import React from 'react';
import moment from 'moment';
import { Table, Button, Divider, Modal } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import TitleBar from '../../components/common/TitleBar';
import Media from 'react-media';

const { confirm } = Modal;

const SchoolScreen = ({ data, history, onDelete }) => {
  const showDeleteConfirm = (schoolId, schoolName) => {
    confirm({
      title: `Are you sure you want to delete ${schoolName}`,
      okType: 'danger',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        onDelete(schoolId);
      },
    });
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
    },
    {
      title: 'Local Govt Area',
      dataIndex: 'local_govt_area',
      key: 'local_govt_area',
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Link to={`/main/admin/edit-school/${record._id}`}>Edit</Link>
          <Divider type="vertical" />
          <a
            href="javascript:;"
            onClick={() => showDeleteConfirm(record._id, record.name)}
          >
            Delete
          </a>
        </span>
      ),
    },
  ];

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="All Schools"
            buttons={[
              <Button
                size={matches ? 'small' : 'default'}
                type="primary"
                icon="usergroup-add"
                style={{ marginRight: 8, marginBottom: '.5em' }}
                onClick={() => history.push('/main/admin/add-school')}
              >
                Add School
              </Button>,
            ]}
          />
          <Table
            columns={columns}
            dataSource={data}
            rowKey="_id"
            style={{ minWidth: 813 }}
          />
        </div>
      )}
    </Media>
  );
};

const SchoolScreenWithRouter = withRouter(SchoolScreen);
export default SchoolScreenWithRouter;
