import React from "react";
import moment from "moment";
import { Table, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import TitleBar from "../components/common/TitleBar";
import Media from "react-media";

const StudentsScreen = ({ data, history, onRowSelect, onDelete }) => {
  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => (
        <Link to={`/main/student/${record._id}/${record.classroom._id}`}>{text}</Link>
      )
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName"
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender"
    },
    {
      title: "Classroom",
      dataIndex: "classroom.name",
      key: "classroom.name"
    },
    {
      title: "Guardian Phone Number",
      dataIndex: "guardianPhoneNumber",
      key: "guardianPhoneNumber"
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: text => (text ? "yes" : "no")
    },
    {
      title: "Added",
      dataIndex: "created_at",
      key: "created_at",
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/main/edit-student/${record._id}`}>Edit</Link>
          <Divider type="vertical" />
          <a href="javascript:;" onClick={() => onDelete(record._id)}>
            Delete
          </a>
        </span>
      )
    }
  ];

  const rowSelection = {
    onChange: onRowSelect,
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name
    })
  };

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="Students"
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
            buttons={[
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="usergroup-add"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() => history.push("/main/add-classroom")}
              >
                Add Classroom
              </Button>,
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="user-add"
                onClick={() => history.push("/main/add-student")}
              >
                Add Student
              </Button>
            ]}
          />
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            rowKey="_id"
            style={{ minWidth: 813 }}
          />
        </div>
      )}
    </Media>
  );
};

const StudentsScreenWithRouter = withRouter(StudentsScreen);
export default StudentsScreenWithRouter;
