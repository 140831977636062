import styled from "styled-components";

const CardSection = styled.div`
  background: white;
  margin-bottom: 14px;
  min-width: 100%;
  max-width: max-content;
  overflow: scroll;
  padding: 24px;
  box-shadow: ${({ shadow }) => shadow ? `2px 4px 11px 0px #d5d5d5` : '' };

  .card-section-title {
    text-align: center;
    margin-top: 20px;
  }

  .ant-tabs-tabpane {
    overflow-x: scroll;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 8px;
    padding: 12px;
  }
`;

const CardSectionTitle = styled.h2`
  border-bottom: 1px solid #96fb96;
  text-align: center;
  padding-bottom: 4px;
`;

export { CardSectionTitle };
export default CardSection;
