export const INITIAL_WEEK = {
  order: 1,
  topic: '',
  day1: {order: 1, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day2: {order: 2, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day3: {order: 3, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day4: {order: 4, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day5: {order: 5, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
};

export const TERM_ONE = {
  order: 1,
  topic: '',
  day1: {order: 1, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day2: {order: 2, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day3: {order: 3, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day4: {order: 4, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day5: {order: 5, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
};

export const TERM_TWO = {
  order: 1,
  topic: '',
  day1: {order: 1, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day2: {order: 2, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day3: {order: 3, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day4: {order: 4, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day5: {order: 5, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
};

export const TERM_THREE = {
  order: 1,
  topic: '',
  day1: {order: 1, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day2: {order: 2, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day3: {order: 3, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day4: {order: 4, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
  day5: {order: 5, teacher_led_activity: { subTopic: '', activity: '', warmUp: '' } },
};


export const refineData = (values) => {
  const session = `${values.sessionFrom}/${values.sessionTo}`
  const updatedValues = { ...values, session };

  delete updatedValues.selectedSubject;
  delete updatedValues.sessionFrom;
  delete updatedValues.sessionTo;
  delete updatedValues.activeTabKey;
  
  return updatedValues;

}