import React from "react";
import { Radio } from "antd";

export default class TableFilters extends React.Component {
  onChange = e => {
    const { name, handleOnChange } = this.props;
    handleOnChange(name, e.target.value);
  };

  _renderRadioButton = ({ value, name }, i) => (
    <Radio.Button key={i} value={value}>
      {name}
    </Radio.Button>
  );

  render() {
    const { label, options } = this.props;
    return (
      <div className="radio-filter table-filter-item">
        {label && (
          <label className="table-filter-item-label">
            filter by: <strong>{label}</strong>
          </label>
        )}
        <Radio.Group
          onChange={this.onChange}
          size="small"
          defaultValue="all"
          buttonStyle="solid"
        >
          {options.map(this._renderRadioButton)}
          <Radio.Button value={undefined}>ALL</Radio.Button>
        </Radio.Group>
      </div>
    );
  }
}
