import React from "react";
import styled from "styled-components";
import { Form, DatePicker, Input, Button, Select, Icon } from "antd";
import { UserConsumer } from "../../App";
import idx from "idx";
import moment from "moment";
const FormItem = Form.Item;
const Option = Select.Option;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const FormWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;

  .app-modal {
    padding: 24px;
    margin-top: 48px;
    width: 400px;
    max-height: 640px;
    overflow: scroll;
    background-color: #ffffff;
    box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;
  }

  @media only screen and (max-width: 375px) {
    position: fixed;

    .app-modal {
      margin-top: 0;
    }
  }
`;

class AddStudentForm extends React.PureComponent {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { classrooms, student } = this.props;
    console.log("HERE STUDENT", student);

    return (
      <UserConsumer>
        {user => (
          <FormWrapper>
            <div className="app-modal animated fadeInUpBig">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => this.props.history.goBack()}
              >
                <Icon type="arrow-left" style={{ color: "rgba(0,0,0,.25)" }} />
              </div>
              <h3>{student ? `Edit Student` : `Add Student To Classroom`}</h3>
              <Form onSubmit={e => this.handleSubmit(e)}>
                <FormItem label="Classroom">
                  {getFieldDecorator("classroomId", {
                    initialValue: idx(student, _ => _.classroom._id),
                    rules: [
                      {
                        required: true,
                        message: "Please fill this field!"
                      }
                    ]
                  })(
                    <Select defaultActiveFirstOption>
                      {classrooms &&
                        classrooms.map(classroom => (
                          <Option key={classroom._id} value={classroom._id}>
                            {classroom.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </FormItem>
                <FormItem label="First Name">
                  {getFieldDecorator("firstName", {
                    initialValue: idx(student, _ => _.firstName)
                  })(<Input placeholder="First Name" />)}
                </FormItem>
                <FormItem label="Last Name">
                  {getFieldDecorator("lastName", {
                    initialValue: idx(student, _ => _.lastName)
                  })(<Input placeholder="Last Name" />)}
                </FormItem>
                <FormItem label="Gender">
                  {getFieldDecorator("gender", {
                    initialValue: idx(student, _ => _.gender),
                    rules: [
                      {
                        required: true,
                        message: "Please fill this field!"
                      }
                    ]
                  })(
                    <Select defaultActiveFirstOption>
                      <Option value={"male"}>Boy</Option>
                      <Option value={"female"}>Girl</Option>
                    </Select>
                  )}
                </FormItem>
                <FormItem label="Date of Birth">
                  {getFieldDecorator("dateOfBirth", {
                    initialValue: idx(student, _ => moment(_.dateOfBirth))
                  })(<DatePicker />)}
                </FormItem>
                <FormItem label="Address">
                  {getFieldDecorator("address", {
                    initialValue: idx(student, _ => _.address),
                    rules: [
                      {
                        required: true,
                        message: "Please add an address"
                      }
                    ]
                  })(<Input placeholder="Address" />)}
                </FormItem>
                <FormItem label="State">
                  {getFieldDecorator("state", {
                    initialValue: idx(student, _ => _.state),
                    rules: [
                      {
                        required: true,
                        message: "Please fill this field!"
                      }
                    ]
                  })(<Input placeholder="State" />)}
                </FormItem>
                <FormItem label="Nationality">
                  {getFieldDecorator("nationality", {
                    initialValue: idx(student, _ => _.nationality),
                    rules: [
                      {
                        required: true,
                        message: "Please fill this field!"
                      }
                    ]
                  })(<Input placeholder="Nationailty" />)}
                </FormItem>
                <FormItem label="Guardian First Name">
                  {getFieldDecorator("guardianFirstName", {
                    initialValue: idx(student, _ => _.guardianFirstName),
                    rules: [
                      {
                        required: true,
                        message: "Please fill this field!"
                      }
                    ]
                  })(<Input placeholder="Guardian First Name" />)}
                </FormItem>
                <FormItem label="Guardian Last Name">
                  {getFieldDecorator("guardianLastName", {
                    initialValue: idx(student, _ => _.guardianLastName),
                    rules: [
                      {
                        required: true,
                        message: "Please fill this field!"
                      }
                    ]
                  })(<Input placeholder="Guardian Last Name" />)}
                </FormItem>
                <FormItem label="Guardian Occupation">
                  {getFieldDecorator("guardianOccupation", {
                    initialValue: idx(student, _ => _.guardianOccupation)
                  })(<Input placeholder="Guardian Occupation" />)}
                </FormItem>
                <FormItem label="Guardian Phone Number">
                  {getFieldDecorator("guardianPhoneNumber", {
                    initialValue: idx(student, _ => _.guardianPhoneNumber),
                    rules: [
                      {
                        required: true,
                        message: "Please fill this field!"
                      }
                    ]
                  })(<Input placeholder="Guardian Phone Number" />)}
                </FormItem>
                <FormItem label="Guardian Email">
                  {getFieldDecorator("guardianEmail", {
                    initialValue: idx(student, _ => _.guardianEmail)
                  })(<Input placeholder="Guardian Email" />)}
                </FormItem>

                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={hasErrors(getFieldsError())}
                  >
                    {student ? `Save Changes` : `Add Student`}
                  </Button>
                </FormItem>
              </Form>
            </div>
          </FormWrapper>
        )}
      </UserConsumer>
    );
  }
}

const WrappedAddStudentForm = Form.create()(AddStudentForm);

export default WrappedAddStudentForm;
