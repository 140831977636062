import React from 'react';
import { Mutation, Query } from 'react-apollo';
import AddOrEditSchoolForm from '../../../screens/forms/AddOrEditSchoolForm'
import CREATE_SCHOOL from '../../../graphql/mutations/CREATE_SCHOOL';

export class AddSchoolContainer extends React.Component {

  state = {
    selectedState: 'Lagos',
  }
  _handleAddSchool = async (values, mutation) => {
    console.log('submitting form', values);
    try {
      const { data } = await mutation({ variables: { school: values } });

      console.log("[Created school]: ", data);
      this.props.history.push(`/main/admin/schools`);
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  }

  _handleStateChange = async (state) => {
    this.setState({ selectedState: state })
  }

  render() {
        return (
          <Mutation mutation={CREATE_SCHOOL}>
          {(mutation, {loading, error }) => {
            return (
            <AddOrEditSchoolForm
              selectedState={this.state.selectedState}
              handleStateChange={state => this._handleStateChange(state)}
              onSubmit={values => this._handleAddSchool(values, mutation)}
            />
          )
          }}
          </Mutation>
          
        )
      
  }
}