import gql from "graphql-tag";

export default gql`
  mutation generateWeeklyReport($weeklyReport: WeeklyReportInputNew!) {
    generateWeeklyReport(weeklyReport: $weeklyReport) {
      _id
      total_score
      reportIds
    }
  }
`;
