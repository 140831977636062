import React from 'react';
import styled from 'styled-components';
import Sidebar from './SidebarScreen';
import { Row, Col } from 'antd';
import logo from '../assets/img/makaranta_logo.png';
import idx from 'idx';
import {
  dashboardRoutes,
  adminDashboardRoutes,
  headTeacherDashboardRoutes,
  seniorTeacherDashboardRoutes,
} from '../config/routes';

const Header = styled.div`
  border-bottom: 1px solid #eee;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 78px;
  position: fixed;
  z-index: 1000000;
  background: #ffffff;
  top: 0;
  left: 0;
  right: 0;

  @media only screen and (max-width: 375px) {
    padding: 12px 12px;
  }
`;

const MainContentArea = styled.div`
  min-height: 100vh;
  overflow: scroll;
  background-color: #f3f3f3;
  padding: 24px;

  @media only screen and (max-width: 375px) {
    padding: 8px 12px;
  }
`;

const LogoutButton = styled.h4`
  cursor: pointer;
  font-weight: lighter;
  color: blue;
`;

const getRoutes = user => {
  if (!user) {
    return [];
  }
  if (user.role == 'admin') {
    return adminDashboardRoutes;
  } else if (user.role == 'head_teacher') {
    return headTeacherDashboardRoutes;
  } else if (user.role == 'senior_teacher') {
    return seniorTeacherDashboardRoutes;
  } else if (user.role == 'teacher') {
    return dashboardRoutes;
  } else {
    return [];
  }
};

const MainContentScreen = ({ children, user, onLogout }) => (
  <div>
    <Row>
      <Header>
        <img style={{ width: 117 }} src={logo} alt="logo" />
        <div style={{ display: 'flex' }}>
          <h4 style={{ marginRight: 14 }}>
            {user && (
              <div>
                <div>Hello, {idx(user, _ => _.firstName)}</div>
              </div>
            )}
          </h4>
          <LogoutButton onClick={onLogout}>Logout</LogoutButton>
        </div>
      </Header>
      <Col style={{ marginTop: 78 }} span={4}>
        <Sidebar user={user} routes={getRoutes(user)} />
      </Col>
      <Col style={{ marginTop: 78 }} span={20}>
        <MainContentArea>{children}</MainContentArea>
      </Col>
    </Row>
  </div>
);

export default MainContentScreen;
