import React from "react";
import { Mutation, Query } from "react-apollo";
import CREATE_STUDENT from "../../../graphql/mutations/CREATE_STUDENT";
import ALL_CLASSROOMS from "../../../graphql/queries/ALL_CLASSROOMS";
import AddStudentForm from "../../../screens/forms/AddStudentForm";

class AddStudentContainer extends React.Component {
  _handleAddStudent = async (values, mutation) => {
    console.log("submitting form");
    try {
      const { data } = await mutation({ variables: { student: values } });
      // retrieve token, persist it and route back to home

      console.log("[Created student]: ", data);
      this._handleNextRoute(values.classroomId);
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  _handleNextRoute = id => this.props.history.push(`/main/classroom/${id}`);

  render() {
    return (
      <Query query={ALL_CLASSROOMS}>
        {({ loading, error, data }) => {
          console.log("[Error]", error);
          console.log("{[Data]}", data);
          const { classrooms } = data;
          return (
            <Mutation mutation={CREATE_STUDENT}>
              {(mutation, { loading, error }) => {
                return (
                  <AddStudentForm
                    classrooms={classrooms}
                    history={this.props.history}
                    onSubmit={values =>
                      this._handleAddStudent(values, mutation)
                    }
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export { AddStudentContainer };
