import React from "react";
import { Row, Col, Button } from "antd";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table } from "antd";
import { Link } from "react-router-dom";
import TitleBar from "../../components/common/TitleBar";
import Avatar from "../../components/common/Avatar";
import CardSection, {
  CardSectionTitle
} from "../../components/common/CardSection";
import KeyedList from "../../components/common/KeyedList";
import { Tabs } from "antd";
const TabPane = Tabs.TabPane;

const ViewClassroomScreen = ({ data, history, students, onRowSelect }) => {
  const studentColumns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      render: (text, record) => (
        <Link to={`/main/admin/student/${record._id}`}>{text}</Link>
      )
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName"
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender"
    },
    {
      title: "Guardian Phone Number",
      dataIndex: "guardianPhoneNumber",
      key: "guardianPhoneNumber"
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: text => (text ? "yes" : "no")
    },
    {
      title: "Added",
      dataIndex: "created_at",
      key: "created_at",
      render: text => moment(text).format("MMM Do YY")
    }
  ];

  const lessonColumns = [
    {
      title: "Topic",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/main/lesson/${record._id}`}>{text}</Link>
      )
    },
    {
      title: "Subject",
      dataIndex: "subject.name",
      key: "subject.name",
      filters: [
        {
          text: "Numeracy",
          value: "Numeracy"
        },
        {
          text: "Literacy",
          value: "Literacy"
        },
        {
          text: "History",
          value: "History"
        }
      ]
    },
    {
      title: "School Level",
      dataIndex: "classroomLevel",
      key: "classroomLevel",
      filters: [
        {
          text: "PRE_SCHOOL",
          value: "PRE_SCHOOL"
        },
        { text: "PRIMARY_ONE", value: "PRIMARY_ONE" }
      ]
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: isActive => (isActive ? "yes" : "no")
    },
    {
      title: "Added",
      dataIndex: "created_at",
      key: "created_at",
      render: date => moment(date).format("MMM Do YY")
    }
  ];

  const dailyReportColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "Student Name",
      dataIndex: "student.firstName",
      key: "student.firstName"
    },
    {
      title: "Lesson",
      dataIndex: "lesson.name",
      key: "lesson.name"
    },
    {
      title: "Assessment Score",
      dataIndex: "assessment_score.total_score",
      key: "assessment_score.total_score"
    },
    {
      title: "Classroom Observation Score",
      dataIndex: "classroom_observation_score.total_score",
      key: "classroom_observation_score.total_score"
    },
    {
      title: "Group Observation Score",
      dataIndex: "group_observation_score.total_score",
      key: "group_observation_score.total_score"
    },
    {
      title: "Total Score",
      dataIndex: "total_score",
      key: "total_score"
    },
    {
      title: "Score Group",
      dataIndex: "score_group",
      key: "score_group",
      render: text => text.split("_")[2]
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/main/admin/daily-report/${record._id}`}>View</Link>
        </span>
      )
    }
  ];

  const weeklyReportColumns = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "Day 1 Score",
      dataIndex: "reports[0].total_score",
      key: "reports[0].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Day 2 Score",
      dataIndex: "reports[1].total_score",
      key: "reports[1].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Day 3 Score",
      dataIndex: "reports[2].total_score",
      key: "reports[2].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Day 4 Score",
      dataIndex: "reports[3].total_score",
      key: "reports[3].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Day 5 Score",
      dataIndex: "reports[4].total_score",
      key: "reports[4].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Student Name",
      dataIndex: "student.firstName",
      key: "student.firstName"
    },
    {
      title: "Total Score",
      dataIndex: "total_score",
      key: "total_score"
    },
    {
      title: "Score Group",
      dataIndex: "score_group",
      key: "score_group",
      render: text => text.split("_")[2]
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/main/admin/weekly-report/${record._id}`}>View</Link>
        </span>
      )
    }
  ];

  const rowSelection = {
    onChange: onRowSelect,
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name
    })
  };

  console.log("Classroom Data: ", data);

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title={"Classroom Details"}
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
            buttons={[
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="user-add"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() => history.push("/main/add-student")}
              >
                Add Student
              </Button>
            ]}
          />
          <Row gutter={12}>
            <Col xs={24} md={7}>
              <CardSection>
                <Avatar style={{ margin: "0 auto" }} />
                <h2 className="card-section-title">{data.name}</h2>
                <KeyedList
                  data={data}
                  keys={[
                    { key: "classroomLevel", label: "school level" },
                    { key: "subject.name", label: "subject name" },
                    { key: "created_at", label: "created on" }
                  ]}
                />
              </CardSection>
            </Col>
            <Col xs={24} md={17}>
              <CardSection>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Students" key="1">
                    <h3>Students Enrolled</h3>
                    <Table
                      rowSelection={rowSelection}
                      columns={studentColumns}
                      dataSource={students}
                      rowKey="_id"
                      style={{ minWidth: 813 }}
                    />
                  </TabPane>
                  <TabPane tab="Lesson Plan" key="2">
                    <p>Lesson goes here</p>
                  </TabPane>
                  <TabPane tab="Lessons" key="3">
                    <h3>Unlocked Lessons</h3>
                    <Table
                      columns={lessonColumns}
                      dataSource={data.teacher && data.teacher.lessons}
                      rowKey="_id"
                      style={{ minWidth: 813 }}
                    />
                  </TabPane>
                  <TabPane tab="Daily Reports" key="4">
                    <h3>Daily Reports</h3>
                    <Table
                      columns={dailyReportColumns}
                      dataSource={data.daily_reports}
                      rowKey="_id"
                      style={{ minWidth: 813 }}
                    />
                  </TabPane>
                  <TabPane tab="Weekly Reports" key="5" disabled={true}>
                    <h3>Weekly Reports</h3>
                    <Table
                      columns={weeklyReportColumns}
                      dataSource={data.weekly_reports}
                      rowKey="_id"
                      style={{ minWidth: 813 }}
                    />
                  </TabPane>
                </Tabs>
              </CardSection>
            </Col>
          </Row>
        </div>
      )}
    </Media>
  );
};

const ViewClassroomScreenWithRouter = withRouter(ViewClassroomScreen);
export default ViewClassroomScreenWithRouter;
