import React from "react";
import styled from "styled-components";
import { Form, Input, InputNumber, Button, Select, Row, Col } from "antd";
import { UserConsumer } from "../../App";
import idx from "idx";
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class AddOrEditLessonActivityForm extends React.Component {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { lessonId, lessonActivity } = this.props;

    return (
      <Form onSubmit={e => this.handleSubmit(e)}>
        <Row gutter={{ md: 16 }}>
          <Col xs={24} md={12}>
            <FormItem label="Activity Name">
              {getFieldDecorator("name", {
                initialValue: idx(lessonActivity, _ => _.name)
              })(<Input placeholder="Name" />)}
            </FormItem>
          </Col>
        </Row>
        <FormItem
          label="Description"
          extra="Describe the activity in as many words as required to make it clear"
        >
          {getFieldDecorator("description", {
            initialValue: idx(lessonActivity, _ => _.description)
          })(
            <TextArea
              style={{ height: 190 }}
              placeholder="Describe the activity"
            />
          )}
        </FormItem>
        <FormItem
          label="Materials"
          extra="Place each material required for the activity on a new line"
        >
          {getFieldDecorator("materials", {
            initialValue: idx(lessonActivity, _ => _.materials)
          })(
            <TextArea
              style={{ height: 190 }}
              placeholder="Activity materials"
            />
          )}
        </FormItem>
        <FormItem
          label="Instructions"
          extra="Place each new instruction on a new line"
        >
          {getFieldDecorator("instructions", {
            initialValue: idx(lessonActivity, _ => _.instructions)
          })(
            <TextArea
              style={{ height: 190 }}
              placeholder="Activity Instructions"
            />
          )}
        </FormItem>
        <FormItem label="Tags" extra="Enter a tag then press the 'enter' key">
          {getFieldDecorator("tags", {
            initialValue: idx(lessonActivity, _ => _.tags)
          })(
            <Select
              mode="tags"
              placeholder="Enter a few tags if required. (Optional)"
              style={{ width: "100%" }}
            />
          )}
        </FormItem>

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            {lessonActivity ? "Edit Activity" : "Create Activity"}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedAddOrEditLessonActivityForm = Form.create()(
  AddOrEditLessonActivityForm
);

export default WrappedAddOrEditLessonActivityForm;
