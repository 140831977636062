import gql from "graphql-tag";

export default gql`
  {
    users {
      _id
      firstName
      lastName
      phone
      gender
      role
      email
      created_at
      updated_at
      verified
      gender
    }
  }
`;
