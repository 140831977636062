import React from "react";
import Query from "react-apollo/Query";
import FETCH_DAILY_REPORT from "../../../graphql/queries/FETCH_DAILY_REPORT";
import ViewDailyReportScreen from "../../../screens/ViewDailyReportScreen";

class ViewDailyReportContainer extends React.Component {
  render() {
    return (
      <Query
        query={FETCH_DAILY_REPORT}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { dailyReports } = data;
          const dailyReport = dailyReports[0];
          return <ViewDailyReportScreen data={dailyReport} />;
        }}
      </Query>
    );
  }
}

// TODO: tomorrow
//  - fetch a weekly report ...
// - display view to the user
// - allow user to view/edit each daily report

export { ViewDailyReportContainer };
