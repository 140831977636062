import React from "react";
import styled from "styled-components";

const TableFiltersDiv = styled.section`
  display: flex;
  margin-bottom: 22px;
  flex-wrap: wrap;

  .table-filter-item {
    &:first-child {
      margin-right: 20px;
      padding-bottom: 10px;
    }

    .table-filter-item-label {
      display: block;
      margin-bottom: 8px;
      color: #9e9e9e;

      strong {
        text-transform: uppercase;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export default class TableFilters extends React.Component {
  render() {
    return <TableFiltersDiv>{this.props.children}</TableFiltersDiv>;
  }
}
