import React from "react";
import styled from "styled-components";
import { Form, Input, InputNumber, Button, Select, Row, Col } from "antd";
import { UserConsumer } from "../../App";
import idx from "idx";
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class AddOrEditLessonSchemeForm extends React.Component {
  state = {
    selectedSubject: "",
    terms: []
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("form values are: ", values);
      }
    });
  };

  _handleAddTerm = e => {
    e.preventDefault();
    this.setState(currentState => ({
      terms: currentState.terms.concat({
        term: currentState.terms.length + 1,
        weeks: [
          {
            weekNumber: 1
          }
        ]
      })
    }));
  };

  _handleAddWeekToTerm = termNumber => {
    this.setState(({ terms }) => ({
      terms: terms.map(term => {
        if (term.term === termNumber) {
          return {
            ...term,
            weeks: term.weeks.concat({ weekNumber: term.weeks.length + 1 })
          };
        }

        return term;
      })
    }));
  };

  _handleDeleteTerm = termNumber =>
    this.setState(({ terms }) => ({
      terms: terms
        .filter(term => termNumber !== term.term)
        // we first filter out the unwanted term, then we map the rest and serialize the term numbers
        .map((term, i) => ({
          ...term,
          term: i + 1
        }))
    }));

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { subjects } = this.props.subjects;
    const { selectedSubject, terms } = this.state;

    return (
      <Form onSubmit={e => this.handleSubmit(e)}>
        <Row gutter={{ md: 16 }}>
          <Col xs={24} md={12}>
            <FormItem label="Subject">
              {getFieldDecorator("subjectId")(
                <Select
                  onSelect={this.handleSelectSubject}
                  defaultActiveFirstOption
                >
                  {subjects &&
                    subjects.map(subject => (
                      <Option key={subject._id} value={subject._id}>
                        {subject.name}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem label="Classroom Level">
              {getFieldDecorator("classroomLevel")(
                <Select defaultActiveFirstOption>
                  <Option value={"PRE_SCHOOL"}>PRE-SCHOOL</Option>
                  <Option value={"PRIMARY_1"}>PRIMARY 1</Option>
                  <Option value={"PRIMARY_2"}>PRIMARY 2</Option>
                  <Option value={"PRIMARY_3"}>PRIMARY 3</Option>
                  <Option value={"PRIMARY_4"}>PRIMARY 4</Option>
                  <Option value={"PRIMARY_5"}>PRIMARY 5</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <Row gutter={{ md: 16 }}>
          <Col xs={12} md={6}>
            <FormItem label="Min Age">
              {getFieldDecorator("minAge")(
                <Select defaultActiveFirstOption>
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                  <Option value={6}>6</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={12} md={6}>
            <FormItem label="Max Age">
              {getFieldDecorator("maxAge")(
                <Select defaultActiveFirstOption>
                  <Option value={1}>1</Option>
                  <Option value={2}>2</Option>
                  <Option value={3}>3</Option>
                  <Option value={4}>4</Option>
                  <Option value={5}>5</Option>
                  <Option value={6}>6</Option>
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <Button onClick={this._handleAddTerm} type="primary">
              Add Term
            </Button>
          </Col>
        </Row>
        {terms.map((term, i) => {
          console.log("term is: ", term);
          const { weeks, term: termNumber } = term;
          return (
            <div key={i}>
              <h3>
                Enter details for Term {termNumber}{" "}
                <Button
                  size="small"
                  onClick={() => this._handleAddWeekToTerm(termNumber)}
                  type="success"
                  style={{ marginRight: 10 }}
                >
                  Add A Week
                </Button>
                <Button
                  size="small"
                  onClick={() => this._handleDeleteTerm(termNumber)}
                  type="danger"
                >
                  Delete
                </Button>
              </h3>
              {weeks.map(({ weekNumber }) => {
                return (
                  <div key={weekNumber}>
                    <h4>{`Enter details for Term ${termNumber} Week ${weekNumber}`}</h4>
                    <FormItem
                      label="Objective"
                      extra="What is the objective of this week's lesson"
                    >
                      {getFieldDecorator(`${termNumber}_${weekNumber}_lesson`)(
                        <Input />
                      )}
                    </FormItem>
                    <hr />
                  </div>
                );
              })}
            </div>
          );
        })}

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            Add Lesson Scheme
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedAddOrEditLessonSchemeForm = Form.create()(
  AddOrEditLessonSchemeForm
);

export default WrappedAddOrEditLessonSchemeForm;
