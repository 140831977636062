import React from "react";
import { Mutation, Query } from "react-apollo";
import RESET_PASSWORD from "../../../graphql/mutations/RESET_PASSWORD";
import FETCH_USER from "../../../graphql/queries/FETCH_USER";
import ResetPasswordForm from "../../../screens/forms/ResetPasswordForm";

export class ResetPasswordContainer extends React.Component {
  _handleResetPassword = async (values, mutation) => {
    console.log(values);
    try {
      const { data } = await mutation({
        variables: {
          _id: this.props.match.params.id,
          password: values.password,
          confirmPassword: values.confirmPassword
        }
      });
      // retrieve token, persist it and route back to home

      console.log("[Reset Pssword]: ", data);
      this.props.history.push(`/main/admin/users`);
    } catch (e) {
      console.log("Error editing user: ", e);
    }
  };

  render() {
    return (
      <Query
        query={FETCH_USER}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { users } = data;
          const user = users[0];
          return (
            <Mutation mutation={RESET_PASSWORD}>
              {(mutation, { loading, error }) => {
                return (
                  <ResetPasswordForm
                    user={user}
                    onSubmit={values =>
                      this._handleResetPassword(values, mutation)
                    }
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}
