import gql from "graphql-tag";

export default gql`
  query dailyReports($filter: JSON!) {
    dailyReports(filter: $filter) {
      _id
      date
      week
      total_score
      percentage_score
      score_group
      date
      classroom {
        _id
        name
        subject {
          _id
          name
        }
      }
      assessment_score {
        task1_score
        task2_score
        task3_score
        homework_score
        total_score
        percentage_score
        score_group
      }
      student {
        _id
        firstName
        lastName
      }
      classroom_observation_score {
        present
        attentive
        participated
        asked_questions
        answered_questions
        total_score
      }
      group_observation_score {
        group_evaluation_score {
          group_name
          activity
          agreed_on_plan
          participated
          productive
          respectful
          completed_assignment
          total_score
        }
        group_member_evaluation_score {
          group_name
          activity
          performed_assigned_roles
          understood_activity_purpose
          answered_questions
          listened_to_ideas
          gave_feedback
          assisted_in_preparation
          expressed_ideas
          willing_to_compormise
          accepted_feedback
          participated
          total_score
        }
        total_score
      }
    }
  }
`;
