import React from "react";
import styled from "styled-components";
import { Form, Input, Button, Select } from "antd";
import { UserConsumer } from "../../App";
import idx from "idx";
import { QUESTION_LABELS } from "../../constants";
const FormItem = Form.Item;
const Option = Select.Option;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const FormWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;

  .app-modal {
    padding: 24px;
    margin-top: 48px;
    width: 400px;
    max-height: 640px;
    overflow: scroll;
    background-color: #ffffff;
    box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;
  }

  @media only screen and (max-width: 375px) {
    position: fixed;

    .app-modal {
      margin-top: 0;
    }
  }
`;

class AddTestQuestionForm extends React.Component {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        values.lessonId = this.props.lessonId;
        console.log("Received values of form: ", values);
        this.props.onSubmit(values);
      }
    });
  };

  renderQuestionLabels = () => {
    return QUESTION_LABELS.filter(({ value }) =>
      value.includes(this.props.subjectName.toUpperCase())
    ).map(({ label, value }) => (
      <Option key={value} value={value}>
        {label}
      </Option>
    ));
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { testQuestion, title } = this.props;
    return (
      <UserConsumer>
        {user => (
          <FormWrapper>
            <div className="app-modal animated fadeInUpBig">
              <div>
                <i className="fa fa-times" />
              </div>
              <h3>{testQuestion ? "Edit" : "Add"} a question</h3>
              <h5>LESSON: {title}</h5>
              <Form onSubmit={e => this.handleSubmit(e)}>
                <FormItem label="Question">
                  {getFieldDecorator("question", {
                    initialValue: idx(testQuestion, _ => _.question)
                  })(<Input placeholder="Enter Question" />)}
                </FormItem>
                <FormItem label="Question Label">
                  {getFieldDecorator("label", {
                    initialValue: idx(testQuestion, _ => _.label)
                  })(
                    <Select defaultActiveFirstOption>
                      {this.renderQuestionLabels()}
                    </Select>
                  )}
                </FormItem>
                <FormItem label="Option A">
                  {getFieldDecorator("a", {
                    initialValue: idx(testQuestion, _ => _.a)
                  })(<Input placeholder="Enter the option for A" />)}
                </FormItem>
                <FormItem label="Option B">
                  {getFieldDecorator("b", {
                    initialValue: idx(testQuestion, _ => _.b)
                  })(<Input placeholder="Enter the option for B" />)}
                </FormItem>
                <FormItem label="Option C">
                  {getFieldDecorator("c", {
                    initialValue: idx(testQuestion, _ => _.c)
                  })(<Input placeholder="Enter the option for C" />)}
                </FormItem>
                <FormItem label="Option D">
                  {getFieldDecorator("d", {
                    initialValue: idx(testQuestion, _ => _.d)
                  })(<Input placeholder="Enter the option for D" />)}
                </FormItem>

                <FormItem label="Correct Option">
                  {getFieldDecorator("correct_answer", {
                    initialValue: idx(testQuestion, _ => _.correct_answer)
                  })(
                    <Select defaultActiveFirstOption>
                      <Option value={"a"}>Option A</Option>
                      <Option value={"b"}>Option B</Option>
                      <Option value={"c"}>Option C</Option>
                      <Option value={"d"}>Option D</Option>
                    </Select>
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={hasErrors(getFieldsError())}
                  >
                    {testQuestion ? "Edit Question" : "Add Question"}
                  </Button>
                </FormItem>
              </Form>
            </div>
          </FormWrapper>
        )}
      </UserConsumer>
    );
  }
}

const WrappedAddTestQuestionForm = Form.create()(AddTestQuestionForm);

export default WrappedAddTestQuestionForm;
