import gql from "graphql-tag";

export default gql`
  mutation editLessonActivity(
    $_id: String!
    $lessonActivity: LessonActivityInputEdit!
  ) {
    editLessonActivity(_id: $_id, lessonActivity: $lessonActivity) {
      _id
      lessonId
    }
  }
`;
