import React from "react";
import { Query, Mutation } from "react-apollo";
import ALL_STUDENTS from "../../../graphql/queries/ALL_STUDENTS";
import DELETE_STUDENT from "../../../graphql/mutations/DELETE_STUDENT";
import StudentsScreen from "../../../screens/StudentsScreen";

export class StudentsContainer extends React.Component {
  _onDelete = async (id, mutation) => {
    console.log("deleting...", id);
    try {
      const { data } = await mutation({
        variables: { _id: id }
      });

      console.log("[Deleted student]: ", data);
    } catch (e) {
      console.log("Error deleting student: ", e);
    }
  };

  render() {
    return (
      <Mutation
        mutation={DELETE_STUDENT}
        update={(cache, { data: { deleteStudent } }) => {
          const { students } = cache.readQuery({ query: ALL_STUDENTS });
          cache.writeQuery({
            query: ALL_STUDENTS,
            data: {
              students: students.filter(
                student => student._id !== deleteStudent._id
              )
            }
          });
        }}
      >
        {(mutation, { loading, error }) => (
          <Query query={ALL_STUDENTS}>
            {({ loading, error, data }) => {
              if (loading) return "Loading...";
              if (error) console.log("[Error fetching classrooms]: ", error);
              const { students } = data;
              return (
                <StudentsScreen
                  onDelete={id => this._onDelete(id, mutation)}
                  data={students}
                />
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}
