import { ApolloClient } from "apollo-client";
// import { ApolloLink } from "apollo-link";
import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
// import "regenerator-runtime/runtime";

export default function getClient(token, uri) {
  const httpLink = createHttpLink({
    uri
  });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    console.log("[Apollo Config]", token);
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ""
      }
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    connectToDevTools: true
  });

  return client;
}
