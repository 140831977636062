import React from "react";
import { Mutation, Query } from "react-apollo";
import EDIT_STUDENT from "../../../graphql/mutations/EDIT_STUDENT";
import FETCH_STUDENT from "../../../graphql/queries/FETCH_STUDENTS";
import ALL_CLASSROOMS from "../../../graphql/queries/ALL_CLASSROOMS";
import EditStudentForm from "../../../screens/forms/AddStudentForm";

class EditStudentContainer extends React.Component {
  _handleEditStudent = async (values, mutation) => {
    console.log("submitting form");
    // we need to remove classroom, teacher, created_at and daily_report fiels before we can submit
    // we just extract the uneeded/unallowed fields and leave the allowed ones
    const {
      __typename,
      classroom,
      teacher,
      created_at,
      daily_reports,
      _id,
      ...allowedFields
    } = values;
    try {
      const { data } = await mutation({
        variables: { _id: this.props.match.params.id, student: allowedFields }
      });
      // retrieve token, persist it and route back to home

      console.log("[Edited student]: ", data);
      this._handleNextRoute(values.classroomId);
    } catch (e) {
      console.log("Error editing student: ", e);
    }
  };

  _handleNextRoute = id => this.props.history.push(`/main/classroom/${id}`);

  render() {
    return (
      <Query
        query={FETCH_STUDENT}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { students } = data;
          const student = students[0];
          return (
            <Query query={ALL_CLASSROOMS}>
              {({ loading, error, data }) => {
                console.log("[Error]", error);
                console.log("{[Data]}", data);
                const { classrooms } = data;
                return (
                  <Mutation mutation={EDIT_STUDENT}>
                    {(mutation, { loading, error }) => {
                      return (
                        <EditStudentForm
                          student={student}
                          classrooms={classrooms}
                          something={"some"}
                          onSubmit={values =>
                            this._handleEditStudent(values, mutation)
                          }
                        />
                      );
                    }}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export { EditStudentContainer };
