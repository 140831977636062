import React from "react";
import { Query, Mutation } from "react-apollo";
import FETCH_LESSON from "../../../graphql/queries/FETCH_LESSON";
import TAKE_COMPETENCY_TEST from "../../../graphql/mutations/TAKE_COMPETENCY_TEST";
import ViewLessonScreen from "../../../screens/ViewLessonScreen";
import { UserConsumer } from "../../../App";

class ViewLessonContainer extends React.Component {
  state = {
    errorMsg: "",
    competencyTestLoading: false
  };

  _takeCompetencyTest = async mutation => {
    this.setState({ competencyTestLoading: true });
    const lessonId = this.props.match.params.id;
    try {
      const { data } = await mutation({
        variables: { competencyTest: { lessonId } }
      });
      // console.log(data)
      this.setState({ competencyTestLoading: false });
      console.log("[Created test]: ", data);
      // if successful, go to the test;
      this.props.history.push(`/main/test/${data.createCompetencyTest._id}`);
    } catch (e) {
      console.log("Error creating competency up: ", e);
      const duplicateError = e.message.includes("E11000");
      if (duplicateError) {
        // user has tried to take a competency test twice for same lesson
        this.setState({
          errorMsg:
            "You have already tried to take this test. Please visit the Tests page to continue",
          competencyTestLoading: false
        });
      }
    }
  };

  render() {
    const { errorMsg, competencyTestLoading } = this.state;
    return (
      <UserConsumer>
        {user => (
          <Mutation mutation={TAKE_COMPETENCY_TEST}>
            {(mutation, { loading, error }) => (
              <Query
                query={FETCH_LESSON}
                variables={{ filter: { _id: this.props.match.params.id } }}
              >
                {({ loading, error, data }) => {
                  if (loading) return "Loading...";
                  if (error) return `Error! ${error.message}`;
                  const { lessons } = data;
                  console.log("[User to pass]", user);
                  const lessonId = this.props.match.params.id;
                  const userHasUnlockedLesson = user.lessonIds.includes(
                    lessonId
                  );
                  const lesson = lessons[0];
                  return (
                    <ViewLessonScreen
                      errorMsg={errorMsg}
                      competencyTestLoading={competencyTestLoading}
                      takeCompetencyTest={() =>
                        this._takeCompetencyTest(mutation)
                      }
                      lessonUnlocked={userHasUnlockedLesson}
                      data={lesson}
                    />
                  );
                }}
              </Query>
            )}
          </Mutation>
        )}
      </UserConsumer>
    );
  }
}

export { ViewLessonContainer };
