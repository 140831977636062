import React from "react";
import idx from "idx";
import { Mutation, Query } from "react-apollo";
import AddDailyReportForm from "../../../screens/forms/AddDailyReportForm";
import CREATE_DAILY_REPORT from "../../../graphql/mutations/CREATE_DAILY_REPORT";
import FETCH_STUDENT from "../../../graphql/queries/FETCH_STUDENTS";
import ALL_CLASSROOMS from "../../../graphql/queries/ALL_CLASSROOMS";
import ALL_LESSONS from "../../../graphql/queries/ALL_LESSONS";
import ALL_SUBJECTS from "../../../graphql/queries/ALL_SUBJECTS";

class AddDailyReportContainer extends React.Component {

  state={
    errorMessage: ''
  }
  _onSubmit = async (values, mutation) => {

    try {
      const { data } = await mutation({ variables: { dailyReport: values } });
      this._handleNextRoute();
      // TODO: We need to navigate to a report view route instead
      console.log("form submitted", data);
    } catch (e) {
      console.log("Error signing up: ", e);
      this.setState({ 
        errorMessage: 'could not create report, please check all fields or network and try again' 
      });
    }
  };

  _handleNextRoute = () => this.props.history.push("/main/reports");

  render() {
    return (
      <Query query={ALL_SUBJECTS}>
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { subjects } = data;
          return (
            <Query query={ALL_LESSONS}>
              {({ loading, error, data }) => {
                if (loading) return "Loading...";
                if (error) return `Error! ${error.message}`;
                const { lessons } = data;

                return (
                  <Query query={ALL_CLASSROOMS}>
                    {({ loading, error, data }) => {
                      console.log("[Error]", error);
                      const { classrooms } = data;

                      return (
                        <Query
                          query={FETCH_STUDENT}
                          variables={{
                            filter: { _id: this.props.match.params.id }
                          }}
                        >
                          {({ loading, error, data }) => {
                            if (loading) return "Loading...";
                            if (error) return `Error! ${error.message}`;
                            const { students } = data;
                            const student = students[0];
                            const studentClassroom = classrooms && classrooms.find(classroom => classroom._id === student.classroom._id);
                            const studentSubject = studentClassroom && studentClassroom.subject

                            return (
                              <Mutation mutation={CREATE_DAILY_REPORT}>
                                {(mutation, { loading, error }) => (
                                  <AddDailyReportForm
                                    studentSubject={studentSubject}
                                    student={student}
                                    lessons={lessons}
                                    subjects={subjects}
                                    error={this.state.errorMessage}
                                    onSubmit={values =>
                                      this._onSubmit(values, mutation)
                                    }
                                  />
                                )}
                              </Mutation>
                            );
                          }}
                        </Query>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

/*
TODO:

- Display a form. User can enter all the details. It will be a multi section form
- Should come in with class data.
- We can check that date and student_id are not already present on submit to BE.
- 

*/

export { AddDailyReportContainer };
