import gql from "graphql-tag";

export default gql`
  mutation suspendUser($_id: String!) {
    suspendUser(_id: $_id) {
      _id
      firstName
      lastName
      email
    }
  }
`;
