import React from "react";
import { Query, Mutation } from "react-apollo";
import ALL_LESSONS from "../../../graphql/queries/ALL_LESSONS";
import DELETE_LESSON from "../../../graphql/mutations/DELETE_LESSON";
import LessonsScreen from "../../../screens/admin/LessonsScreen";
import { Table, Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";

export class LessonsContainer extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
    showLessonSelectionModal: false,
    searchText: ""
  };

  _clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  _handleFilterChange = (name, value) => {
    const { filteredInfo } = this.state;

    this.setState({
      filteredInfo: {
        ...filteredInfo,
        [name]: typeof value == "undefined" ? null : [value.toString()]
      }
    });
  };

  _handleTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      switch (dataIndex) {
        case "name":
          console.log("RECORD: ", record, dataIndex);
          return record.name.toLowerCase().includes(value.toLowerCase());

          break;
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    console.log("selected keys", selectedKeys);
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  _onDelete = async (id, mutation) => {
    console.log("deleting...", id);
    try {
      const { data } = await mutation({
        variables: { _id: id }
      });

      console.log("[Deleted lesson]: ", data);
    } catch (e) {
      console.log("Error deleting teacher: ", e);
    }
  };

  render() {
    let { sortedInfo, filteredInfo, searchText } = this.state;
    console.log("filteredInfo", filteredInfo);
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    return (
      <Mutation
        mutation={DELETE_LESSON}
        update={(cache, { data: { deleteLesson } }) => {
          const { lessons } = cache.readQuery({ query: ALL_LESSONS });
          cache.writeQuery({
            query: ALL_LESSONS,
            data: {
              lessons: lessons.filter(lesson => lesson._id !== deleteLesson._id)
            }
          });
        }}
      >
        {(mutation, { loading, error }) => (
          <Query query={ALL_LESSONS}>
            {({ loading, error, data }) => {
              if (loading) return "Loading...";
              if (error) console.log("[Error fetching lessons]: ", error);
              const { lessons } = data;
              return (
                <LessonsScreen
                  onDelete={id => this._onDelete(id, mutation)}
                  data={lessons}
                  searchText={searchText}
                  getColumnSearchProps={this.getColumnSearchProps}
                  tableOptions={{
                    sortedInfo,
                    filteredInfo,
                    handleTableChange: this._handleTableChange,
                    handleFilterChange: this._handleFilterChange,
                    clearFilters: this._clearFilters
                  }}
                />
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}
