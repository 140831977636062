export const WeeklyReportQuery = `
  weekly_reports {
    _id
    startDate
    endDate
    total_score
    reports {
      _id
      total_score
    }
    percentage_score
    score_group
    student {
      _id
      firstName
      lastName
    }
  }
`;
