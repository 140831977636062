import React from "react";
import { Row, Col, Button } from "antd";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table } from "antd";
import { Link } from "react-router-dom";
import TitleBar from "../../components/common/TitleBar";
import Avatar from "../../components/common/Avatar";
import KeyedList from "../../components/common/KeyedList";
import { Tabs } from "antd";
import CardSection, {
  CardSectionTitle
} from "../../components/common/CardSection";
const TabPane = Tabs.TabPane;

const ViewUserScreen = ({
  data,
  history,
  onRowSelect,
  onSuspend,
  onDelete
}) => {
  console.log("[User]:", data);

  const columns = [
    {
      title: "Class Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/main/admin/classroom/${record._id}`}>{text}</Link>
      )
    },
    {
      title: "Classroom Level",
      dataIndex: "classroomLevel",
      key: "classroomLevel"
    },
    {
      title: "Subject",
      dataIndex: "subject.name",
      key: "subject"
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: text => (text ? "yes" : "no")
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: text => moment(text).format("MMM Do YY")
    }
  ];

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="Teacher Profile"
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
            buttons={[
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="paper"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() => onSuspend(data._id)}
              >
                Suspend Teacher
              </Button>,
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="delete"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() => onDelete(data._id)}
              >
                Delete Teacher
              </Button>
            ]}
          />
          <Row gutter={12}>
            <Col xs={24} md={7}>
              <CardSection>
                <Avatar style={{ margin: "0 auto" }} />
                <h2 className="card-section-title">{`${data.firstName} ${
                  data.lastName
                }`}</h2>
                <KeyedList
                  data={data}
                  keys={[
                    { key: "firstName", label: "first name" },
                    { key: "lastName", label: "last name" },
                    { key: "middleName", label: "middle name" },
                    {
                      key: "dateOfBirth",
                      label: "date of birth"
                    },
                    { key: "gender", label: "gender" },
                    { key: "created_at", label: "added on" }
                  ]}
                />
              </CardSection>
              {/*<CardSection>
                <CardSectionTitle>Address</CardSectionTitle>
                <KeyedList
                  data={data}
                  keys={[
                    { key: "address", label: "address" },
                    { key: "state", label: "state" },
                    { key: "nationality", label: "nationality" }
                  ]}
                />
                </CardSection>*/}
            </Col>
            <Col xs={24} md={17}>
              <CardSection>
                <Tabs defaultActiveKey="1">
                  {/*                <TabPane tab="Daily Reports" key="1">
                    {data.dailyreports.map((dailyReport, i) => (
                      <div key={i}>Score: {dailyReport.total_score}</div>
                    ))}
                    </TabPane>*/}
                  <TabPane tab="Teacher Information" key="1">
                    <h3>Teacher Details</h3>
                    <KeyedList
                      overflowOnMobile
                      data={data}
                      keys={[
                        { key: "firstName", label: "first name" },
                        { key: "lastName", label: "last name" },
                        { key: "gender", label: "gender" },
                        { key: "created_at", label: "added on" }
                      ]}
                    />
                  </TabPane>
                  <TabPane tab="Teacher Classrooms" key="2">
                    <Table
                      columns={columns}
                      dataSource={data.classrooms}
                      rowKey="_id"
                      style={{ minWidth: 813 }}
                    />
                  </TabPane>
                </Tabs>
              </CardSection>
            </Col>
          </Row>
        </div>
      )}
    </Media>
  );
};

const ViewUserScreenWithRouter = withRouter(ViewUserScreen);
export default ViewUserScreenWithRouter;
