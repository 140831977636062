import gql from "graphql-tag";

export default gql`
  {
    schemeOfWork {
      _id
      session
      subject {
        name
      }
      classroomLevel
    }
  }
`;
