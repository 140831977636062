import gql from "graphql-tag";

export default gql`
  query testQuestions($filter: JSON!) {
    testQuestions(filter: $filter) {
      _id
      question
      a
      b
      c
      d
      lessonId
      correct_answer
      label
    }
  }
`;
