// Admin Component
import React from "react";
import { Query, Mutation } from "react-apollo";
import FETCH_LESSON from "../../../graphql/queries/FETCH_LESSON";
import DELETE_TEST_QUESTION from "../../../graphql/mutations/DELETE_TEST_QUESTION";
import DELETE_LESSON_ACTIVITY from "../../../graphql/mutations/DELETE_LESSON_ACTIVITY";
import ViewLessonScreen from "../../../screens/admin/ViewLessonScreen";

class ViewLessonContainer extends React.Component {
  _onDeleteTestQuestion = async (id, mutation) => {
    console.log("deleting...", id);
    try {
      const { data } = await mutation({
        variables: { _id: id }
      });

      console.log("[Deleted question]: ", data);
    } catch (e) {
      console.log("Error deleting question: ", e);
    }
  };

  _onDeleteLessonActivity = async (id, mutation) => {
    console.log(id);
    try {
      const { data } = await mutation({
        variables: { _id: id }
      });

      console.log("[Deleted question]: ", data);
    } catch (e) {
      console.log("Error deleting question: ", e);
    }
  };

  render() {
    return (
      <Mutation
        mutation={DELETE_TEST_QUESTION}
        update={(cache, { data: { deleteTestQuestion } }) => {
          const { lessons } = cache.readQuery({
            query: FETCH_LESSON,
            variables: { filter: { _id: this.props.match.params.id } }
          });
          console.log("lessssssons:", lessons);
          const lesson = lessons[0];

          lesson.competentcy_test_questions = lesson.competentcy_test_questions.filter(
            question => question._id !== deleteTestQuestion._id
          );

          cache.writeQuery({
            query: FETCH_LESSON,
            data: { lessons: [lesson] }
          });
        }}
      >
        {(deleteQuestionMutation, { loading, error }) => (
          <Mutation
            mutation={DELETE_LESSON_ACTIVITY}
            update={(cache, { data: { deleteLessonActivity } }) => {
              const { lessons } = cache.readQuery({
                query: FETCH_LESSON,
                variables: { filter: { _id: this.props.match.params.id } }
              });
              console.log("delete activity:", lessons);
              const lesson = lessons[0];

              lesson.lesson_activities = lesson.lesson_activities.filter(
                lesson => lesson._id !== deleteLessonActivity._id
              );

              cache.writeQuery({
                query: FETCH_LESSON,
                data: { lessons: [lesson] }
              });
            }}
          >
            {(deleteActivityMutation, { loading, error }) => (
              <Query
                query={FETCH_LESSON}
                variables={{ filter: { _id: this.props.match.params.id } }}
              >
                {({ loading, error, data }) => {
                  if (loading) return "Loading...";
                  if (error) return `Error! ${error.message}`;
                  const { lessons } = data;
                  const lesson = lessons[0];
                  return (
                    <ViewLessonScreen
                      onDeleteTestQuestion={id =>
                        this._onDeleteTestQuestion(id, deleteQuestionMutation)
                      }
                      onDeleteLessonActivity={id =>
                        this._onDeleteLessonActivity(id, deleteActivityMutation)
                      }
                      data={lesson}
                    />
                  );
                }}
              </Query>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}

export { ViewLessonContainer };
