import gql from "graphql-tag";

export default gql`
  mutation createLesson($lesson: LessonInputNew!) {
    createLesson(lesson: $lesson) {
      _id
      name
      content
      goals
      objectives
    }
  }
`;
