import gql from 'graphql-tag';

export default gql`
  query competencytests($filter: JSON!) {
    competencytests(filter: $filter) {
      _id
      score
      times_attempted
      date_suspended
      updated_at
      is_passed
      lesson {
        _id
        subject {
          _id
          name
        }
        name
        competentcy_test_questions {
          _id
          question
          a
          b
          c
          d
          correct_answer
          label
        }
      }
      teacherId
      answers {
        question
        question_details
        answer
        correct_answer
        is_correct
      }
    }
  }
`;
