import React from "react";
import { Query } from "react-apollo";
import ALL_LESSONS from "../../../graphql/queries/ALL_LESSONS";
import LessonsScreen from "../../../screens/LessonsScreen";

class LessonsContainer extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: null
  };

  _clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  _handleFilterChange = (name, value) => {
    const { filteredInfo } = this.state;

    this.setState({
      filteredInfo: { ...filteredInfo, [name]: !value ? null : [value] }
    });
  };

  _handleTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  // We don't need this for now
  // clearAll = () => {
  //   this.setState({
  //     filteredInfo: null,
  //     sortedInfo: null
  //   });
  // };

  render() {
    let { sortedInfo, filteredInfo } = this.state;
    console.log("filteredInfo", filteredInfo);
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    return (
      <Query query={ALL_LESSONS}>
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) console.log("[Error fetching classrooms]: ", error);
          const { lessons } = data;
          return (
            <LessonsScreen
              tableOptions={{
                sortedInfo,
                filteredInfo,
                handleTableChange: this._handleTableChange,
                handleFilterChange: this._handleFilterChange,
                clearFilters: this._clearFilters
              }}
              data={lessons}
            />
          );
        }}
      </Query>
    );
  }
}

export { LessonsContainer };
