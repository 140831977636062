import React from "react";
import { Mutation, Query } from "react-apollo";
import EDIT_LESSON from "../../../graphql/mutations/EDIT_LESSON";
import FETCH_LESSON from "../../../graphql/queries/FETCH_LESSON";
import ALL_SUBJECTS from "../../../graphql/queries/ALL_SUBJECTS";
import EditLessonForm from "../../../screens/forms/AddOrEditLessonForm";

export class EditLessonContainer extends React.Component {
  _handleEditLesson = async (values, mutation) => {
    const {
      __typename,
      created_at,
      updated_at,
      verified,
      _id,
      ...allowedFields
    } = values;
    try {
      const { data } = await mutation({
        variables: { _id: this.props.match.params.id, lesson: allowedFields }
      });

      console.log("[Edited Lesson]: ", data);
      this.props.history.push(`/main/admin/lesson/${data.editLesson._id}`);
    } catch (e) {
      console.log("Error editing lesson: ", e);
    }
  };

  render() {
    return (
      <Query
        query={FETCH_LESSON}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const [lesson] = data.lessons;

          return (
            <Query query={ALL_SUBJECTS}>
              {({ loading, error, data: subjectQuery }) => {
                console.log("data is", subjectQuery);
                return (
                  <Mutation mutation={EDIT_LESSON}>
                    {(mutation, { loading, error }) => {
                      return (
                        <EditLessonForm
                          subjects={subjectQuery.subjects}
                          lesson={lesson}
                          onSubmit={values =>
                            this._handleEditLesson(values, mutation)
                          }
                        />
                      );
                    }}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}
