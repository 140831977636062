import React from 'react'
import { Query, Mutation } from 'react-apollo';
import ALL_SCHEME_OF_WORK from '../../../graphql/queries/ALL_SCHEME_OF_WORK';
import ViewAllSchemeOfWorkScreen from '../../../screens/SchemeOfWorkScreen';

class SchemeOfWorkContainer extends React.Component {

  render() {
    return (
      <Query
      query={ALL_SCHEME_OF_WORK}
      >
      {({loading, data, error}) => {
        if (loading) return "Loading...";
        if (error) return `Error! ${error.message}`;
        const { schemeOfWork } = data;
        return (
        <ViewAllSchemeOfWorkScreen schemes={schemeOfWork} />
        )
        
      }}
      </Query>
      
    )
  }
}

export { SchemeOfWorkContainer };
