import gql from 'graphql-tag';

export default gql`
  query schools($filter: JSON!) {
    schools(filter: $filter) {
      _id
      name
      local_govt_area
      state
      code
      address
      city
      created_at
      updated_at
    }
  }
`;
