import React from "react";
import { Tooltip, Button } from "antd";
import { styleConstants } from "../../constants";

const TitleBar = ({ buttons, title, style, explainerText, helperTooltip }) => (
  <div style={style}>
    <div style={{ marginBottom: "20px" }}>
      <h2>{title}</h2>
      {explainerText && <p>{explainerText}</p>}
      {helperTooltip && (
        <Tooltip placement="right" title={helperTooltip}>
          <Button
            style={{ color: styleConstants.color.blue }}
            icon="question-circle"
          >
            Help
          </Button>
        </Tooltip>
      )}
    </div>
    <div>
      {buttons &&
        buttons.map((button, i) => (
          <React.Fragment key={i}>{button}</React.Fragment>
        ))}
    </div>
  </div>
);

export default TitleBar;
