import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import LoginForm from "./LoginScreen";
import SignupForm from "./SignupScreen";
import RequestVerificationForm from "./RequestVerificationScreen";
import VerifyUserForm from "./VerifyUserScreen";
import styled from "styled-components";
import logo from "../../assets/img/makaranta_logo.png";
import bgImage from "../../assets/img/african-school-banner.png";
import { styleConstants } from "../../constants";

const Button = styled.button`
  border: 1px solid rgba(45, 49, 56, 0.15);
  border-radius: 16px;
  padding-right: 16px;
  padding-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  min-width: 86px;
  height: 32px;
  text-transform: uppercase;
  font-weight: bold;
  background: none;
  transition: background-color 0.1s ease-in-out;
  margin-right: 12px;
  outline: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const Logo = styled.div`
  width: 30%;

  img {
    width: 100%;
  }
`;

const BlueButton = styled(Button)`
  background: #3d84f0;
  border-color: #3d84f0;
  color: #fff;

  &:hover {
    background-color: #2e6fd2;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const AuthWrapper = styled.div`
  min-height: 100vh;
  display: flex;

  .form-content {
    width: 35%;
    position: relative;
    padding: 32px;

    .form-container {
      margin-top: 32px;
      padding: 0 20px;
      width: 100%;
    }

    .footnote {
      position: absolute;
      bottom: 5px;
      font-size: 12px;
      text-align: center;
      color: #dedede;
    }

    @media only screen and (max-width: 1340px) {
      width: 50%;
    }

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  .fancy-section {
    align-items: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 65%;
    background-image: url(${bgImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    padding: 48px;

    &:before {
      content: " ";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      background: ${styleConstants.color.yellow};
      opacity: 0.65;
    }

    h1 {
      color: white;
      font-size: 56px;
      z-index: 1;
    }

    h3 {
      color: white;
      font-size: 25px;
      max-width: 540px;
      text-align: center;
      z-index: 1;
    }

    @media only screen and (max-width: 1340px) {
      width: 50%;
    }

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .flex-centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-column {
    flex-direction: column;
  }
`;

class Auth extends React.Component {
  _showLoginModal = () => this.props.history.push("/auth/login");

  _showSignupModal = () => this.props.history.push("/auth/signup");

  render() {
    return (
      <AuthWrapper>
        <div className="form-content flex-centered flex-column">
          <Logo>
            <img src={logo} alt="Makaranta Logo" />
          </Logo>
          <h1>Welcome to Makaranta</h1>
          <section className="form-container">
            {/* the routes simply inject a form into the view */}
            <Switch>
              <Route path="/auth/login" component={LoginForm} />
              <Route path="/auth/signup" component={SignupForm} />
              <Route
                path="/auth/request_verification"
                component={RequestVerificationForm}
              />
              <Route path="/auth/verify_user" component={VerifyUserForm} />
              <Redirect to="/auth/login" />
            </Switch>
          </section>
          <div className="footnote">
            ©2018-2019 All Rights Reserved. Makaranta is a project created by
            the Orderly Society Trust in collaboration with DCM.
          </div>
        </div>
        <div className="fancy-section">
          <h1>Classroom Automation Suite</h1>
          <h3>
            For managing, enrolling and reporting students and student
            performance
          </h3>
        </div>
      </AuthWrapper>
    );
  }
}

export default Auth;
