import React from "react";
import { List } from "antd";
import styled from "styled-components";

const Div = styled.div`
  margin-bottom: 48px;

  h5 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    color: #3f7af6;
    margin-bottom: 12px;
  }
`;

// Component takes a string with \n and renders them one after the other
const DisplayList = ({ title, data: string, render }) => {
  // we want DisplayList to be able to take in a renderprop now

  if (render && typeof render == "function") {
    return (
      <Div>
        <h5>{title}</h5>
        {render(title, string)}
      </Div>
    );
  }

  if (!string || typeof string == "undefined") return null;
  return (
    <Div>
      <h5>{title}</h5>
      {string
        .split("\n")
        .map((entry, i) => entry.length > 0 && <p key={i}>{entry}</p>)}
    </Div>
  );
};

export default DisplayList;
