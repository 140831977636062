import React from 'react';
import { Query, Mutation } from 'react-apollo';
import FETCH_COMPETENCY_TEST from '../../../graphql/queries/FETCH_COMPETENCY_TESTS';
import SUBMIT_ANSWERS from '../../../graphql/mutations/UPDATE_COMPETENCY_TEST';
import ViewCompetencyTestScreen from '../../../screens/ViewCompetencyTestScreen';

export class ViewCompetencyTestContainer extends React.Component {
  state = {
    answers: [],
  };

  _onSelectAnswer = (question, answer) => {
    const correct_answer = question.correct_answer;
    const answerObject = {
      question: question._id,
      correct_answer,
      answer,
      label: question.label || [],
    };

    let addedAnswerDirectly;
    const answers = this.state.answers.map(answer => {
      if (answerObject.question == answer.question) {
        addedAnswerDirectly = true;
        return answerObject;
      }
      return answer;
    });

    this.setState({
      answers: addedAnswerDirectly
        ? answers
        : this.state.answers.concat([answerObject]),
    });
  };

  _onSubmitAnswers = async (mutation, test, cb) => {
    console.log(this.state.answers);

    // we just extract the uneeded/unallowed fields and leave the allowed ones
    const {
      __typename,
      answers,
      lesson,
      updated_at,
      _id,
      ...allowedFields
    } = test;
    try {
      const { data } = await mutation({
        variables: {
          _id: test._id,
          edit: { ...allowedFields, lessonId: lesson._id },
          answers: this.state.answers,
        },
      });
      console.log('[Back again]: ', data);
      // clear out state answers
      this.setState({
        answers: [],
      });
      const { updateCompetencyTest: updatedTest } = data;
      // if the test wasn't passed tell the user that another set of questions have been served via popup.We use a callback that's provided by the Screen Component to handle this
      if (!updatedTest.is_passed) {
        cb(updatedTest);
      }
      // this._handleNextRoute();
      // rather than handle routing here, we let the screen handle all the variables - test being passed, number of retries exceeeded etc.
    } catch (e) {
      console.log('Error submitting test answers: ', e);
    }
  };

  render() {
    return (
      <Mutation mutation={SUBMIT_ANSWERS}>
        {(mutation, { data, errors, loading }) => (
          <Query
            query={FETCH_COMPETENCY_TEST}
            variables={{ filter: { _id: this.props.match.params.id } }}
          >
            {({ loading, error, data }) => {
              if (loading) return 'Loading...';
              if (error) return `Error! ${error.message}`;
              const { competencytests } = data;
              const competencytest = competencytests[0];
              return (
                <ViewCompetencyTestScreen
                  onRowSelect={this._handleSelectRow}
                  data={competencytest}
                  answers={this.state.answers}
                  onSelectAnswer={this._onSelectAnswer}
                  onSubmitAnswers={cb =>
                    this._onSubmitAnswers(mutation, competencytest, cb)
                  }
                />
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}
