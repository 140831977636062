import React from "react";
import { VictoryPie } from "victory";
import styled from "styled-components";

const Div = styled.div`
  position: relative;

  .chart-info {
    position: absolute;
    bottom: 0;

    p {
      margin: 4px;
      font-size: 80%;
      font-weight: bold;
    }

    span {
      text-transform: uppercase;
    }
  }
`;

class PieChart extends React.Component {
  render() {
    const { data, title } = this.props;

    return (
      <Div>
        <h4>{title}</h4>
        <VictoryPie
          data={data}
          colorScale={["tomato", "orange", "gold", "cyan", "navy"]}
          innerRadius={100}
          padAngle={3}
        />
        <div className="chart-info">
          {data.map(({ x: label, y: value }) => {
            return (
              <p key={label}>
                <span>{label}</span>: {value}
              </p>
            );
          })}
        </div>
      </Div>
    );
  }
}

export default PieChart;
