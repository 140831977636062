import gql from "graphql-tag";

export default gql`
  mutation createUser($user: SignupInput!) {
    signup(user: $user) {
      user {
        _id
        firstName
        lastName
        email
        phone
        verified
      }
      token
      errors {
        message
      }
    }
  }
`;
