import gql from "graphql-tag";

export default gql`
  mutation createTestQuestion($testQuestion: TestQuestionInputNew!) {
    createTestQuestion(testQuestion: $testQuestion) {
      _id
      question
      label
      a
      b
      c
      d
      lessonId
    }
  }
`;
