import React from "react";
import { UserConsumer } from "../../App";
import { Switch, Route, Redirect } from "react-router-dom";

import MainContentScreen from "../../screens/MainContentScreen";
import { deleteUserAuthData } from "../../utils/persistStorage";
import { ApolloConsumer } from "react-apollo";
import {
  dashboardRoutes,
  adminDashboardRoutes,
  seniorTeacherDashboardRoutes,
  headTeacherDashboardRoutes
} from "../../config/routes";
import AccessDenied from "../AccessDenied";

class MainContent extends React.PureComponent {
  _onLogout = client => {
    deleteUserAuthData();
    client.resetStore();
    this.props.history.push("/");
  };

  render() {
    return (
      <ApolloConsumer>
        {client => (
          <UserConsumer>
            {user => {
              const userIsAdmin = user && user.role == "admin";
              const userIsHeadTeacher = user && user.role == "head_teacher";
              const userIsSeniorTeacher = user && user.role == "senior_teacher";
              const userIsSuspended = user && user.isSuspended;
              const userIsDeleted = user && user.deletedAt;

              return userIsSuspended || userIsDeleted ? (
                <AccessDenied userIsSuspended userIsDeleted />
              ) : (
                <MainContentScreen
                  user={user}
                  onLogout={() => this._onLogout(client)}
                >
                  {user && userIsAdmin && (
                    <Switch>
                      {adminDashboardRoutes.map(
                        ({ path, component: Component, redirect }, i) => {
                          if (redirect) return <Redirect key={i} to={path} />;
                          return (
                            <Route key={i} path={path} component={Component} />
                          );
                        }
                      )}
                    </Switch>
                  )}
                  {user && userIsHeadTeacher && (
                    <Switch>
                      {headTeacherDashboardRoutes.map(
                        ({ path, component: Component, redirect }, i) => {
                          if (redirect) return <Redirect key={i} to={path} />;
                          return (
                            <Route key={i} path={path} component={Component} />
                          );
                        }
                      )}
                    </Switch>
                  )}
                  {user && userIsSeniorTeacher && (
                    <Switch>
                      {console.log(
                        "these are the teacher routes: ",
                        seniorTeacherDashboardRoutes
                      )}
                      {seniorTeacherDashboardRoutes.map(
                        (
                          {
                            path,
                            component: Component,
                            redirect,
                            getRedirectPath
                          },
                          i
                        ) => {
                          if (user && redirect)
                            return (
                              <Redirect key={i} to={getRedirectPath(user)} />
                            );
                          return (
                            <Route key={i} path={path} component={Component} />
                          );
                        }
                      )}
                    </Switch>
                  )}
                  {/* important to avoid an infinite navigation/redirect loop */}
                  {user &&
                    !userIsHeadTeacher &&
                    !userIsSeniorTeacher &&
                    !userIsAdmin &&
                    !userIsSuspended &&
                    !userIsDeleted && (
                      <Switch>
                        {dashboardRoutes.map(
                          (
                            {
                              path,
                              component: Component,
                              redirect,
                              getRedirectPath
                            },
                            i
                          ) => {
                            if (user && redirect)
                              return (
                                <Redirect key={i} to={getRedirectPath(user)} />
                              );
                            return (
                              <Route
                                key={i}
                                path={path}
                                component={Component}
                              />
                            );
                          }
                        )}
                      </Switch>
                    )}
                </MainContentScreen>
              );
            }}
          </UserConsumer>
        )}
      </ApolloConsumer>
    );
  }
}

export default MainContent;
