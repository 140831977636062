import React from "react";
import { Mutation, Query } from "react-apollo";
import EDIT_LESSON_ACTIVITY from "../../../graphql/mutations/EDIT_LESSON_ACTIVITY";
import FETCH_LESSON_ACTIVITY from "../../../graphql/queries/FETCH_LESSON_ACTIVITY";
import EditLessonActivityForm from "../../../screens/forms/AddOrEditLessonActivityForm";

class EditLessonActivityContainer extends React.Component {
  _handleEditLessonActivity = async (values, mutation) => {
    const {
      __typename,
      created_at,
      updated_at,
      _id,
      ...allowedFields
    } = values;

    try {
      const {
        data: { editLessonActivity: lessonActivity }
      } = await mutation({
        variables: {
          _id: this.props.match.params.activityId,
          lessonActivity: allowedFields
        }
      });
      console.log(lessonActivity);
      this._handleNextRoute(lessonActivity.lessonId);
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  _handleNextRoute = lessonId =>
    this.props.history.push(`/main/admin/lesson/${lessonId}`);

  render() {
    return (
      <Query
        query={FETCH_LESSON_ACTIVITY}
        variables={{ filter: { _id: this.props.match.params.activityId } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { lessonActivities } = data;
          const lessonActivity = lessonActivities[0];

          return (
            <Mutation mutation={EDIT_LESSON_ACTIVITY}>
              {(mutation, { loading, error }) => {
                const lessonId = this.props.match.params.lessonId;
                return (
                  <EditLessonActivityForm
                    lessonId={lessonId}
                    lessonActivity={lessonActivity}
                    onSubmit={values =>
                      this._handleEditLessonActivity(values, mutation)
                    }
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export { EditLessonActivityContainer };
