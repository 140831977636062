import React from "react";
import Query from "react-apollo/Query";
import FETCH_WEEKLY_REPORT from "../../../graphql/queries/FETCH_WEEKLY_REPORT";
import ViewWeeklyReportScreen from "../../../screens/ViewWeeklyReportScreen";


class ViewWeeklyReportContainer extends React.Component {
  render() {
    return (
      <Query
        query={FETCH_WEEKLY_REPORT}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { weeklyReports } = data;
          const weeklyReport = weeklyReports[0];
          return <ViewWeeklyReportScreen data={weeklyReport} />;
        }}
      </Query>
    );
  }
}

export { ViewWeeklyReportContainer };
