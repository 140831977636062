import gql from "graphql-tag";

export default gql`
  query weeklyReports($filter: JSON!) {
    weeklyReports(filter: $filter) {
      _id
      startDate
      created_at
      updated_at
      endDate
      total_score
      percentage_score
      score_group
      student {
        _id
        firstName
        lastName
      }
      classroom {
        _id
        name
        subject {
          _id
          name
        }
      }
      reports {
        _id
        assessment_score {
          total_score
        }
        classroom_observation_score {
          total_score
        }
        group_observation_score {
          total_score
        }
        lesson {
          _id
          name
        }
        date
        total_score
        percentage_score
        score_group
      }
    }
  }
`;
