import gql from "graphql-tag";

export default gql`
  mutation deleteUser($_id: String!) {
    deleteUser(_id: $_id) {
      _id
      firstName
      lastName
      email
    }
  }
`;
