import React from "react";
import { Row, Col, Button, Card, Icon } from "antd";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table } from "antd";
import { Link } from "react-router-dom";
import TitleBar from "../components/common/TitleBar";
import Avatar from "../components/common/Avatar";
import KeyedList from "../components/common/KeyedList";
import { Tabs } from "antd";
import CardSection, {
  CardSectionTitle
} from "../components/common/CardSection";
import ReportOverviewWidget from "../components/common/ReportOverviewWidget";
import { WeekReportsChart } from '../components/common/WeekReportsChart';

const TabPane = Tabs.TabPane;

const FlexedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const ScrollableDiv = styled.div`
  overflow: scroll;
  height: 350px;
`;

const ViewWeeklyReportScreen = ({ data: weeklyReport, history }) => {

  const { student, classroom, reports: dailyReports } = weeklyReport;
  console.log(dailyReports, '<<<')
  
  const weekReportChartData = dailyReports
  .reduce((chartData, report, index) => {
    chartData[0].data.push({
      x: `Day ${index + 1}`,
      y: report.total_score,
      date: `${moment(report.date).format("MMM Do YY")}`,
      lesson: report.lesson.name
    });
    return chartData;
  },
  [
    {
      id: "week report",
      color: "hsl(110, 70%, 50%)",
      data: []
    }
  ]);

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="Weekly Report"
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
          />
          <Row gutter={12}>
            <Col xs={24} md={7}>
              <CardSection>
                <Avatar style={{ margin: "0 auto" }} />
                <h2 className="card-section-title">{`${student.firstName} ${
                  student.lastName
                }`}</h2>
                <KeyedList
                  data={weeklyReport}
                  keys={[
                    {
                      key: "total_score",
                      label: "Total Score",
                      suffix: "(out of 500)"
                    },
                    {
                      key: "percentage_score",
                      label: "Percentage Score",
                      render: value => {
                        return value.toFixed(2);
                      },
                      suffix: "%"
                    },
                    {
                      key: "score_group",
                      label: "score group",
                      render: value => value.split("_").join(" ")
                    },
                    {
                      key: "startDate",
                      label: "Start Date"
                    },
                    { key: "endDate", label: "end date" },
                    { key: "created_at", label: "created" },
                    { key: "updated_at", label: "last updated" }
                  ]}
                />
              </CardSection>
            </Col>
            <Col xs={24} md={17}>
              <CardSection>
                <FlexedDiv>
                  <h1>Report Details</h1>
                  <ReportOverviewWidget report={weeklyReport} />
                </FlexedDiv>
                <KeyedList
                  overflowOnMobile
                  data={student}
                  keys={[
                    { key: "firstName", label: "Student First Name" },
                    { key: "lastName", label: "Student Last Name" }
                  ]}
                />
                <KeyedList
                  overflowOnMobile
                  data={classroom}
                  keys={[
                    { key: "name", label: "Classroom" },
                    { key: "subject.name", label: "Subject" }
                  ]}
                />
                <h3 style={{ margin: "25px 0" }}>Daily Reports</h3>
                <ScrollableDiv>
                
                {dailyReports.map(report => {
                  return (
                    <Card
                      style={{ marginBottom: 25 }}
                      key={report._id}
                      title={`${moment(report.date).format(
                        "dddd, MMMM Do YYYY"
                      )}`}
                      actions={[
                        <Link to={`/main/daily-report/${report._id}`}>
                          <Icon
                            style={{ marginRight: 10 }}
                            type="line-chart"
                            theme="outlined"
                          />
                          View Report
                        </Link>,
                        <Link to={`/main/edit-daily-report/${report._id}`}>
                          <Icon style={{ marginRight: 10 }} type="edit" />
                          Edit Report
                        </Link>
                      ]}
                    >
                      <KeyedList
                        data={report}
                        keys={[
                          { key: "lesson.name", label: "Lesson" },
                          {
                            key: "assessment_score.total_score",
                            label: "Assessment Score",
                            suffix: "(out of 50)"
                          },
                          {
                            key: "classroom_observation_score.total_score",
                            label: "Classroom Observation Score",
                            suffix: "(out of 5)"
                          },
                          {
                            key: "group_observation_score.total_score",
                            label: "Group Observation Score",
                            suffix: "(out of 45)"
                          },
                          {
                            key: "total_score",
                            label: "Total Score",
                            suffix: "(out of 100)"
                          },
                          {
                            key: "percentage_score",
                            label: "Percentage Score",
                            render: value => {
                              return value.toFixed(2);
                            },
                            suffix: "%"
                          },
                          {
                            key: "score_group",
                            label: "score group",
                            render: value => value.split("_").join(" ")
                          }
                        ]}
                      />
                    </Card>
                  );
                })}
                </ScrollableDiv>
                <h3 style={{ margin: "25px 0" }}>Week Report Chart</h3>
                <WeekReportsChart
                  weekReportChartData={weekReportChartData}
                  bottomAxisLabel='Days' leftAxisLabel='Total Score'
                  scoreTitle='Total Score:'
                />
              </CardSection>
            </Col>
          </Row>
        </div>
      )}
    </Media>
  );
};

const ViewWeeklyReportScreenWithRouter = withRouter(ViewWeeklyReportScreen);
export default ViewWeeklyReportScreenWithRouter;
