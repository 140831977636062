import moment from 'moment';

export const getAverageDailyReport = (classroomId, classrooms) => {
  const selectedClassroom = classrooms.find(classroom => classroom._id === classroomId)

  const { daily_reports } = selectedClassroom;

  const orderedDates = daily_reports.reduce((acc, cur) => {
    const day = cur.created_at.split('T')[0];

    return Object.assign(acc, {
      [day]: acc[day] ? (acc[day] + cur.total_score) / Object.values(cur).length : cur.total_score
    });
  }, {});

  const graphData = Object.keys(orderedDates).map(date => ({
    date,
    total_score: orderedDates[date]
  }));

  const averageReportData = graphData.slice(Math.max(graphData.length - 7, 0))
  .reduce((chartData, report, index) => {
    chartData[0].data.push({
      x: `Day ${index + 1}`,
      y: report.total_score,
      date: report.date !== '' ? `${moment(report.date).format("MMM Do YY")}` : '',
    });
    return chartData;
  },
    [
      {
        id: "week report",
        color: "hsl(110, 70%, 50%)",
        data: []
      }
    ])

  return averageReportData
}


export const getWeeklyReportChartData = (unrefinedChartData) => {
  return unrefinedChartData.slice(Math.max(unrefinedChartData.length - 7, 0))
  .reduce((chartData, report, index) => {
    chartData[0].data.push({
      x: `Day ${index + 1}`,
      y: report.total_score,
      date: `${moment(report.date).format("MMM Do YY")}`,
      lesson: report.lesson && report.lesson.name
    });
    return chartData;
  },
  [
    {
      id: "week report",
      color: "hsl(110, 70%, 50%)",
      data: []
    }
  ])
  
}