import React from "react";
import { Mutation } from "react-apollo";
import CREATE_LESSON_ACTIVITY from "../../../graphql/mutations/CREATE_LESSON_ACTIVITY";
import AddLessonActivityForm from "../../../screens/forms/AddOrEditLessonActivityForm";

class AddLessonActivityContainer extends React.Component {
  _handleAddLessonActivity = async (values, mutation) => {
    // add the lessonId
    values.lessonId = this.props.match.params.lessonId;

    try {
      const {
        data: { createLessonActivity: lessonActivity }
      } = await mutation({ variables: { lessonActivity: values } });
      console.log(lessonActivity);
      this._handleNextRoute(lessonActivity.lessonId);
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  _handleNextRoute = lessonId =>
    this.props.history.push(`/main/admin/lesson/${lessonId}`);

  render() {
    return (
      <Mutation mutation={CREATE_LESSON_ACTIVITY}>
        {(mutation, { loading, error }) => {
          const lessonId = this.props.match.params.lessonId;
          return (
            <AddLessonActivityForm
              lessonId={lessonId}
              lessonActivity={null}
              onSubmit={values =>
                this._handleAddLessonActivity(values, mutation)
              }
            />
          );
        }}
      </Mutation>
    );
  }
}

export { AddLessonActivityContainer };
