import React, { Component } from "react";
import Query from "react-apollo/Query";
import CURRENT_USER from "./graphql/queries/CURRENT_USER";
import { Route, Switch, Redirect } from "react-router-dom";
import Auth from "./containers/Auth";
import MainContent from "./containers/MainContent";
import idx from "idx";
import { ApolloProvider } from "react-apollo";
import clientWith from "./config/apollo";
import { retrieveUserAuthToken } from "./utils/persistStorage";
import { notification } from "antd";

const UserContext = React.createContext();
export const UserConsumer = UserContext.Consumer;

class App extends Component {
  state = {
    loginError: ""
  };

  openNotificationWithIcon = type => {
    notification.error({
      placement: "bottomRight",
      message: "Authentication Error",
      description: "Please try and log into your Makaranta account again",
      duration: 10
    });
  };

  render() {
    const token = retrieveUserAuthToken();

    return (
      <ApolloProvider
        client={clientWith(token, process.env.REACT_APP_GRAPHQL_URI)}
      >
        <Query query={CURRENT_USER}>
          {({ loading, error, data }) => {
            let isAuthError;

            if (error) {
              console.log("We had an error", error);
              const errorCode =
                error.graphQLErrors[0] && error.graphQLErrors[0].data.code;
              isAuthError = errorCode === 403 || errorCode === 400;

              {
                /* Taking this out to remove the popup */
              }
              {
                /* if (isAuthError) {
                  this.openNotificationWithIcon();
                } */
              }
            }

            const currentUser = idx(data, _ => _.currentUser);
            return (
              <UserContext.Provider value={currentUser}>
                {currentUser && currentUser.role == "admin" ? (
                  <Switch>
                    <Route path="/main" component={MainContent} />
                    {token ? <Redirect to="/main" /> : <Redirect to="/" />}
                  </Switch>
                ) : currentUser && currentUser.role == "head_teacher" ? (
                  <Switch>
                    <Route path="/main" component={MainContent} />
                    {token ? <Redirect to="/main" /> : <Redirect to="/" />}
                  </Switch>
                ) : currentUser && currentUser.role == "senior_teacher" ? (
                  <Switch>
                    <Route path="/main" component={MainContent} />
                    {token ? <Redirect to="/main" /> : <Redirect to="/" />}
                  </Switch>
                ) : (
                  <Switch>
                    <Route path="/auth" component={Auth} />
                    <Route path="/main" component={MainContent} />
                    {console.log("is it an auth error: ", isAuthError)}
                    {token ? <Redirect to="/main" /> : <Redirect to="/auth" />}
                  </Switch>
                )}
              </UserContext.Provider>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

export default App;
