import React from 'react';
import { Query, Mutation } from 'react-apollo';
import SchoolsScreen from '../../../screens/admin/SchoolsScreen';
import ALL_SCHOOLS from '../../../graphql/queries/ALL_SCHOOLS';
import DELETE_SCHOOL from '../../../graphql/mutations/DELETE_SCHOOL';
// import schools from '../../../lib/mock_schools';


export class SchoolsContainer extends React.Component {

  _onDelete = async (id, mutation) => {
    try {
      const { data } = await mutation({
        variables: { _id: id }
      });

      console.log("[Deleted school]: ", data);
    } catch (e) {
      console.log("Error deleting school: ", e);
    }
  };

  render() {
    return (
      <Query query={ALL_SCHOOLS}>
        {({ loading, error, data }) => {
          if (loading) return 'loading...';
          if (error) console.log('Errror fetching schools:', error);
          const { schools } = data;
          return (
            <Mutation
              mutation={DELETE_SCHOOL}
              update={(cache, { data: { deleteSchool } }) => {
                const { schools } = cache.readQuery({ query: ALL_SCHOOLS });
                cache.writeQuery({
                  query: ALL_SCHOOLS,
                  data: { schools: schools.filter(school => school._id !== deleteSchool._id) }
                });
              }}
            >
              {(mutation, { loading, error }) => {
                return (
                  <SchoolsScreen
                    data={schools}
                    onDelete={id => this._onDelete(id, mutation)}
                  />
                )
              }}
            </Mutation>
          );
        }}
      </Query>
    )
  }
}
