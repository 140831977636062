import gql from "graphql-tag";

export default gql`
  {
    competencytests {
      _id
      score
      times_attempted
      date_suspended
      is_passed
      teacher {
        _id
        firstName
        lastName
        email
      }
      lesson {
        _id
        subject {
          _id
          name
        }
        name
        competentcy_test_questions {
          _id
          question
          a
          b
          c
          d
          correct_answer
        }
      }
      answers {
        question
        answer
        correct_answer
        is_correct
      }
    }
  }
`;
