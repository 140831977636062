import React from "react";
import ReactDOM from "react-dom";
import "./styles/animate.css";
import "./styles/index.css";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: "https://5ae2a6fb25cc48a8b1173db32c8f80e9@o53206.ingest.sentry.io/5226891",
  attachStacktrace: true,
  environment: process.env.NODE_ENV,
  beforeSend(event) {
    if (event.exception) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
    return event;
  }
});



const rootEl = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/" component={App} />
    </Switch>
  </BrowserRouter>,
  rootEl
);

if (module.hot) {
  module.hot.accept("./App", () => {
    ReactDOM.render(
      <BrowserRouter>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </BrowserRouter>,
      rootEl
    );
  });
}



// 4. Add new view for Qualification badges and recommended courses to Teacher’s profile
// 5. Create view for Head Teacher.Future iteration ? (hybrid of Teacher[without recommended courses]and Admin profile]
// round up teacher recommended calculations


// work on the v1 roles - add scheme of work
// v1 role- complete senior teacher view
// v1 role - admin open comptency test list view



// 2. Set up test environment and clear test data from live environment -----> done-ish
// 3. Learning paths - remove tab ? ----> done
// 6. Make v1 and 2 mobile friendly ----> done