import React from 'react'
import Media from "react-media";
import { Link, withRouter } from "react-router-dom";
import { Table, Row, Col, Button, Divider, Modal } from 'antd'
import TitleBar from "../components/common/TitleBar";

const { confirm } = Modal;

const ViewAllSchemeOfWorkScreen = ({ schemes, user, history, onDelete }) => {

  const showDeleteConfirm = (recordId, session) => {
    confirm({
      title: `Are you sure you want to delete the scheme of work for ${session}`,
      okType: 'danger',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        onDelete(recordId);
      },
    });
  }

  const columns = [
    {
      title: "Session",
      dataIndex: "session",
      key: "session",
      render: (text, record) => (
        <Link to={`/main/scheme-of-work/${record._id}`}>{text}</Link>
      )
    },
    {
      title: "Subject",
      dataIndex: "subject.name",
      key: "subject.name"
    },
    {
      title: "Classroom Level",
      dataIndex: "classroomLevel",
      key: "classroomLevel"
    },
    {
      title: user && user.role === 'admin' && "Action",
      key: "action",
      render: (text, record) => (
        <span>
          {user && user.role === 'admin' &&
            <React.Fragment>
              <Divider type="vertical" />
              <a
                href="javascript:;"
                onClick={() => showDeleteConfirm(record._id, `${record.session}`)}
              > Delete</a>
            </React.Fragment>
          }
        </span>
      )
    }
  ];

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title={'All Schemes of Work'}
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
            buttons={user && ['admin', 'head_teacher'].includes(user.role) && [
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="user-add"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() => history.push('/main/admin/add-scheme-of-work')}
              >
                Add new Scheme of Work
            </Button>
            ]}
          />
          <div>
            <Table
              columns={columns}
              dataSource={schemes}
              rowKey="_id"
              style={{ minWidth: 813, marginTop: 15 }}
            />
          </div>
        </div>
      )}
    </Media>
  )
}
const ViewAllSchemeOfWorkScreenWithRouter = withRouter(ViewAllSchemeOfWorkScreen)
export default ViewAllSchemeOfWorkScreenWithRouter;
