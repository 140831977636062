import gql from "graphql-tag";

export default gql`
  {
    subjects {
      _id
      name
      code
      lessons {
        _id
        name
      }
    }
  }
`;
