import gql from "graphql-tag";

export default gql`
  {
    classrooms {
      _id
      created_at
      updated_at
      name
      teacher {
        _id
        firstName
        lastName
      }
      classroomLevel
      subject {
        _id
        name
        code
      }
      isActive
      students {
        _id
        firstName
        lastName
        daily_reports {
          _id
          created_at
          subjectId
          date
          lessonId
          assessment_score {
            total_score
          }
          classroom_observation_score {
            total_score
          }
          group_observation_score {
            total_score
          }
          total_score
          percentage_score
          score_group
        }
        weekly_reports {
          _id
          created_at
          subjectId
          startDate
          endDate
          total_score
          percentage_score
          score_group
        }
      }
    }
  }
`;
