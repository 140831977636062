import gql from "graphql-tag";

export default gql`
  query students($filter: JSON!) {
    students(filter: $filter) {
      _id
      firstName
      lastName
      created_at
      dateOfBirth
      gender
      address
      state
      nationality
      teacher {
        _id
        firstName
        lastName
      }
      classroomId
      classroom {
        _id
        name
      }
      isActive
      guardianFirstName
      guardianLastName
      guardianPhoneNumber
      guardianEmail
      guardianOccupation
      guardianAddress
      weekly_reports {
        _id
        created_at
        subjectId
        startDate
        endDate
        total_score
        percentage_score
        score_group
        reports {
          _id
          total_score
        }
      }
      daily_reports {
        _id
        created_at
        classroom {
          _id
          subject {
            _id
            name
          }
        }
        date
        lesson {
          _id
          name
          subject {
            _id
            name
          }
        }
        assessment_score {
          total_score
        }
        classroom_observation_score {
          total_score
        }
        group_observation_score {
          total_score
        }
        total_score
        percentage_score
        score_group
      }
    }
  }
`;
