import { DCM_USER_TOKEN } from "../constants";

export function persistUserAuthData(token) {
  localStorage.setItem(DCM_USER_TOKEN, token);
}

export function retrieveUserAuthToken() {
  return localStorage.getItem(DCM_USER_TOKEN);
}

export function deleteUserAuthData() {
  return localStorage.removeItem(DCM_USER_TOKEN);
}

// export function getCookie(cookieName) {
//   var name = cookieName + "=";
//   var decodedCookie = decodeURIComponent(document.cookie);
//   console.log("decoded: ", decodedCookie);
//   var ca = decodedCookie.split(";");
//   for (var i = 0; i < ca.length; i++) {
//     var c = ca[i];
//     while (c.charAt(0) === " ") {
//       c = c.substring(1);
//     }
//     if (c.indexOf(name) === 0) {
//       return c.substring(name.length, c.length);
//     }
//   }
//   return "";
// }
