import gql from "graphql-tag";

export default gql`
  {
    students {
      _id
      firstName
      lastName
      created_at
      dateOfBirth
      gender
      address
      state
      nationality
      teacher {
        _id
        firstName
        lastName
      }
      classroom {
        _id
        name
      }
      isActive
      guardianFirstName
      guardianLastName
      guardianPhoneNumber
      guardianEmail
      guardianOccupation
      guardianAddress
    }
  }
`;
