import gql from "graphql-tag";

export default gql`
  mutation createLessonActivity($lessonActivity: LessonActivityInputNew!) {
    createLessonActivity(lessonActivity: $lessonActivity) {
      _id
      lessonId
    }
  }
`;
