import gql from "graphql-tag";

export default gql`
  mutation resetPassword(
    $_id: String!
    $password: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      _id: $_id
      password: $password
      confirmPassword: $confirmPassword
    ) {
      _id
      firstName
      lastName
    }
  }
`;
