import React from "react";
import styled from "styled-components";
import { Form, Input, Button, Select, Icon } from "antd";
import { UserConsumer } from "../../App";
import idx from "idx";
const FormItem = Form.Item;
const Option = Select.Option;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const FormWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.45);
  display: flex;
  justify-content: center;

  .app-modal {
    padding: 24px;
    margin-top: 48px;
    width: 400px;
    max-height: 640px;
    overflow: scroll;
    background-color: #ffffff;
    box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;
  }

  @media only screen and (max-width: 375px) {
    position: fixed;

    .app-modal {
      margin-top: 0;
    }
  }
`;

class AddClassroomForm extends React.Component {
  state = {
    selectedSubject: ""
  };
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
      }
      this.props.onSubmit(values);
    });
  };

  handleSelectSubject = value => {
    const { subjects } = this.props.data;
    const { name } = subjects.filter(subject => subject._id === value)[0];
    this.setState({ selectedSubject: name });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { subjects } = this.props.data;
    const { selectedSubject } = this.state;
    return (
      <UserConsumer>
        {user => (
          <FormWrapper>
            <div className="app-modal animated fadeInUpBig">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => this.props.history.goBack()}
              >
                <Icon type="arrow-left" style={{ color: "rgba(0,0,0,.25)" }} />
              </div>
              <h3>Create Classroom</h3>
              <Form onSubmit={e => this.handleSubmit(e)}>
                <FormItem label="Classroom Name">
                  {getFieldDecorator("name", {
                    initialValue: `${idx(user, _ => _.firstName)}'s-${
                      selectedSubject ? selectedSubject : "[Subject]"
                    }-Class`
                  })(<Input placeholder="Classroom Name" disabled />)}
                </FormItem>
                <FormItem label="Subject">
                  {getFieldDecorator("subjectId")(
                    <Select
                      onSelect={this.handleSelectSubject}
                      defaultActiveFirstOption
                    >
                      {subjects &&
                        subjects.map(subject => (
                          <Option key={subject._id} value={subject._id}>
                            {subject.name}
                          </Option>
                        ))}
                    </Select>
                  )}
                </FormItem>
                <FormItem label="Classroom Level">
                  {getFieldDecorator("classroomLevel")(
                    <Select defaultActiveFirstOption>
                      <Option value={"PRE_SCHOOL"}>PRE-SCHOOL</Option>
                      <Option value={"PRIMARY_1"}>PRIMARY 1</Option>
                      <Option value={"PRIMARY_2"}>PRIMARY 2</Option>
                      <Option value={"PRIMARY_3"}>PRIMARY 3</Option>
                      <Option value={"PRIMARY_4"}>PRIMARY 4</Option>
                      <Option value={"PRIMARY_5"}>PRIMARY 5</Option>
                    </Select>
                  )}
                </FormItem>
                <FormItem>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={hasErrors(getFieldsError())}
                  >
                    Create Class
                  </Button>
                </FormItem>
              </Form>
            </div>
          </FormWrapper>
        )}
      </UserConsumer>
    );
  }
}

const WrappedAddClassroomForm = Form.create()(AddClassroomForm);

export default WrappedAddClassroomForm;
