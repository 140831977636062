import React from 'react';
import moment from 'moment';
import { Table, Button, Modal, Select } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import TitleBar from '../components/common/TitleBar';
import Media from 'react-media';

const Option = Select.Option;

const CompetencyTestsScreen = ({
  data,
  history,
  isSeniorTeacher,
  isHeadTeacher,
  showLessonSelectionModal,
  onCloseModal,
  onSelectLesson,
  lessons,
  onAddCompetencyTest,
}) => {
  console.log('is senior', lessons);
  const columns = [
    {
      title: 'Lesson',
      dataIndex: 'lesson.name',
      key: 'lessonName',
    },
    {
      title: 'Score',
      dataIndex: 'score',
      key: 'score',
      render: score => (score ? score : 0),
    },
    {
      title: 'Subject',
      dataIndex: 'lesson.subject.name',
      key: 'subject',
    },
    {
      title: 'Times Attempted',
      dataIndex: 'times_attempted',
      key: 'times_attempted',
    },
    {
      title: 'Passed',
      dataIndex: 'is_passed',
      key: 'is_passed',
      render: text => (text ? 'Yes' : 'No'),
    },
    {
      title: 'Created',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => moment(text).format('MMM Do YY'),
    },
    {
      title: 'Action',
      key: 'action',
      render: record => (
        <Button onClick={() => history.push(`/main/test/${record._id}`)}>
          Take Test
        </Button>
      ),
    },
  ];

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="Competency Tests"
            explainerText={`Take a competency test in order to unlock lesson content. Click the take test button to begin`}
            helperTooltip={`You will have 3 tries to pass the lesson. After the third time you will need to contact an administrator in order to teach this content.`}
            buttons={
              isSeniorTeacher || isHeadTeacher
                ? [
                    <Button
                      size={matches ? 'small' : 'default'}
                      type="primary"
                      icon="file-add"
                      style={{ marginRight: 8, marginBottom: '.5em' }}
                      onClick={onAddCompetencyTest}
                    >
                      Add Competency Test Question
                    </Button>,
                  ]
                : []
            }
          />
          <Table
            columns={columns}
            dataSource={data}
            rowKey="_id"
            style={{ minWidth: 813 }}
          />
          {(isSeniorTeacher || isHeadTeacher) && (
            <Modal
              title="Select A Lesson"
              visible={showLessonSelectionModal}
              footer={null}
              onCancel={onCloseModal}
            >
              <p>Choose a lesson to add a competency test to</p>
              <div>
                <Select
                  style={{ display: 'block' }}
                  placeholder="Choose a lesson"
                >
                  {lessons.map(lesson => (
                    <Option
                      onClick={onSelectLesson(lesson)}
                      key={lesson._id}
                      value={lesson._id}
                    >
                      {lesson.name}
                    </Option>
                  ))}
                </Select>
              </div>
            </Modal>
          )}
        </div>
      )}
    </Media>
  );
};

const CompetencyTestsScreenWithRouter = withRouter(CompetencyTestsScreen);
export default CompetencyTestsScreenWithRouter;
