import React from 'react';
import { Query, Mutation } from 'react-apollo';
import FETCH_USER from '../../../graphql/queries/FETCH_USER';
import DELETE_USER from '../../../graphql/mutations/DELETE_USER';
import SUSPEND_USER from '../../../graphql/mutations/SUSPEND_USER';
import ViewUserScreen from '../../../screens/admin/ViewUserScreen';

export class ViewUserContainer extends React.Component {
  onSuspendOrDelete = async (id, mutation) => {
    console.log('deleting or suspending...', id, mutation);
    try {
      const { data } = await mutation({
        variables: { _id: id },
      });

      console.log('[Deleted or Suspended teacher]: ', data);
      this.props.history.push('/main/admin/users');
    } catch (e) {
      console.log('Error deleting or suspending teacher: ', e);
    }
  };

  render() {
    const filter = {
      _id: this.props.match.params.id,
    };

    return (
      <Mutation mutation={DELETE_USER}>
        {(deleteMutation, { loading, error }) => (
          <Mutation mutation={SUSPEND_USER}>
            {(suspendMutation, { loading, error }) => (
              <Query query={FETCH_USER} variables={{ filter }}>
                {({ loading, error, data }) => {
                  if (loading) return 'Loading...';
                  if (error) return `Error! ${error.message}`;
                  const { users } = data;
                  const [user] = users;
                  return (
                    <ViewUserScreen
                      onDelete={id =>
                        this.onSuspendOrDelete(id, deleteMutation)
                      }
                      onSuspend={id =>
                        this.onSuspendOrDelete(id, suspendMutation)
                      }
                      data={user}
                    />
                  );
                }}
              </Query>
            )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}
