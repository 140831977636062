import gql from "graphql-tag";

export default gql`
  mutation createSchool($school: SchoolInputNew!) {
    createSchool(school: $school) {
      _id
      name
      local_govt_area
      state
      address
      city
      created_at
      updated_at
      deleted_at
    }
  }
`;
