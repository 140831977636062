import React from "react";
import { Form, Icon, Input } from "antd";
import Button from "../../components/common/Button";
import Mutation from "react-apollo/Mutation";
import VERIFY_USER from "../../graphql/mutations/VERIFY_USER";
const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class VerifyUserScreen extends React.Component {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = async (e, mutation) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
      }
      try {
        const { data } = await mutation({ variables: values });
        // retrieve token, persist it and route back to home
        const successMessage = data.verifyUser;
        this._handleNextRoute();
      } catch (e) {
        console.log("Error signing up: ", e);
      }
    });
  };

  _handleNextRoute = () => this.props.history.push("/");

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    console.log("history", this.props.history);
    return (
      <Mutation mutation={VERIFY_USER}>
        {(mutation, { loading, error, data }) => {
          return (
            <Form onSubmit={e => this.handleSubmit(e, mutation)}>
              <FormItem>
                {getFieldDecorator("verificationCode")(
                  <Input
                    prefix={
                      <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Enter verification code"
                  />
                )}
              </FormItem>
              <FormItem>
                <Button
                  disabled={hasErrors(getFieldsError())}
                  block
                  type="primary"
                  htmlType="submit"
                  text="Verify Account"
                  buttonColor="yellow"
                />
              </FormItem>
            </Form>
          );
        }}
      </Mutation>
    );
  }
}

const WrappedVerifyUserScreen = Form.create()(VerifyUserScreen);

export default WrappedVerifyUserScreen;
