import gql from "graphql-tag";

export default gql`
  mutation updateCompetencyTest(
    $_id: String!
    $edit: CompetencyTestInputEdit!
    $answers: [CompetencyTestAnswerInput!]
  ) {
    updateCompetencyTest(_id: $_id, edit: $edit, answers: $answers) {
      _id
      score
      times_attempted
      is_passed
      date_suspended
      lesson {
        _id
        name
      }
    }
  }
`;
