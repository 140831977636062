import gql from "graphql-tag";

export default gql`
  query lessons($filter: JSON!) {
    lessons(filter: $filter) {
      _id
      created_at
      subjectId
      subject {
        _id
        name
      }
      lesson_activities {
        _id
        name
        materials
        instructions
        description
      }
      competentcy_test_questions {
        _id
        question
        a
        b
        c
        d
        correct_answer
      }
      classroomLevel
      name
      content
      goals
      objectives
      materials
      printables
      key_terms
      suggested_resources
      introduction
      development
      practice
      checks_for_understanding
      closure
      total_activity_time
      homework
      evaluation
      isActive
      deletedAt
      publicId
    }
  }
`;
