import React from "react";
import { Query } from "react-apollo";
import ALL_CLASSROOMS from "../../../graphql/queries/ALL_CLASSROOMS";
import ClassroomsScreen from "../../../screens/admin/ClassroomsScreen";

export class ClassroomContainer extends React.Component {
  render() {
    return (
      <Query query={ALL_CLASSROOMS}>
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) console.log("[Error fetching classrooms]: ", error);
          const { classrooms } = data;
          return <ClassroomsScreen data={classrooms} />;
        }}
      </Query>
    );
  }
}
