import React from "react";
import styled from "styled-components";

const Div = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export default class AccessDenied extends React.Component {
  render() {
    const { userIsDeleted, userIsSuspended } = this.props;

    return (
      <Div>
        <h2>Access Denied!</h2>
        <p>
          Your profile has been {`${userIsSuspended ? "suspended" : "deleted"}`}{" "}
          Please contact an Admin for further assistance
        </p>
      </Div>
    );
  }
}
