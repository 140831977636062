import gql from "graphql-tag";

export default gql`
  mutation reopenCompetencyTest($_id: String!) {
    reopenCompetencyTest(_id: $_id) {
      _id
      date_suspended
      is_passed
      score
      times_attempted
    }
  }
`;
