import React from "react";
import moment from "moment";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import TitleBar from "../components/common/TitleBar";
import RadioFilter from "../components/common/RadioFilter";
import TableFilters from "../components/common/TableFilters";

const StudentsScreen = ({
  data,
  history,
  students,
  onRowSelect,
  tableOptions
}) => {
  console.log("[Table Data]", data);
  const {
    sortedInfo,
    filteredInfo,
    handleTableChange,
    handleFilterChange,
    clearFilters
  } = tableOptions;

  const columns = [
    {
      title: "Topic",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/main/lesson/${record._id}`}>{text}</Link>
      )
    },
    {
      title: "Subject",
      dataIndex: "subject.name",
      key: "subject.name",
      filters: [
        {
          text: "Numeracy",
          value: "Numeracy"
        },
        {
          text: "Literacy",
          value: "Literacy"
        },
        {
          text: "History",
          value: "History"
        }
      ],
      filteredValue: filteredInfo["subject.name"] || null,
      onFilter: (value, record) => record.subject.name.includes(value)
    },
    {
      title: "School Level",
      dataIndex: "classroomLevel",
      key: "classroomLevel",
      filters: [
        {
          text: "PRE_SCHOOL",
          value: "PRE_SCHOOL"
        },
        { text: "PRIMARY_ONE", value: "PRIMARY_ONE" }
      ],
      filteredValue: filteredInfo.classroomLevel || null,
      onFilter: (value, record) => record.classroomLevel.includes(value)
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: isActive => (isActive ? "yes" : "no")
    },
    {
      title: "Added",
      dataIndex: "created_at",
      key: "created_at",
      render: date => moment(date).format("MMM Do YY")
    }
  ];

  const rowSelection = {
    onChange: onRowSelect,
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name
    })
  };

  return (
    <div>
      <TitleBar
        title="Lessons"
        explainerText={`This is the lessons page. Click a lesson to view and unlock it.`}
        helperTooltip={`Use the filters below to search for lessons based on subject and school level. Click on a lesson to view details and unlock that content by taking a competency test. You can also toggle the view to show only your unlocked lessons`}
      />
      <TableFilters>
        <RadioFilter
          name="subject.name"
          handleOnChange={handleFilterChange}
          label="subject"
          options={[
            { value: "Literacy", name: "Literacy" },
            { value: "Numeracy", name: "Numeracy" },
            { value: "History", name: "History" }
          ]}
        />
        <RadioFilter
          name="classroomLevel"
          handleOnChange={handleFilterChange}
          label="school level"
          options={[
            { value: "PRE_SCHOOL", name: "Pre-School" },
            { value: "PRIMARY_1", name: "Primary 1" },
            { value: "PRIMARY_2", name: "Primary 2" }
          ]}
        />
      </TableFilters>
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        rowKey="_id"
        style={{ minWidth: 813 }}
        onChange={handleTableChange}
      />
    </div>
  );
};

const StudentsScreenWithRouter = withRouter(StudentsScreen);
export default StudentsScreenWithRouter;
