import React from "react";
import styled from "styled-components";
import { Form, Icon, Input, Button } from "antd";
import { persistUserAuthData } from "../../utils/persistStorage";
const FormItem = Form.Item;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const WrapperEl = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;

  .auth-modal {
    padding: 24px;
    margin-top: 48px;
    width: 400px;
    height: max-content;
    overflow: scroll;
    background-color: #ffffff;
    box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;
  }
`;

class ResetPasswordForm extends React.Component {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    return (
      <WrapperEl>
        <div className="auth-modal animated fadeInUpBig">
          <div>
            <i className="fa fa-times" />
          </div>
          <h3>Reset Password</h3>
          <Form onSubmit={e => this.handleSubmit(e)}>
            <FormItem label="New Password">
              {getFieldDecorator("password", {
                rules: [
                  {
                    required: true,
                    message: "Please input the new password!"
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </FormItem>
            <FormItem label="Confirm New Password">
              {getFieldDecorator("confirmPassword", {
                rules: [
                  {
                    required: true,
                    message: "Please verify the password!"
                  }
                ]
              })(
                <Input
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  type="password"
                  placeholder="Password"
                />
              )}
            </FormItem>
            <FormItem>
              <Button
                type="primary"
                htmlType="submit"
                disabled={hasErrors(getFieldsError())}
              >
                Reset Password
              </Button>
            </FormItem>
          </Form>
        </div>
      </WrapperEl>
    );
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPasswordForm);

export default WrappedResetPasswordForm;
