import React from "react";
import styled from "styled-components";
import { styleConstants } from "../constants";
import { NavLink } from "react-router-dom";
import { Icon } from "antd";
import Media from "react-media";
import idx from 'idx'

const Sidebar = styled.div`
  background-color: ${styleConstants.color.blue};
  color: white;
  /* background-color: white; */
  min-height: 100vh;
  border-right: 1px solid #ddd;
  padding: 24px 0;
  position: fixed;
  width: 16.66666667%;
`;

const NavButton = styled.div`
  a {
    padding: 12px 24px;
    text-transform: uppercase;
    display: block;

    &:hover {
      background-color: #346ee8;
    }
    @media only screen and (max-width: 1191px) {
      text-align: center;
    }

    @media only screen and (max-width: 933px) {
      padding: 12px 8px;
      font-size: 8px;
    }
  }
`;

const SidebarScreen = ({ routes, user }) => (
  <Media query="(max-width: 1191px)">
    {matches => (
      <Sidebar>
        <h4 style={{ marginBottom: 48, paddingLeft: 24, color: 'white' }}>
          {user && (
            <div>
              <div>{`${idx(user, _ => _.firstName)} ${idx(user, _ => _.lastName)}`}</div>
              <p>{idx(user, _ => _.email)}</p>
              <div>
                <p style={{ textTransform: 'capitalize' }}>
                  Role: {user.role.split('_').join(' ')}
                </p>
              </div>
              {user.school && <div>
                <p style={{ textTransform: 'capitalize' }}>
                  School: {user.school.name}
                </p>
              </div>}
            </div>
          )}
        </h4>
        {routes.map(
          ({ name, path, redirect, iconType }, i) =>
            name && (
              <SidebarNavButton
                key={i}
                name={name}
                matches={matches}
                path={path}
                iconType={iconType}
              />
            )
        )}
      </Sidebar>
    )}
  </Media>
);

const SidebarNavButton = ({ name, matches, path, iconType }) => (
  <NavButton>
    <NavLink
      activeStyle={{ backgroundColor: "#346ee8" }}
      style={{ color: "white" }}
      to={path}
    >
      <Icon
        type={iconType}
        style={
          matches
            ? {
              fontSize: 32,
              display: "block",
              margin: "auto",
              marginBottom: 3
            }
            : { marginRight: 8, fontSize: 18 }
        }
      />
      {name}
    </NavLink>
  </NavButton>
);

export default SidebarScreen;
