import React from 'react';
import { Mutation, Query } from 'react-apollo';
import AddOrEditSchoolForm from '../../../screens/forms/AddOrEditSchoolForm'
import FETCH_SCHOOL from '../../../graphql/queries/FETCH_SCHOOL';
import EDIT_SCHOOL from '../../../graphql/mutations/EDIT_SCHOOL';

export class EditSchoolContainer extends React.Component {

  state = {
    selectedState: ''
  };

  _handleEditSchool = async (values, mutation) => {
    delete values.code;
    try {
      const { data } = await mutation({
        variables: { _id: this.props.match.params.id, edit: values }
      })

      this.props.history.push(`/main/admin/schools`);
    } catch (e) {
      console.log("Error editing student: ", e);
    }
  }
  
  _handleStateChange = (state) => {
    this.setState({ selectedState: state })
  }

  render() {
    return (
      <Query
        query={FETCH_SCHOOL}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
      {({loading, error, data}) => {
        if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { schools } = data;
          const school = schools[0];
          return (
            <Mutation mutation={EDIT_SCHOOL}>
            {(mutation, {loading, error}) => {
              return (
                <AddOrEditSchoolForm
                  school={school}
                  handleStateChange={state => this._handleStateChange(state)}
                  selectedState={this.state.selectedState === '' ? school.state : this.state.selectedState}
                  onSubmit={values => this._handleEditSchool(values, mutation)}
                />
              )
            }}
            </Mutation>
          )
      }}
      </Query>
    )
  }
}
