import React from "react";
import styled from "styled-components";
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Row,
  Col,
  Upload,
  Icon,
  message
} from "antd";
import { UserConsumer } from "../../App";
import idx from "idx";
import axios from "axios";
const FormItem = Form.Item;
const Option = Select.Option;
const { TextArea } = Input;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

const Dragger = Upload.Dragger;

class AddLessonForm extends React.Component {
  state = {
    selectedSubject: "",
    fileList: []
  };
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.props.onSubmit(values);
      }
    });
  };

  handleSelectSubject = value => {
    const { subjects } = this.props;
    const [filteredSubject] = subjects.filter(subject => subject._id === value);
    const { name } = filteredSubject;

    this.setState({ selectedSubject: name });
  };

  _onFileChange = (file, multi) => {
    this.setState(
      state => ({
        fileList: file ? (multi ? [...state.fileList, file] : [file]) : []
      }),
      // Add the file to the list then update to cloudinary
      async () => {
        // Grab the file off the fileList array
        const [file] = this.state.fileList;
        // create a formData object and append the required values
        const formData = new FormData();
        formData.append("file", file);
        formData.append("upload_preset", process.env.REACT_APP_UPLOAD_PRESET);

        // Make an unsigned upload to cloudinary using the cloud name
        const { data } = await axios.post(
          `https://api.cloudinary.com/v1_1/${
          process.env.REACT_APP_CLOUD_NAME
          }/video/upload`,
          formData
        );

        this.props.form.setFieldsValue({
          publicId: data.public_id
        });
      }
    );
  };

  render() {
    const { fileList } = this.state;

    const draggerProps = {
      multiple: false,
      accept: "video/mp4",
      onRemove: file => {
        this._onFileChange(null);
      },
      beforeUpload: file => {
        // We pass in the prop for multi upload which is false by default
        this._onFileChange(file, this.props.multi);
        return false;
      },
      fileList
    };

    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { subjects } = this.props;
    const { selectedSubject } = this.state;
    const { lesson } = this.props;
    return (
      <Form onSubmit={e => this.handleSubmit(e)}>
        <h2>{lesson ? "Edit Lesson" : "Add Lesson"}</h2>
        <FormItem label="Lesson Topic">
          {getFieldDecorator("name", {
            initialValue: idx(lesson, _ => _.name)
          })(<Input placeholder="Lesson Topic" />)}
        </FormItem>
        <Row gutter={{ md: 16 }}>
          <Col xs={24} md={12}>
            <FormItem label="Subject">
              {getFieldDecorator("subjectId", {
                initialValue: idx(lesson, _ => _.subjectId)
              })(
                <Select
                  onSelect={this.handleSelectSubject}
                  defaultActiveFirstOption
                >
                  {subjects &&
                    subjects.map(subject => (
                      <Option key={subject._id} value={subject._id}>
                        {subject.name}
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          </Col>
          <Col xs={24} md={12}>
            <FormItem label="Classroom Level">
              {getFieldDecorator("classroomLevel", {
                initialValue: idx(lesson, _ => _.classroomLevel)
              })(
                <Select defaultActiveFirstOption>
                  <Option value={"PRE_SCHOOL"}>PRE-SCHOOL</Option>
                  <Option value={"PRIMARY_1"}>PRIMARY 1</Option>
                  <Option value={"PRIMARY_2"}>PRIMARY 2</Option>
                  <Option value={"PRIMARY_3"}>PRIMARY 3</Option>
                  <Option value={"PRIMARY_4"}>PRIMARY 4</Option>
                  <Option value={"PRIMARY_5"}>PRIMARY 5</Option>
                </Select>
              )}
            </FormItem>
          </Col>
        </Row>
        <FormItem label="Content" extra="Place each new content on a new line">
          {getFieldDecorator("content", {
            initialValue: idx(lesson, _ => _.content)
          })(
            <TextArea
              style={{ height: 190 }}
              placeholder={`
              E.g
              1. Content for this lesson...
              2. Another bit of content
              3. Yet another bit of content`}
            />
          )}
        </FormItem>
        <FormItem
          label="Lesson Video"
          extra="Upload a video that explains the lesson"
        >
          <Dragger {...draggerProps}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              You can drag any .mp4 video into this area and it will be added as
              the Lesson Video
            </p>
          </Dragger>
        </FormItem>
        <FormItem
          label="Video URL"
          extra="The public ID for the video. This will be filled automatically when you upload a video above"
        >
          {getFieldDecorator("publicId", {
            initialValue: idx(lesson, _ => _.publicId)
          })(<Input disabled placeholder="Video unique identifier" />)}
        </FormItem>
        <FormItem label="Goals" extra="Place each new goal on a new line">
          {getFieldDecorator("goals", {
            initialValue: idx(lesson, _ => _.goals)
          })(
            <TextArea
              style={{ height: 190 }}
              placeholder="1. Goal number one for this lesson is..."
            />
          )}
        </FormItem>
        <FormItem
          label="Objectives"
          extra="Place each new objective on a new line"
        >
          {getFieldDecorator("objectives", {
            initialValue: idx(lesson, _ => _.objectives)
          })(
            <TextArea
              style={{ height: 190 }}
              placeholder="1. Objective one..."
            />
          )}
        </FormItem>
        <FormItem label="Materials" extra="Place each new item on a new line">
          {getFieldDecorator("materials", {
            initialValue: idx(lesson, _ => _.materials)
          })(
            <TextArea style={{ height: 190 }} placeholder="Lesson materials" />
          )}
        </FormItem>
        <FormItem label="Printabes" extra="Place each new item on a new line">
          {getFieldDecorator("printables", {
            initialValue: idx(lesson, _ => _.printables)
          })(
            <TextArea style={{ height: 190 }} placeholder="Lesson printables" />
          )}
        </FormItem>
        <FormItem
          label="Key Terms"
          extra="Enter a key term then press the 'enter' key"
        >
          {getFieldDecorator("key_terms", {
            initialValue: idx(lesson, _ => _.key_terms)
          })(
            <Select
              mode="tags"
              placeholder="Enter the key terms for this lesson"
              style={{ width: "100%" }}
            />
          )}
        </FormItem>
        <FormItem
          label="Suggested Resources"
          extra="Place each new item on a new line"
        >
          {getFieldDecorator("suggested_resources", {
            initialValue: idx(lesson, _ => _.suggested_resources)
          })(
            <TextArea style={{ height: 190 }} placeholder="Lesson resources" />
          )}
        </FormItem>
        <FormItem
          label="Introduction"
          extra="Place each new item on a new line"
        >
          {getFieldDecorator("introduction", {
            initialValue: idx(lesson, _ => _.introduction)
          })(<TextArea style={{ height: 190 }} placeholder="Introduction" />)}
        </FormItem>
        <FormItem label="Development" extra="Place each new item on a new line">
          {getFieldDecorator("development", {
            initialValue: idx(lesson, _ => _.development)
          })(<TextArea style={{ height: 190 }} placeholder="Development" />)}
        </FormItem>
        <FormItem label="Practice" extra="Place each new item on a new line">
          {getFieldDecorator("practice", {
            initialValue: idx(lesson, _ => _.practice)
          })(<TextArea style={{ height: 190 }} placeholder="Practice" />)}
        </FormItem>
        <FormItem
          label="Checks For Understanding"
          extra="Place each new item on a new line"
        >
          {getFieldDecorator("checks_for_understanding", {
            initialValue: idx(lesson, _ => _.checks_for_understanding)
          })(
            <TextArea
              style={{ height: 190 }}
              placeholder="Checks for understanding"
            />
          )}
        </FormItem>
        <FormItem label="Closure" extra="Place each new item on a new line">
          {getFieldDecorator("closure", {
            initialValue: idx(lesson, _ => _.closure)
          })(<TextArea style={{ height: 190 }} placeholder="Closure" />)}
        </FormItem>
        <FormItem label="Homework" extra="Place each new item on a new line">
          {getFieldDecorator("homework", {
            initialValue: idx(lesson, _ => _.homework)
          })(
            <TextArea style={{ height: 190 }} placeholder="Lesson homework" />
          )}
        </FormItem>
        <FormItem label="Evaluation" extra="Place each new item on a new line">
          {getFieldDecorator("evaluation", {
            initialValue: idx(lesson, _ => _.evaluation)
          })(<TextArea style={{ height: 190 }} placeholder="Evaluation" />)}
        </FormItem>
        <FormItem
          label="Total Activity Time"
          extra="This is the total time in minutes"
        >
          {getFieldDecorator("total_activity_time", {
            initialValue: idx(lesson, _ => _.total_activity_time)
          })(<InputNumber placeholder="Total Activity Time" />)}
        </FormItem>

        <FormItem>
          <Button
            type="primary"
            htmlType="submit"
            disabled={hasErrors(getFieldsError())}
          >
            {lesson ? "Edit Lesson" : "Create Lesson"}
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const WrappedAddLessonForm = Form.create()(AddLessonForm);

export default WrappedAddLessonForm;
