import React from "react";
import { Row, Col, Button, Alert, Icon } from "antd";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table } from "antd";
import { Link } from "react-router-dom";
import TitleBar from "../components/common/TitleBar";
import Avatar from "../components/common/Avatar";
import KeyedList from "../components/common/KeyedList";
import { Tabs } from "antd";
import CardSection, {
  CardSectionTitle
} from "../components/common/CardSection";
import DisplayList from "../components/common/DisplayList";
import Toast from "../components/common/Toast";
import { Video } from "cloudinary-react";

const TabPane = Tabs.TabPane;

const DisplayListWrapper = styled.div`
  padding: 48px 0;
`;

const ErrorText = styled.p`
  color: red;
  font-weight: bold;
  background: #fdf0ef;
  width: 100%;
  text-align: center;
  padding: 10px 12px;
  border-radius: 2px;
  margin-top: 20px;
`;

const ViewLessonScreen = ({
  data,
  history,
  onRowSelect,
  lessonUnlocked,
  takeCompetencyTest,
  competencyTestLoading,
  errorMsg
}) => {
  console.log("[Lesson]:", data);
  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title={"Lesson Details"}
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
            buttons={[
              lessonUnlocked ? null : (
                <Button
                  disabled={errorMsg || competencyTestLoading}
                  size={matches ? "small" : "default"}
                  type="primary"
                  icon="file-add"
                  style={{ marginRight: 8, marginBottom: ".5em" }}
                  onClick={() => takeCompetencyTest()}
                  loading={competencyTestLoading}
                >
                  Take Competency Test
                </Button>
              )
            ]}
          />
          <Row gutter={12}>
            <Col xs={24} md={7}>
              <CardSection>
                <Avatar style={{ margin: "0 auto" }} />
                <h2 className="card-section-title">{data.name}</h2>
                <KeyedList
                  data={data}
                  keys={[
                    { key: "classroomLevel", label: "school level" },
                    { key: "subject.name", label: "subject name" },
                    { key: "key_terms", label: "key terms" }
                  ]}
                />
              </CardSection>
            </Col>
            <Col xs={24} md={17}>
              <CardSection>
                {!lessonUnlocked ? (
                  <div
                    style={{
                      marginBottom: 24,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <Alert
                      banner
                      message="Lesson Locked!"
                      description="This lesson is locked. You can unlock the message content by taking a competency test. Click the button below to begin"
                      type="error"
                      showIcon
                    />
                    {errorMsg && (
                      <ErrorText>
                        <Icon
                          style={{ color: "red", marginRight: 10 }}
                          type="exclamation-circle-o"
                        />
                        {errorMsg}
                      </ErrorText>
                    )}

                    <Button
                      disabled={errorMsg || competencyTestLoading}
                      style={{ marginTop: 8 }}
                      size="default"
                      type="primary"
                      icon="file-add"
                      loading={competencyTestLoading}
                      onClick={() => takeCompetencyTest()}
                    >
                      Take Competency Test
                    </Button>
                  </div>
                ) : (
                    <Tabs defaultActiveKey="1">
                      <TabPane tab="Lesson Information" key="1">
                        <KeyedList
                          data={data}
                          keys={[
                            { key: "name", label: "name" },
                            { key: "classroomLevel", label: "school level" },
                            { key: "subject.name", label: "subject name" },

                            {
                              key: "total_activity_time",
                              label: "total activity time",
                              suffix: "minutes"
                            }
                          ]}
                        />
                        {lessonUnlocked && (
                          <DisplayListWrapper>
                            <DisplayList title="Content" data={data.content} />
                            <DisplayList title="Goals" data={data.goals} />
                            <DisplayList
                              title="Objectives"
                              data={data.objectives}
                            />
                            <DisplayList
                              title="Materials"
                              data={data.materials}
                            />
                            <DisplayList
                              title="printables"
                              data={data.printables}
                            />
                            <DisplayList
                              title="suggested resources"
                              data={data.suggested_resources}
                            />
                            <DisplayList
                              title="Introduction"
                              data={data.introduction}
                            />
                            <DisplayList
                              title="development"
                              data={data.development}
                            />
                            <DisplayList title="practice" data={data.practice} />
                            <DisplayList
                              title="checks for understanding"
                              data={data.checks_for_understanding}
                            />
                            <DisplayList title="closure" data={data.closure} />
                            <DisplayList title="homework" data={data.homerwork} />
                            <DisplayList
                              title="evaluation"
                              data={data.evaluation}
                            />
                            {data.publicId && <DisplayList
                              title="lesson video"
                              render={() => (
                                <Video
                                  controls
                                  cloudName={process.env.REACT_APP_CLOUD_NAME}
                                  publicId={data.publicId}
                                />
                              )}
                            />}
                          </DisplayListWrapper>
                        )}
                      </TabPane>
                      <TabPane tab="Lesson Activities" key="2">
                        {!data.lesson_activities.length && (
                          <Alert
                            banner
                            message="No Activites!"
                            description="No activities yet for this lesson"
                            type="info"
                            showIcon
                          />
                        )}
                        {data.lesson_activities &&
                          data.lesson_activities.map(activity => {
                            return (
                              <React.Fragment key={activity._id}>
                                <DisplayListWrapper>
                                  <h3>Activity Name: {activity.name}</h3>
                                  <DisplayList
                                    title="Description"
                                    data={activity.description}
                                  />
                                  <DisplayList
                                    title="Materials"
                                    data={activity.materials}
                                  />
                                  <DisplayList
                                    title="Instructions"
                                    data={activity.instructions}
                                  />
                                  <hr />
                                </DisplayListWrapper>
                              </React.Fragment>
                            );
                          })}
                      </TabPane>
                    </Tabs>
                  )}
              </CardSection>
            </Col>
          </Row>
        </div>
      )}
    </Media>
  );
};

const ViewLessonScreenWithRouter = withRouter(ViewLessonScreen);
export default ViewLessonScreenWithRouter;
