import gql from "graphql-tag";

export default gql`
query schemeOfWork($filter: JSON!) {
  schemeOfWork(filter: $filter) {
    _id
    session
    minAge
    maxAge
    objectives
    classroomLevel
    subject{
      _id
      name
    }
    term1{
      weeks {
        order
        topic
        day1 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day2 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day3 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day4 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day5 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
      }
    }
    term2 {
       weeks {
        order
        topic
        day1 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day2 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day3 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day4 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day5 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
      }
    }
    term3 {
       weeks {
        order
        topic
        day1 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day2 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day3 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day4 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
        day5 {
          order
          lesson {
            name
          }
          objectives
          evaluation
          keywords
          assignment
          resources
          teacher_initiated_activities
          teacher_led_activity
        }
      }
    }
  }
}
`;
