import gql from "graphql-tag";

export default gql`
  mutation editTestQuestion(
    $_id: String!
    $testQuestion: TestQuestionInputEdit!
  ) {
    editTestQuestion(_id: $_id, testQuestion: $testQuestion) {
      _id
    }
  }
`;
