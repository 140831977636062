import gql from "graphql-tag";
import { DailyReportQuery } from "../fragments/daily_report";
import { WeeklyReportQuery } from "../fragments/weekly_report";

export default gql`
  query classrooms($filter: JSON!) {
    classrooms(filter: $filter) {
      _id
      created_at
      updated_at
      name
      teacher {
        _id
        firstName
        lastName
        lessonIds
        lessons {
          _id
          name
          subject {
            _id
            name
          }
          classroomLevel
          isActive
          created_at
        }
      }
      classroomLevel
      subject {
        _id
        name
        code
      }
      isActive
      ${DailyReportQuery}
      ${WeeklyReportQuery}
    }
  }
`;
