import React from "react";
import moment from "moment";
import { Table, Popover, DatePicker } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import TitleBar from "../components/common/TitleBar";
import { Collapse, Alert } from "antd";
import { List, Button } from "antd";

const Panel = Collapse.Panel;
const { RangePicker } = DatePicker;

const ReportsScreen = ({ classrooms, history, generateWeeklyReport }) => {
  const createReport = (student, classroom) =>
    history.push({
      pathname: `/main/create-daily-report/${student._id}`,
      state: { student, classroom }
    });

  const noClassroom = classrooms.length < 1;

  const onSelectDateRange = (student, classroom) => (date, dateString) => {
    generateWeeklyReport(date, student, classroom);
  };

  return (
    <div>
      <TitleBar
        title="Reports"
        explainerText={`This is the reports page. Select a classroom from your list to view the students in that class, then select a student to create a report for them.`}
      />
      {noClassroom && (
        <div style={{ paddingTop: 32 }}>
          <Alert
            message="No Classrooms Yet"
            description="You do nothave any classrooms yet. You'll need a classroom and some student to create reports. First create a classroom, then head over to the lessons tab to unlock some lessons"
            type="warning"
            showIcon
          />
        </div>
      )}
      <Collapse accordion>
        {classrooms.map((classroom, i) => (
          <Panel header={classroom.name} key={i}>
            <List
              itemLayout="horizontal"
              dataSource={classroom.students}
              renderItem={student => (
                <List.Item
                  actions={[
                    <a onClick={() => createReport(student, classroom)}>
                      create daily report
                    </a>,
                    <Popover
                      placement="leftTop"
                      content={
                        <RangePicker
                          onChange={onSelectDateRange(student, classroom)}
                          renderExtraFooter={() =>
                            "select the start and end date for the week"
                          }
                        />
                      }
                      title="Select a Date Range"
                      trigger="click"
                    >
                      <a>generate weekly report</a>
                    </Popover>
                  ]}
                >
                  <List.Item.Meta
                    title={`${student.firstName} ${student.lastName}`}
                  />
                </List.Item>
              )}
            />
          </Panel>
        ))}
      </Collapse>
    </div>
  );
};

const ReportsScreenWithRouter = withRouter(ReportsScreen);
export default ReportsScreenWithRouter;
