import React from 'react';
import HomeScreen from '../../../screens/HomeScreen';
import { UserConsumer } from '../../../App';
import FETCH_CLASSROOM from '../../../graphql/queries/FETCH_CLASSROOM';
import FETCH_STUDENTS from '../../../graphql/queries/FETCH_STUDENTS';
import { Query } from 'react-apollo';

export class HomeContainer extends React.Component {
  state = {
    showSchoolSelectionModal: false,
  };

  _openSelectSchoolModal = () =>
    this.setState({ showSchoolSelectionModal: true });

  _closeSchoolSelectModal = () =>
    this.setState({ showSchoolSelectionModal: false });

  render() {
    const { showSchoolSelectionModal } = this.state;
    const getStudentsDataFromClassrooms = classrooms => {
      const allStudents = classrooms.reduce((totalStudents, classroom) => {
        return totalStudents.concat(classroom.students);
      }, []);
      const numberOfStudents = allStudents.length;
      return { allStudents, numberOfStudents };
    };

    return (
      <UserConsumer>
        {user => {
          const { classrooms, lessons } = user;

          const homeScreenProps = {
            showSchoolSelectionModal,
            onOpenSelectSchoolModal: this._openSelectSchoolModal,
            onCloseSelectSchoolModal: this._closeSchoolSelectModal,
            user,
            numberOfClassrooms: classrooms ? classrooms.length : 0,
            numberOfLessonsPassed: lessons ? lessons.length : 0,
            // here we have a function returns an object we spread into homeScreenProps
            ...getStudentsDataFromClassrooms(classrooms),
          };

          return <HomeScreen {...homeScreenProps} />;
        }}
      </UserConsumer>
    );
  }
}
