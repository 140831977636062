import React from 'react'
import {
  Form, Tabs, Select, Row, Col,
  InputNumber, Input, Divider, Button, notification
} from "antd";
import CardSection, {
  CardSectionTitle
} from '../../components/common/CardSection';

import { tenYears } from '../../helpers/dateRanges';
import { INITIAL_WEEK, TERM_ONE, TERM_TWO, TERM_THREE, refineData } from '../../helpers/schemeOfWorkData';
const FormItem = Form.Item;
const { Option } = Select;
const { TabPane } = Tabs
const { TextArea } = Input;

const termDays = [
  { order: 1 },
  { order: 2 },
  { order: 3 },
  { order: 4 },
  { order: 5 },
];

const hasErrors = (fieldsError) => {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class AddSchemeOfWorkForm extends React.Component {

  state = {
    activeTabKey: '1',
    selectedSubject: null,
    subjectId: '',
    sessionFrom: '',
    sessionTo: '',
    minAge: 0,
    maxAge: 0,
    objectives: '',
    classroomLevel: '',
    term1: {
      weeks: [{...TERM_ONE}],
    },
    term2: {
      weeks: [{...TERM_TWO}],
    },
    term3: {
      weeks: [{...TERM_THREE}],
    },
  }

  handleNextTabChange = () => {
    const { activeTabKey } = this.state;
    const nextValue = parseInt(activeTabKey, 10) + 1
    this.setState({ activeTabKey: `${nextValue}` })
  }

  handlePreviousTabChange = () => {
    const { activeTabKey } = this.state;
    const nextValue = parseInt(activeTabKey, 10) - 1
    this.setState({ activeTabKey: `${nextValue}` });
  }

  handleOverviewDataChange = (name, value) => {
    const { subjects } = this.props;

    if(name === 'subjectId') {
      const foundSubject = subjects.find(subject => subject._id === value)
      this.setState({ [name]: value, selectedSubject: foundSubject });
    }

    this.setState({ [name]: value });
  };

  handleTermWeekDataChange = (term, week, key, value) => {
    const { weeks } = this.state[term];
    const foundWeek = weeks.find(weekItem => weekItem.order === week)
    const updatedWeek = {
      ...foundWeek, [key]: value
    }

    this.setState({
      [term]: {
        weeks: weeks.map(week => {
          if (week.order === updatedWeek.order) {
            return updatedWeek;
          }
          return week;
        })
      }
    });
  }

  handleTermWeekDayDataChange = (term, week, day, key, value) => {
    const { weeks } = this.state[term];
    const exactDay = `day${day}`;
    const foundWeek = weeks.find(weekItem => weekItem.order === week)
    let updatedDay = {};

    if (key === 'keywords') {
      updatedDay = {
        ...foundWeek[exactDay], [key]: [...value]
      }
    }

    else if (key === 'warmUp' || key === 'subTopic' || key === 'activity') {
      const teacher_led_activity = {
           ...foundWeek[exactDay]['teacher_led_activity'], [key]: value
      }

      updatedDay = {
        ...foundWeek[exactDay], teacher_led_activity
      }
    }
    else {
      updatedDay = {
      ...foundWeek[exactDay], [key]: value
      }
    }
    
    foundWeek[exactDay] = updatedDay;

    this.setState(prevState => ({
      [term]: {
        ...this.state[term],
        weeks: prevState[term].weeks.map(week => {
        if (week.order === foundWeek.order) {
          return foundWeek;
        }
        return week;
      })
    }
    }))
  };

  addWeek = e => {
    e.preventDefault();
    const { name } = e.target;
    const { weeks } = this.state[name];

    if(weeks.length + 1 <= 13) {
      const testWeek = JSON.parse(JSON.stringify(INITIAL_WEEK))
      const week = new Object({
        ...testWeek,
        order: weeks.length + 1,
      });

      weeks.push(week);

      return this.setState({ [name]: { weeks } });
    };

    notification.error({
      placement: 'bottomRight',
      message: 'Limit Error',
      description: 'You have reached the limit of weeks that can be added',
      duration: 10
    });
  };
    

  deleteWeek = (e, week) => {
    e.preventDefault();
    const { order } = week;
    const { name } = e.target;
    const { weeks } = this.state[name];
    const newWeeks = weeks.filter(week => week.order !== order)

    newWeeks.map((week, index) => {
      week.order = index+1;
    })

    this.setState({ [name]: { weeks: newWeeks } });
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return notification.error({
          placement: 'bottomRight',
          message: 'Submission Error',
          description: 'Please check through the tabs to ensure all required fields are filled',
          duration: 10
        });
      }
      const updatedValues = refineData(this.state);
      return this.props.onSubmit(updatedValues);
    });

  }

  render() {
    const { subjects } = this.props;
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { term1, term2, term3, selectedSubject, activeTabKey } = this.state;

    return (
      <CardSection>
        <CardSectionTitle>Scheme of work form</CardSectionTitle>
        <Form onSubmit={e => this.handleSubmit(e)}>
          <Tabs type="card" activeKey={activeTabKey}>
            <TabPane tab="Overview" key="1">

              <Row gutter={8}>
                <Col xs={8} md={4}>
                  <FormItem label="Session From">
                  {getFieldDecorator("sessionFrom", {
                    rules: [{ required: true, message: 'Please select the start of the session' }],
                  })(<Select onChange={e => this.handleOverviewDataChange('sessionFrom', e)} >
                      {tenYears.map(year => (
                        <Option key={year} value={year}>{year}</Option>
                      ))}
                    </Select>
                  )}
                  </FormItem>
                </Col>

                <Col xs={8} md={4}>
                  <FormItem label="Session To">
                  {getFieldDecorator("sessionTo", {
                    rules: [{ required: true, message: 'Please select the end of the session' }],
                  })(<Select onChange={e => this.handleOverviewDataChange('sessionTo', e)}>
                      {tenYears  .map(year => (
                        <Option key={year} value={year}>{year}</Option>
                      ))}
                    </Select>
                  )}
                  </FormItem>
                </Col>
              </Row>

              <Row gutter={8}>
                <Col xs={8} md={4}>
                  <FormItem label="Subject">
                  {getFieldDecorator("subject", {
                    rules: [{ required: true, message: 'Please select the end of the session' }],
                  })(<Select onChange={e => this.handleOverviewDataChange('subjectId', e)}>
                      {subjects && subjects.map(subject => (
                        <Option key={subject} value={subject._id}>{subject.name}</Option>
                      ))}
                    </Select>
                  )}
                  </FormItem>
                </Col>

                <Col xs={8} md={2}>
                  <FormItem label="Min age">
                    {getFieldDecorator("minAge", {
                    rules: [{ required: true, message: 'Please enter a minimum age' }],
                  })(<InputNumber
                      initialValue={0}
                      onChange={e => this.handleOverviewDataChange('minAge', e)}
                      min={0}
                      max={10}
                    />)}
                  </FormItem>
                </Col>

                <Col xs={8} md={2}>
                  <FormItem label="Max age">
                    {getFieldDecorator("maxAge", {
                    rules: [{ required: true, message: 'Please enter a maximum age'}],
                  })(<InputNumber
                      initialValue={0}
                      onChange={e => this.handleOverviewDataChange('maxAge', e)}
                      min={0}
                      max={10}
                    />)}
                  </FormItem>
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={8}>
                  <FormItem label="Classroom Level">
                  {getFieldDecorator("classroomLevel", {
                    rules: [{ required: true, message: 'Please select a classroom level'}],
                  })(<Select onChange={e => this.handleOverviewDataChange('classroomLevel', e)} >
                      <Option value={"PRE_SCHOOL"}>PRE-SCHOOL</Option>
                      <Option value={"PRIMARY_1"}>PRIMARY 1</Option>
                      <Option value={"PRIMARY_2"}>PRIMARY 2</Option>
                      <Option value={"PRIMARY_3"}>PRIMARY 3</Option>
                      <Option value={"PRIMARY_4"}>PRIMARY 4</Option>
                      <Option value={"PRIMARY_5"}>PRIMARY 5</Option>
                    </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormItem
                    label="Objectives"
                    extra="Place each new objective on a new line, press enter to place on new line"
                  >
                  {getFieldDecorator("objectives", {
                    rules: [{ required: true, message: 'Please add the objectives'}],
                  })(<TextArea
                      onChange={e => this.handleOverviewDataChange('objectives', e.target.value)}
                      style={{ height: 190 }}
                      placeholder="1. Goal number one for this subject is..."
                    />)}
                  </FormItem>

                </Col>
              </Row>

            </TabPane>
            
            <TabPane tab="1st Term" key="2">
              <Button name='term1' type='primary' size='small' onClick={this.addWeek}>Add New Week</Button>
              <Row style={{ marginTop: 15 }}>
                {term1.weeks && term1.weeks.map(week => (
                  <div key={week.order}>
                    <Row>
                      <Divider orientation='left' />
                      <Row>
                        <Col xs={24} md={12}>
                          <h2> Week {week.order} </h2>
                        </Col>

                        <Col xs={24} md={12}>
                          {term1.weeks.length > 1 && 
                            <Button name='term1' type='danger' size='small' onClick={(e) => this.deleteWeek(e, week )}>
                            Delete Week {week.order}
                            </Button>
                          }
                        </Col>
                      </Row>

                      <Row gutter={8}>
                        <Col xs={24} md={12}>
                          <FormItem label="Topic">
                          {getFieldDecorator(`term1Topic${week.order}`, {
                            rules: [{ required: true, message: 'Please enter a topic' }],
                          })(<Input onChange={e => this.handleTermWeekDataChange('term1', week.order, 'topic', e.target.value)} />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </Row>
                    {termDays.map(day => (
                      <div key={day.order} style={{ borderWidth: 1, borderStyle: 'solid', padding: 5, borderColor: '#ccc', marginTop: 8 }}>
                        <h4> Day {day.order} </h4>
                        <Row gutter={8}>
                          <Col xs={24} md={8}>
                            <FormItem label="Lesson">
                            {getFieldDecorator(`term1LessonWeek${week.order}Day${day.order}`)(
                              <Select 
                                onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'lessonId', e)}
                                >
                                {selectedSubject && selectedSubject.lessons.length > 0  &&
                                  selectedSubject.lessons.map(lesson => (
                                    <Option key={lesson._id} value={lesson._id}>
                                      {lesson.name}
                                    </Option>
                                  ))}
                              </Select>)}
                            </FormItem>
                          </Col>

                          <Col xs={24} md={16}>
                            <FormItem label="Keywords" extra="Enter a keyword then press the 'enter' key">
                            {getFieldDecorator(`term1KeywordsWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add keywords' }],
                            })(<Select
                                mode="tags"
                                style={{ width: "100%" }}
                                onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'keywords', e)}
                              />)}
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={8}>
                          <Col xs={24} md={8}>
                            <FormItem label="Evaluation">
                              {getFieldDecorator(`term1EvaluationWeek${week.order}Day${day.order}`, {
                                rules: [{ required: true, message: 'Please add an evaluation' }],
                              })(<TextArea
                                  style={{ height: 'auto' }}
                                  onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'evaluation', e.target.value)}
                                />)}
                            </FormItem>
                          </Col>

                          <Col xs={24} md={8}>
                            <FormItem label="Resources" extra='Place each new resource on a new line'>
                            {getFieldDecorator(`term1ResourcesWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add resources' }],
                            })(<TextArea
                                style={{ height: 'auto' }}
                                placeholder='1. Resource one...'
                                onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'resources', e.target.value)}
                              />)}
                            </FormItem>
                          </Col>

                          <Col xs={24} md={8}>
                            <FormItem label="Assignment">
                            {getFieldDecorator(`term1AssignmentWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add an assignment' }],
                            })(<TextArea
                                style={{ height: 'auto' }}
                                onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'assignment', e.target.value)}
                              />)}
                            </FormItem>
                          </Col>
                        </Row>

                        <Row gutter={8}>
                        <Col xs={24} md={8}>
                          <FormItem label="Objectives" extra='Place each new objective on a new line'>
                          {getFieldDecorator(`term1ObjectivesWeek${week.order}Day${day.order}`, {
                            rules: [{ required: true, message: 'Please add objectives' }],
                          })(<TextArea
                              style={{ height: 'auto' }}
                              placeholder='1. Objective one...'
                              onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'objectives', e.target.value)}
                            />)}
                          </FormItem>
                        </Col>

                        <Col xs={24} md={8}>
                          <FormItem label="Teacher Initiated Activities" extra='Place each new activity on a new line'>
                          {getFieldDecorator(`term1TIAWeek${week.order}Day${day.order}`, {
                            rules: [{ required: true, message: 'Please enter a the initiated activities' }],
                          })(<TextArea
                              style={{ height: 'auto' }}
                              placeholder='LA: ... '
                              onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'teacher_initiated_activities', e.target.value)}
                            />)}
                          </FormItem>
                        </Col>
                        </Row>

                        <Row gutter={8}>
                          <Col xs={24} md={8}>
                            <FormItem label="Teacher Led Activity (Warm Up)" >
                            {getFieldDecorator(`term1WarmUpWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add a warmup activity' }],
                            })(<TextArea
                                  placeholder='Ask pupils to ... '
                                  style={{ height: 'auto' }}
                                  onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'warmUp', e.target.value)}
                              />)}
                            </FormItem>
                          </Col>

                          <Col xs={24} md={8}>
                            <FormItem label="Teacher Led Activity (Sub Topic)">
                            {getFieldDecorator(`term1SubTopicWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add a subtopic for the activity' }],
                            })(<TextArea
                                placeholder='subtopic is... '
                                style={{ height: 'auto' }}
                                onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'subTopic', e.target.value)}
                              />)}
                            </FormItem>
                          </Col>

                          <Col xs={24} md={8}>
                            <FormItem label="Teacher Led Activity (Activity)">
                            {getFieldDecorator(`term1ActivityWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add n activity for the activity' }],
                            })(<TextArea
                                placeholder='The activity is...'
                                style={{ height: 'auto' }}
                                onChange={e=> this.handleTermWeekDayDataChange('term1', week.order, day.order, 'activity', e.target.value)}
                              />)}
                            </FormItem>
                          </Col>
                        </Row>
                      </div>
                    ))}
                  </div>
                ))}
              </Row>

              <Row style={{ marginTop: 15 }}>
                <Button name='term1' type='primary' size='small' onClick={this.addWeek}>Add New Week</Button>
              </Row>

            </TabPane>

            <TabPane tab="2nd Term" key="3">
              <Button name='term2' type='primary' size='small' onClick={this.addWeek}>Add New Week</Button>
              <Row style={{ marginTop: 15 }}>
              {term2.weeks.map(week => (
                <div key={week.order}>
                  <Row>
                    <Divider orientation='left' />
                    <Row>
                      <Col xs={24} md={12}>
                        <h2> Week {week.order} </h2>
                      </Col>

                      <Col xs={24} md={12}>
                      {term2.weeks.length > 1 && 
                        <Button name='term2' type='danger' size='small' onClick={(e) => this.deleteWeek(e, week )}>
                        Delete Week {week.order}
                        </Button>
                      }
                      </Col>
                    </Row>

                    <Row gutter={8}>
                    <Col xs={24} md={12}>
                      <FormItem label="Topic">
                      {getFieldDecorator(`term2Topic${week.order}`, {
                        rules: [{ required: true, message: 'Please enter a topic' }],
                      })(<Input onChange={e => this.handleTermWeekDataChange('term2', week.order, 'topic', e.target.value)}/>)}
                      </FormItem>
                    </Col>
                    </Row>
                  </Row>
                  {termDays.map(day => (
                    <div key={day.order} style={{ borderWidth: 1, borderStyle: 'solid', padding: 5, borderColor: '#ccc', marginTop: 8 }}>
                      <h4> Day {day.order} </h4>
                      <Row gutter={8}>
                        <Col xs={24} md={8}>
                          <FormItem label="Lesson">
                          {getFieldDecorator(`term2LessonWeek${week.order}Day${day.order}`)(
                            <Select
                            onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'lessonId', e)}
                            >
                            {selectedSubject && selectedSubject.lessons.length > 0 &&
                              selectedSubject.lessons.map(lesson => (
                                <Option key={lesson._id} value={lesson._id}>
                                  {lesson.name}
                                </Option>
                              ))}
                            </Select>)}
                          </FormItem>
                        </Col>

                        <Col xs={24} md={16}>
                        <FormItem label="Keywords" extra="Enter a keyword then press the 'enter' key">
                          {getFieldDecorator(`term2KeywordsWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add keywords' }],
                            })(<Select
                            mode="tags"
                            style={{ width: "100%" }}
                            onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'keywords', e)}
                          />)}
                          </FormItem>
                          
                        </Col>
                      </Row>

                      <Row gutter={8}>
                        <Col xs={24} md={8}>
                          <FormItem label="Evaluation">
                            {getFieldDecorator(`term2EvaluationWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add an evaluation' }],
                            })(<TextArea
                                style={{ height: 'auto' }}
                                onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'evaluation', e.target.value)}
                              />)}
                          </FormItem>
                        </Col>

                        <Col xs={24} md={8}>
                          <FormItem label="Resources" 
                          extra='Place each new resource on a new line'
                          >
                          {getFieldDecorator(`term2ResourcesWeek${week.order}Day${day.order}`, {
                            rules: [{ required: true, message: 'Please add resources' }],
                          })(<TextArea
                              style={{ height: 'auto' }}
                              placeholder='1. Resource one...'
                              onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'resources', e.target.value)}
                            />)}
                          </FormItem>
                        </Col>

                        <Col xs={24} md={8}>
                          <FormItem label="Assignment">
                          {getFieldDecorator(`term2AssignmentWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add an assignment' }],
                            })(<TextArea
                            onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'assignment', e.target.value)}
                            style={{ height: 'auto' }}
                            />)}
                          </FormItem>
                        </Col>
                      </Row>

                      <Row gutter={8}>
                        <Col xs={24} md={8}>
                          <FormItem
                            label="Objectives"
                            extra='Place each new objective on a new line'
                            >
                            {getFieldDecorator(`term2ObjectivesWeek${week.order}Day${day.order}`, {
                              rules: [{ required: true, message: 'Please add objectives' }],
                            })(<TextArea
                              onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'objectives', e.target.value)}
                              style={{ height: 'auto' }}
                              placeholder='1. Objective one...'
                            />)}
                          </FormItem>
                        </Col>

                        <Col xs={24} md={8}>
                          <FormItem
                            label="Teacher Initiated Activities"
                            extra='Place each new activity on a new line'
                          >
                          {getFieldDecorator(`term2TIAWeek${week.order}Day${day.order}`, {
                            rules: [{ required: true, message: 'Please enter a the initiated activities' }],
                          })(<TextArea
                              style={{ height: 'auto' }}
                              placeholder='LA: ... '
                              onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'teacher_initiated_activities', e.target.value)}
                            />)}
                          </FormItem>
                        </Col>
                      </Row>

                      <Row gutter={8}>
                        <Col xs={24} md={8}>
                          <FormItem label="Teacher Led Activity (Warm Up)">
                          {getFieldDecorator(`term2WarmUpWeek${week.order}Day${day.order}`, {
                            rules: [{ required: true, message: 'Please add a warmup activity' }],
                          })(<TextArea
                              placeholder='Ask pupils to ... '
                              style={{ height: 'auto' }}
                              onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'warmUp', e.target.value)}
                            />)}
                          </FormItem>
                        </Col>
                      
                        <Col xs={24} md={8}>
                          <FormItem label="Teacher Led Activity (Sub Topic)">
                          {getFieldDecorator(`term2SubTopicWeek${week.order}Day${day.order}`, {
                            rules: [{ required: true, message: 'Please add a subtopic for the activity' }],
                          })(<TextArea
                                placeholder='subtopic is... '
                                style={{ height: 'auto' }}
                                onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'subTopic', e.target.value)}
                            />)}
                          </FormItem>
                        </Col>

                        <Col xs={24} md={8}>
                          <FormItem label="Teacher Led Activity (Activity)">
                          {getFieldDecorator(`term2ActivityWeek${week.order}Day${day.order}`, {
                            rules: [{ required: true, message: 'Please add n activity for the activity' }],
                          })(<TextArea
                                placeholder='The activity is...'
                                style={{ height: 'auto' }}
                                onChange={e=> this.handleTermWeekDayDataChange('term2', week.order, day.order, 'activity', e.target.value)}
                            />)}
                          </FormItem>
                        </Col>
                      </Row>
                    </div>
                  ))}
                </div>
              ))}
              </Row>

              <Row style={{ marginTop: 15 }}>
                <Button name='term2' type='primary' size='small' onClick={this.addWeek}>Add New Week</Button>
              </Row>            

            </TabPane>
            
            <TabPane tab="3rd Term" key="4">
              <Button name='term3' type='primary' size='small' onClick={this.addWeek}>Add New Week</Button>
                <Row style={{ marginTop: 15 }}>
                  {term3.weeks.map(week => (
                    <div key={week.order}>
                    <Row>
                    <Divider orientation='left' />
                      <Row>
                        <Col xs={24} md={12}>
                          <h2> Week {week.order} </h2>
                        </Col>

                        <Col xs={24} md={12}>
                          {term3.weeks.length > 1 && 
                            <Button name='term3' type='danger' size='small' onClick={(e) => this.deleteWeek(e, week )}>
                            Delete Week {week.order}
                            </Button>
                          }
                        </Col>
                      </Row>

                      <Row gutter={8}>
                        <Col xs={24} md={12}>
                          <FormItem label="Topic">
                          {getFieldDecorator(`term3Topic${week.order}`, {
                            rules: [{ required: true, message: 'Please enter a topic' }],
                          })(<Input onChange={e => this.handleTermWeekDataChange('term3', week.order, 'topic', e.target.value)}/>)}
                          </FormItem>
                        </Col>
                        </Row>
                      </Row>
                      {termDays.map(day => (
                        <div key={day.order} style={{ borderWidth: 1, borderStyle: 'solid', padding: 5, borderColor: '#ccc', marginTop: 8 }}>
                          <h4> Day {day.order} </h4>
                          <Row gutter={8}>
                            <Col xs={24} md={8}>
                              <FormItem label="Lesson">
                              {getFieldDecorator(`term3LessonWeek${week.order}Day${day.order}`)(
                                <Select 
                                  onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'lessonId', e)}
                                >
                                {selectedSubject && selectedSubject.lessons.length > 0 &&
                                  selectedSubject.lessons.map(lesson => (
                                    <Option key={lesson._id} value={lesson._id}>
                                      {lesson.name}
                                    </Option>
                                  ))}
                                </Select>)}
                              </FormItem>
                            </Col>

                            <Col xs={24} md={16}>
                              <FormItem label="Keywords" extra="Enter a keyword then press the 'enter' key">
                              {getFieldDecorator(`term3KeywordsWeek${week.order}Day${day.order}`, {
                                rules: [{ required: true, message: 'Please add keywords' }],
                              })(<Select
                                  mode="tags"
                                  onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'keywords', e)}
                                  style={{ width: "100%" }}
                                />)}
                              </FormItem>
                            </Col>
                          </Row>

                          <Row gutter={8}>
                            <Col xs={24} md={8}>
                              <FormItem label="Evaluation">
                                {getFieldDecorator(`term3EvaluationWeek${week.order}Day${day.order}`, {
                                  rules: [{ required: true, message: 'Please add an evaluation' }],
                                })(<TextArea
                                    style={{ height: 'auto' }}
                                    onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'evaluation', e.target.value)}
                                  />)}
                              </FormItem>
                            </Col>

                            <Col xs={24} md={8}>
                              <FormItem label="Resources" extra='Place each new resource on a new line'>
                              {getFieldDecorator(`term3ResourcesWeek${week.order}Day${day.order}`, {
                                rules: [{ required: true, message: 'Please add resources' }],
                              })(<TextArea
                                  style={{ height: 'auto' }}
                                  placeholder='1. Resource one...'
                                  onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'resources', e.target.value)}
                                />)}
                              </FormItem>
                            </Col>

                            <Col xs={24} md={8}>
                              <FormItem label="Assignment">
                              {getFieldDecorator(`term3AssignmentWeek${week.order}Day${day.order}`, {
                                rules: [{ required: true, message: 'Please add an assignment' }],
                              })(<TextArea
                                  style={{ height: 'auto' }}
                                  onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'assignment', e.target.value)}
                                />)}
                              </FormItem>
                            </Col>
                          </Row>

                          <Row gutter={8}>
                            <Col xs={24} md={8}>
                              <FormItem label="Objectives" extra='Place each new objective on a new line'>
                              {getFieldDecorator(`term3ObjectivesWeek${week.order}Day${day.order}`, {
                                rules: [{ required: true, message: 'Please add objectives' }],
                              })(<TextArea
                                  style={{ height: 'auto' }}
                                  placeholder='1. Objective one...'
                                  onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'objectives', e.target.value)}
                                />)}
                              </FormItem>
                            </Col>

                            <Col xs={24} md={8}>
                              <FormItem label="Teacher Initiated Activities" extra='Place each new activity on a new line'>
                              {getFieldDecorator(`term3TIAWeek${week.order}Day${day.order}`, {
                                rules: [{ required: true, message: 'Please enter a the initiated activities' }],
                              })(<TextArea
                                  style={{ height: 'auto' }}
                                  placeholder='LA: ... '
                                  onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'teacher_initiated_activities', e.target.value)}
                                />)}
                              </FormItem>
                            </Col>
                          </Row>

                          <Row gutter={8}>
                            <Col xs={24} md={8}>
                              <FormItem label="Teacher Led Activity (Warm Up)">
                                {getFieldDecorator(`term3WarmUpWeek${week.order}Day${day.order}`, {
                                rules: [{ required: true, message: 'Please add a warmup activity' }],
                              })(<TextArea
                                    placeholder='Ask pupils to ... '
                                    style={{ height: 'auto' }}
                                    onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'warmUp', e.target.value)}
                                />)}
                              </FormItem>
                            </Col>
                            
                            <Col xs={24} md={8}>
                              <FormItem label="Teacher Led Activity (Sub Topic)" >
                              {getFieldDecorator(`term3SubTopicWeek${week.order}Day${day.order}`, {
                                rules: [{ required: true, message: 'Please add a subtopic for the activity' }],
                              })(<TextArea
                                    placeholder='subtopic is... '
                                    style={{ height: 'auto' }}
                                    onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'subTopic', e.target.value)}
                                />)}
                              </FormItem>
                            </Col>

                            <Col xs={24} md={8}>
                              <FormItem label="Teacher Led Activity (Activity)" >
                                {getFieldDecorator(`term3ActivityWeek${week.order}Day${day.order}`, {
                                  rules: [{ required: true, message: 'Please add n activity for the activity' }],
                                })(<TextArea
                                    placeholder='The activity is...'
                                    style={{ height: 'auto' }}
                                    onChange={e=> this.handleTermWeekDayDataChange('term3', week.order, day.order, 'activity', e.target.value)}
                                />)}
                              </FormItem>
                            </Col>
                          </Row>
                        </div>
                      ))}
                    </div>
                  ))}
                </Row>

                <Row style={{ marginTop: 15 }}>
                 <Button name='term3' type='primary' size='small' onClick={this.addWeek}>Add New Week</Button>
                </Row>

            </TabPane>
          </Tabs>

          <hr />
          <Row style={{marginTop: 30, textAlign: 'center'}}>
            <Col xs={24} md={8}>
              { activeTabKey !== '1' && <Button type='primary' size='default' onClick={this.handlePreviousTabChange}>Previous</Button>}
            </Col>
            <Col xs={24} md={8}>
            { activeTabKey === '4' &&
              <Button type='primary' htmlType="submit" size='large' disabled={hasErrors(getFieldsError())}>
              Save Scheme of Work
              </Button>
            }
            </Col>
            <Col xs={24} md={8}>
            { activeTabKey !== '4' && <Button type='primary' size='default' onClick={this.handleNextTabChange}>Next</Button> }
            </Col>
          </Row>
          </Form>

      </CardSection>
    )
  }
}

const WrappedForm = Form.create()(AddSchemeOfWorkForm);

export default WrappedForm;
