import React from "react";
import { Mutation, Query } from "react-apollo";
import EDIT_USER from "../../../graphql/mutations/EDIT_USER";
import FETCH_USER from "../../../graphql/queries/FETCH_USER";
import AddOrEditUserForm from "../../../screens/forms/AddOrEditUserForm";
import CURRENT_USER from '../../../graphql/queries/CURRENT_USER';
import ALL_SCHOOLS from '../../../graphql/queries/ALL_SCHOOLS';

export class EditUserContainer extends React.Component {
  _handleEditUser = async (values, mutation) => {
    console.log(values);

    const {
      __typename,
      created_at,
      updated_at,
      verified,
      _id,
      ...allowedFields
    } = values;
    try {
      const { data } = await mutation({
        variables: { _id: this.props.match.params.id, user: allowedFields }
      });
      // retrieve token, persist it and route back to home

      this.props.history.push(`/main/admin/users`);
    } catch (e) {
      console.log("Error editing student: ", e);
    }
  };

  render() {
    return (
      <Query
        query={FETCH_USER}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { users } = data;
          const user = users[0];
          return (
            <Query query={CURRENT_USER}>
              {({ loading, error, data }) => {
                if (loading) return 'loading...';
                if (error) console.log('Got an error:', error);
                const { currentUser } = data;
                return (
                  <Mutation mutation={EDIT_USER}>
                    {(mutation, { loading, error }) => {
                      return (
                        <Query query={ALL_SCHOOLS}>
                          {({ loading, data, error }) => {
                            if (loading) return "Loading...";
                            if (error) return `Error! ${error.message}`;
                            const { schools } = data;
                            return (
                              <AddOrEditUserForm
                                user={user}
                                onSubmit={values => this._handleEditUser(values, mutation)}
                                currentUser={currentUser}
                                allSchools={schools}
                              />
                            )
                          }}
                        </Query>

                      );
                    }}
                  </Mutation>
                )

              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}
