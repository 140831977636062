import {
  HomeContainer,
  AddLessonContainer,
  UsersContainer,
  ViewLessonContainer,
  AddTestQuestionContainer,
  AddUserContainer,
  EditUserContainer,
  ResetPasswordContainer,
  ViewUserContainer,
  ViewStudentContainer,
  CompetencyTestsContainer,
  EditLessonContainer,
  EditTestQuestionContainer,
  AddLessonActivityContainer,
  EditLessonActivityContainer,
  ClassroomContainer,
  ViewClassroomContainer,
  ViewDailyReportContainer,
  ViewWeeklyReportContainer,
  ViewCompetencyTestDetailsContainer,
  AddLessonSchemeContainer,
  LessonsContainer,
  AddSchemeOfWorkContainer,
  SchemeOfWorkContainer,
  ViewSchemeOfWorkContainer,
} from '../../containers/MainContent/AdminDashboardContainers';

import {
  CompetencyTestsContainer as TeacherCompetencyTestsContainer,
} from '../../containers/MainContent/DashboardContainers';

// There are two types of routes. The ones that should show up in the sidebar and the ones that do not.
// For the ones that show up we add a `name` and `iconType` property

const headTeacherDashboardRoutes = [
  {
    name: 'Home',
    iconType: 'home',
    path: '/main/admin/home',
    component: HomeContainer,
  },
  {
    name: 'Lessons',
    iconType: 'copy',
    path: '/main/admin/lessons',
    component: LessonsContainer,
  },
  {
    name: 'Teachers',
    iconType: 'user',
    path: '/main/admin/users',
    component: UsersContainer,
  },
  {
    name: 'Classrooms',
    iconType: 'book',
    path: '/main/admin/classrooms',
    component: ClassroomContainer,
  },
  {
    path: '/main/admin/classroom/:id',
    component: ViewClassroomContainer,
  },
  {
    path: '/main/admin/user/:id',
    component: ViewUserContainer,
  },
  {
    path: '/main/admin/lesson/:id',
    component: ViewLessonContainer,
  },
  {
    path: '/main/admin/add-question/:lessonId',
    component: AddTestQuestionContainer,
  },
  {
    path: '/main/admin/edit-question/:lessonId/:questionId',
    component: EditTestQuestionContainer,
  },
  {
    name: 'Add Lesson',
    iconType: 'file-add',
    path: '/main/admin/add-lessons',
    component: AddLessonContainer,
  },
  {
    path: '/main/admin/add-lesson-activity/:lessonId',
    component: AddLessonActivityContainer,
  },
  {
    path: '/main/admin/edit-lesson-activity/:lessonId/:activityId',
    component: EditLessonActivityContainer,
  },
  {
    path: '/main/admin/edit-lesson/:id',
    component: EditLessonContainer,
  },
  {
    name: 'My Lesson Tests',
    iconType: 'book',
    path: '/main/tests',
    component: TeacherCompetencyTestsContainer,
  },
  // {
  //   name: "Add Lesson Scheme",
  //   iconType: "file-add",
  //   path: "/main/admin/add-lesson-scheme",
  //   component: AddLessonSchemeContainer
  // },
  {
    path: '/main/admin/student/:id',
    component: ViewStudentContainer,
  },
  {
    path: '/main/admin/add-user',
    component: AddUserContainer,
  },
  {
    path: '/main/admin/edit-user/:id',
    component: EditUserContainer,
  },
  {
    path: '/main/admin/reset-password/:id',
    component: ResetPasswordContainer,
  },
  {
    name: 'Competency Tests',
    iconType: 'book',
    path: '/main/admin/tests',
    component: CompetencyTestsContainer,
  },
  {
    path: '/main/admin/view-test-details/:id',
    component: ViewCompetencyTestDetailsContainer,
  },
  {
    path: '/main/admin/daily-report/:id',
    component: ViewDailyReportContainer,
  },
  {
    path: '/main/admin/weekly-report/:id',
    component: ViewWeeklyReportContainer,
  },
  {
    name: 'Scheme Of Work',
    iconType: 'book',
    path: '/main/admin/all-scheme-of-work',
    component: SchemeOfWorkContainer,
  },
  {
    path: '/main/scheme-of-work/:id',
    component: ViewSchemeOfWorkContainer,
  },
  {
    path: '/main/admin/add-scheme-of-work',
    component: AddSchemeOfWorkContainer,
  },
  {
    redirect: true,
    path: '/main/admin/home',
  },
];

export { headTeacherDashboardRoutes };
