import gql from "graphql-tag";

export default gql`
  query users($filter: JSON!) {
    users(filter: $filter) {
      _id
      firstName
      lastName
      phone
      gender
      role
      email
      created_at
      updated_at
      verified
      gender
      schoolId
      school {
        _id
        name
      }
      classrooms {
        _id
        name
        classroomLevel
        subjectId
        subject {
          _id
          name
        }

        teacherId

        lessonIds
        lessons {
          _id
          name
        }

        studentIds

        isActive
        daily_reports {
          _id
        }
        weekly_reports {
          _id
        }
      }
    }
  }
`;
