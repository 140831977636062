import React from "react";
import styled from "styled-components";
import { Form, Icon, Input, Button, Select } from "antd";
import Mutation from "react-apollo/Mutation";
import CREATE_USER from "../../graphql/mutations/CREATE_USER";
import { persistUserAuthData } from "../../utils/persistStorage";
import idx from "idx";

const FormItem = Form.Item;
const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class AddOrEditUserForm extends React.Component {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log("Received values of form: ", values);
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { user, currentUser, allSchools } = this.props;

    return (
      <div>
        <Form onSubmit={e => this.handleSubmit(e)}>
          <FormItem label="First Name">
            {getFieldDecorator("firstName", {
              initialValue: idx(user, _ => _.firstName)
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="First Name"
              />
            )}
          </FormItem>
          <FormItem label="Last Name">
            {getFieldDecorator("lastName", {
              initialValue: idx(user, _ => _.lastName)
            })(
              <Input
                prefix={
                  <Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Last Name"
              />
            )}
          </FormItem>
          <FormItem label="Teacher Role">
            {getFieldDecorator("role", {
              initialValue: idx(user, _ => _.role)
            })(
              <Select defaultActiveFirstOption>
                <Option value={"teacher"}>Teacher</Option>
                <Option value={"senior_teacher"}>Senior Teacher</Option>
                <Option value={"head_teacher"}>
                  Head Teacher
                </Option>
              </Select>
            )}
          </FormItem>

          {currentUser.role === 'admin' && <FormItem label="School">
            {getFieldDecorator("schoolId", {
              initialValue: idx(user, _ => _.schoolId),
              rules: [
                {
                  required: currentUser.role === 'admin' ? true : false,
                  message: "Please select a school"
                },
              ]
            })(
              <Select
                showSearch
                defaultActiveFirstOption
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {allSchools && allSchools.map(school => (
                  <Option value={school._id} key={school._id}>
                    {school.name}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>}

          <FormItem label="Gender">
            {getFieldDecorator("gender", {
              initialValue: idx(user, _ => _.gender)
            })(
              <Select defaultActiveFirstOption>
                <Option value={"male"}>Male</Option>
                <Option value={"female"}>Female</Option>
              </Select>
            )}
          </FormItem>
          <FormItem label="Email">
            {getFieldDecorator("email", {
              initialValue: idx(user, _ => _.email)
            })(
              <Input
                prefix={
                  <Icon type="mail" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Email"
              />
            )}
          </FormItem>
          <FormItem label="Phone">
            {getFieldDecorator("phone", {
              initialValue: idx(user, _ => _.phone)
            })(
              <Input
                prefix={
                  <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                }
                placeholder="Phone"
              />
            )}
          </FormItem>
          {!user && (
            <React.Fragment>
              <FormItem label="Password">
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your password!"
                    },
                    {
                      validator: this.validateToNextPassword
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Password"
                  />
                )}
              </FormItem>
              <FormItem label="Confirm Password">
                {getFieldDecorator("confirmPassword", {
                  rules: [
                    {
                      required: true,
                      message: "Please confirm your password!"
                    },
                    {
                      validator: this.compareToFirstPassword
                    }
                  ]
                })(
                  <Input
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    type="password"
                    placeholder="Confirm Password"
                    onBlur={this.handleConfirmBlur}
                  />
                )}
              </FormItem>
            </React.Fragment>
          )}
          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              {!user ? "Add User" : "Save Changes"}
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const WrappedForm = Form.create()(AddOrEditUserForm);

export default WrappedForm;
