import gql from "graphql-tag";

export default gql`
  {
    lessons {
      _id
      created_at
      subject {
        _id
        name
      }
      classroomLevel
      name
      content
      goals
      objectives
      materials
      printables
      key_terms
      suggested_resources
      introduction
      development
      practice
      checks_for_understanding
      closure
      total_activity_time
      homework
      evaluation
      isActive
      deletedAt
    }
  }
`;
