import React, { useState } from "react";
import { Row, Col, Button } from "antd";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table, Select } from "antd";
import { Link } from "react-router-dom";
import TitleBar from "../../components/common/TitleBar";
import Avatar from "../../components/common/Avatar";
import KeyedList from "../../components/common/KeyedList";
import { Tabs } from "antd";
import CardSection, {
  CardSectionTitle
} from "../../components/common/CardSection";
import { DailyReportsChart } from "../../components/common";

const TabPane = Tabs.TabPane;
const { Option } = Select;

const ViewStudentScreen = ({ data, history, onRowSelect }) => {

  const rowSelection = {
    onChange: onRowSelect,
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name
    })
  };

  const dailyReports = data.daily_reports;

  const dailyReportsSubjects = dailyReports.reduce((subjectsArray, report) => {
    const subjectName = report.lesson.subject.name;
    if (subjectsArray.includes(subjectName)) {
      return subjectsArray;
    }

    return [...subjectsArray, subjectName];
  }, []);

  const [selectedSubject, setSelectedSubject] = useState(
    dailyReportsSubjects[0]
  );

  const handleSubjectSelect = selectedSubject =>
    setSelectedSubject(selectedSubject);

  const dailyReportsChartData = dailyReports
    .filter(report => selectedSubject == report.lesson.subject.name)
    .reduce(
      (chartData, report, index) => {
        const currentDate = moment(report.date).format("MMM Do YY");
        const previousDate =
          chartData[0].data[chartData[0].data.length - 1] &&
          chartData[0].data[chartData[0].data.length - 1].x;

        const datesAreSame = previousDate && currentDate == previousDate;

        if (datesAreSame) {
          return chartData;
        } else {
          chartData[0].data.push({
            x: `${index + 1}`,
            y: report.total_score,
            date: `${moment(report.date).format("MMM Do YY")}`,
            _id: report._id
          });

          return chartData;
        }
      },
      [
        {
          id: "daily reports",
          color: "hsl(110, 70%, 50%)",
          data: []
        }
      ]
    );

  const weeklyReportsColumns = [
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "Day 1 Score",
      dataIndex: "reports[0].total_score",
      key: "reports[0].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Day 2 Score",
      dataIndex: "reports[1].total_score",
      key: "reports[1].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Day 3 Score",
      dataIndex: "reports[2].total_score",
      key: "reports[2].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Day 4 Score",
      dataIndex: "reports[3].total_score",
      key: "reports[3].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Day 5 Score",
      dataIndex: "reports[4].total_score",
      key: "reports[4].total_score",
      render: text => (typeof text === "undefined" || text === 0 ? "N/A" : text)
    },
    {
      title: "Total Score",
      dataIndex: "total_score",
      key: "total_score"
    },
    {
      title: "Score Group",
      dataIndex: "score_group",
      key: "score_group",
      render: text => text.split("_")[2]
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/main/admin/weekly-report/${record._id}`}>View</Link>
        </span>
      )
    }
  ];

  const dailyReportsColumns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "Lesson",
      dataIndex: "lesson.name",
      key: "lesson.name"
    },
    {
      title: "Assessment Score",
      dataIndex: "assessment_score.total_score",
      key: "assessment_score.total_score"
    },
    {
      title: "Classroom Observation Score",
      dataIndex: "classroom_observation_score.total_score",
      key: "classroom_observation_score.total_score"
    },
    {
      title: "Group Observation Score",
      dataIndex: "group_observation_score.total_score",
      key: "group_observation_score.total_score"
    },
    {
      title: "Total Score",
      dataIndex: "total_score",
      key: "total_score"
    },
    {
      title: "Score Group",
      dataIndex: "score_group",
      key: "score_group",
      render: text => text.split("_")[2]
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/main/admin/daily-report/${record._id}`}>View</Link>
        </span>
      )
    }
  ];

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="Student Profile"
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
          />
          <Row gutter={12}>
            <Col xs={24} md={7}>
              <CardSection>
                <Avatar style={{ margin: "0 auto" }} />
                <h2 className="card-section-title">{`${data.firstName} ${
                  data.lastName
                }`}</h2>
                <KeyedList
                  data={data}
                  keys={[
                    { key: "firstName", label: "first name" },
                    { key: "lastName", label: "last name" },
                    { key: "middleName", label: "middle name" },
                    {
                      key: "dateOfBirth",
                      label: "date of birth"
                    },
                    { key: "gender", label: "gender" },
                    { key: "classroom.name", label: "classroom" },
                    { key: "created_at", label: "added on" }
                  ]}
                />
              </CardSection>
              <CardSection>
                <CardSectionTitle>Address</CardSectionTitle>
                <KeyedList
                  data={data}
                  keys={[
                    { key: "address", label: "address" },
                    { key: "state", label: "state" },
                    { key: "nationality", label: "nationality" }
                  ]}
                />
              </CardSection>
              <CardSection>
                <CardSectionTitle>Guardian Information</CardSectionTitle>
                <KeyedList
                  data={data}
                  keys={[
                    { key: "guardianFirstName", label: "first name" },
                    { key: "guardianLastName", label: "last name" },
                    { key: "guardianMiddleName", label: "middle name" },
                    {
                      key: "guardianOccupation",
                      label: "occupation"
                    },
                    { key: "gender", label: "gender" },
                    { key: "guardianPhoneNumber", label: "phone number" },
                    { key: "guardianEmail", label: "email" },
                    { key: "guardianAddress", label: "address" }
                  ]}
                />
              </CardSection>
            </Col>
            <Col xs={24} md={17}>
              <CardSection>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Student Information" key="1">
                    <h3>Student Details</h3>
                    <KeyedList
                      overflowOnMobile
                      data={data}
                      keys={[
                        { key: "firstName", label: "first name" },
                        { key: "lastName", label: "last name" },
                        { key: "middleName", label: "middle name" },
                        {
                          key: "dateOfBirth",
                          label: "date of birth"
                        },
                        { key: "gender", label: "gender" },
                        { key: "classroom.name", label: "classroom" },
                        { key: "created_at", label: "added on" },
                        { key: "address", label: "address" },
                        { key: "state", label: "state" },
                        { key: "nationality", label: "nationality" },
                        { key: "guardianFirstName", label: "first name" },
                        { key: "guardianLastName", label: "last name" },
                        { key: "guardianMiddleName", label: "middle name" },
                        {
                          key: "guardianOccupation",
                          label: "occupation"
                        },
                        { key: "gender", label: "gender" },
                        { key: "guardianPhoneNumber", label: "phone number" },
                        { key: "guardianEmail", label: "email" },
                        { key: "guardianAddress", label: "address" }
                      ]}
                    />
                  </TabPane>
                  <TabPane tab="Daily Reports" key="2">
                    <CardSectionTitle>
                      {`${data.firstName}'s Daily Reports`}{" "}
                    </CardSectionTitle>
                    <Table
                      rowSelection={rowSelection}
                      columns={dailyReportsColumns}
                      dataSource={data.daily_reports}
                      rowKey="_id"
                      style={{ minWidth: 813 }}
                    />
                  </TabPane>
                  <TabPane tab="Daily Reports Chart" key="3">
                    <h3>Select the subject you'd like to view</h3>
                    <Select
                      value={selectedSubject}
                      style={{ width: 120 }}
                      onChange={handleSubjectSelect}
                    >
                      {dailyReportsSubjects.map(subject => (
                        <Option key={subject} value={subject}>
                          {subject}
                        </Option>
                      ))}
                    </Select>
                    <DailyReportsChart
                      dailyReportsChartData={dailyReportsChartData}
                    />
                  </TabPane>
                  <TabPane tab="Weekly Reports" key="4">
                    <CardSectionTitle>
                      {`${data.firstName}'s Weekly Reports`}{" "}
                    </CardSectionTitle>
                    <Table
                      rowSelection={rowSelection}
                      columns={weeklyReportsColumns}
                      dataSource={data.weekly_reports}
                      rowKey="_id"
                      style={{ minWidth: 813 }}
                    />
                  </TabPane>
                </Tabs>
              </CardSection>
            </Col>
          </Row>
        </div>
      )}
    </Media>
  );
};

const ViewStudentScreenWithRouter = withRouter(ViewStudentScreen);
export default ViewStudentScreenWithRouter;
