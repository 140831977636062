import React from "react";
import { Mutation, Query } from "react-apollo";
import CREATE_CLASSROOM from "../../../graphql/mutations/CREATE_CLASSROOM";
import ALL_SUBJECTS from "../../../graphql/queries/ALL_SUBJECTS";
import AddClassroomForm from "../../../screens/forms/AddClassroomForm";

class AddClassroomContainer extends React.Component {
  _handleAddClassroom = async (values, mutation) => {
    try {
      await mutation({ variables: { classroom: values } });
      this._handleNextRoute();
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  _handleNextRoute = () => this.props.history.push("/main/classrooms");

  render() {
    return (
      <Query query={ALL_SUBJECTS}>
        {({ loading, error, data }) => {
          console.log("[Error]", error);
          console.log("{[Data]}", data);
          return (
            <Mutation mutation={CREATE_CLASSROOM}>
              {(mutation, { loading, error }) => {
                return (
                  <AddClassroomForm
                    data={data}
                    history={this.props.history}
                    onSubmit={values =>
                      this._handleAddClassroom(values, mutation)
                    }
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export { AddClassroomContainer };
