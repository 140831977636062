import React from 'react';
import { Query, Mutation } from 'react-apollo';
import CURRENT_USER from '../../../graphql/queries/CURRENT_USER';
import ALL_SCHEME_OF_WORK from '../../../graphql/queries/ALL_SCHEME_OF_WORK';
import DELETE_SCHEME_OF_WORK from '../../../graphql/mutations/DELETE_SCHEME_OF_WORK';
import ViewAllSchemeOfWorkScreen from '../../../screens/SchemeOfWorkScreen';

class SchemeOfWorkContainer extends React.Component {
  _onDelete = async (id, mutation) => {
    try {
      const { data } = await mutation({
        variables: { _id: id },
      });
    } catch (e) {
      console.log('Error deleting scheme of work: ', e);
    }
  };
  render() {
    return (
      <Mutation
        mutation={DELETE_SCHEME_OF_WORK}
        update={(cache, { data: { deleteSchemeOfWork } }) => {
          const { schemeOfWork } = cache.readQuery({
            query: ALL_SCHEME_OF_WORK,
          });
          cache.writeQuery({
            query: ALL_SCHEME_OF_WORK,
            data: {
              schemeOfWork: schemeOfWork.filter(
                scheme => scheme._id !== deleteSchemeOfWork._id,
              ),
            },
          });
        }}
      >
        {(mutation, { loading, error }) => (
          <Query query={CURRENT_USER}>
            {({ loading, data, error }) => {
              if (loading) return 'Loading...';
              if (error) return `Error! ${error.message}`;
              const { currentUser } = data;
              return (
                <Query query={ALL_SCHEME_OF_WORK}>
                  {({ loading, data, error }) => {
                    if (loading) return 'Loading...';
                    if (error) return `Error! ${error.message}`;
                    const { schemeOfWork } = data;
                    return (
                      <ViewAllSchemeOfWorkScreen
                        user={currentUser}
                        schemes={schemeOfWork}
                        onDelete={id => this._onDelete(id, mutation)}
                      />
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}

export { SchemeOfWorkContainer };
