import React from "react";
import { Mutation, Query } from "react-apollo";
import CREATE_LESSON from "../../../graphql/mutations/CREATE_LESSON";
import ALL_SUBJECTS from "../../../graphql/queries/ALL_SUBJECTS";
import AddLessonForm from "../../../screens/forms/AddOrEditLessonForm";

class AddLessonContainer extends React.Component {
  _handleAddLesson = async (values, mutation) => {
    try {
      await mutation({ variables: { lesson: values } });
      this._handleNextRoute();
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  _handleNextRoute = () => this.props.history.push("/main/lessons");

  render() {
    return (
      <Query query={ALL_SUBJECTS}>
        {({ loading, error, data: subjectQuery }) => {
          console.log("[Error]", error);
          console.log("{[Data]}", subjectQuery);
          return (
            <Mutation mutation={CREATE_LESSON}>
              {(mutation, { loading, error }) => {
                return (
                  <AddLessonForm
                    subjects={subjectQuery.subjects}
                    onSubmit={values => this._handleAddLesson(values, mutation)}
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export { AddLessonContainer };
