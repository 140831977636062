import React from "react";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import TitleBar from "../../components/common/TitleBar";
import { GreyH5 } from "../../components/styled";
import { Icon } from "antd";

const ViewCompetencyTestDetailsScreen = ({ data: test }) => {
  console.log("test: ", test);

  const AnswerSectionEl = styled.section`
    margin-bottom: 62px;

    .question-label {
      font-size: 90%;
      margin-left: 32px;
      display: block;

      span {
        font-weight: bold;
        text-transform: capitalize;
      }

      margin-bottom: 24px;
    }

    .question-detail {
      color: #ababab;

      span {
        display: block;
        color: #515050;

        .capitalize {
          display: inline;
          text-transform: uppercase;
          margin-right: 10px;
        }
      }
    }
  `;

  const QuestionText = styled.h6`
    font-size: 1.8rem;
    color: #0a0a0a;
    font-weight: bold;
    margin-bottom: 0px;
  `;

  const renderAnswer = (answer, i) => {
    console.log(answer);
    //TODO: display multiple labels if an array
    const label =
      answer.question_details.label &&
      typeof answer.question_details.label == "string"
        ? answer.question_details.label
        : answer.question_details.label && answer.question_details.label[0];
    return (
      <AnswerSectionEl key={answer._id}>
        <GreyH5>question {i + 1}</GreyH5>
        <QuestionText>
          {
            <Icon
              style={{
                marginRight: 10,
                color: answer.is_correct ? "green" : "red"
              }}
              type={answer.is_correct ? "check-circle" : "close-circle"}
            />
          }
          {answer.question_details.question}
        </QuestionText>
        {label && (
          <small className="question-label">
            {console.log("it is, ", label)}
            {"This question is tagged: "}
            <span>{label && label.replace(/_/g, " ")}</span>
          </small>
        )}
        <span>
          <h3 className="question-detail">
            Teacher's Answer:
            <span>
              <span className="capitalize">{answer.answer}:</span>
              {answer.question_details[answer.answer]}
            </span>
          </h3>
        </span>
        <span>
          <h3 className="question-detail">
            Correct Answer:
            <span>
              <span className="capitalize">
                {answer.question_details.correct_answer}:
              </span>
              {answer.question_details[answer.question_details.correct_answer]}
            </span>
          </h3>
        </span>
        <span>
          <h3 className="question-detail">Provided Options:</h3>
          <h4 className="question-detail">
            <span>
              <span className="capitalize">A:</span>
              {answer.question_details["a"]}
            </span>
          </h4>
          <h4 className="question-detail">
            <span>
              <span className="capitalize">B:</span>
              {answer.question_details["b"]}
            </span>
          </h4>
          <h4 className="question-detail">
            <span>
              <span className="capitalize">C:</span>
              {answer.question_details["c"]}
            </span>
          </h4>
          <h4 className="question-detail">
            <span>
              <span className="capitalize">D:</span>
              {answer.question_details["d"]}
            </span>
          </h4>
        </span>
      </AnswerSectionEl>
    );
  };

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title={`Competency Test`}
            explainerText={`Here's a list of answers provided by the teacher`}
          />

          <section>
            <p>
              This test is{" "}
              {test.is_passed ? <span>Passed</span> : <span>Not Passed</span>}
            </p>
            <p>Times Attempted: {test.times_attempted}</p>
            <div>{test.answers.map(renderAnswer)}</div>
          </section>
        </div>
      )}
    </Media>
  );
};

export default ViewCompetencyTestDetailsScreen;
