import moment from 'moment';

export const tenYears = [
  moment().format('YYYY'),
  moment().add(1, 'years').format('YYYY'),
  moment().add(2, 'years').format('YYYY'),
  moment().add(3, 'years').format('YYYY'),
  moment().add(4, 'years').format('YYYY'),
  moment().add(5, 'years').format('YYYY'),
  moment().add(6, 'years').format('YYYY'),
  moment().add(7, 'years').format('YYYY'),
  moment().add(8, 'years').format('YYYY'),
  moment().add(9, 'years').format('YYYY'),
];
