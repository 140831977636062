import React from "react";
import styled from "styled-components";

const GreyH5 = styled.h5`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #3f7af6;
  margin-bottom: 12px;
`;

export { GreyH5 };
