import React, { Component } from 'react'
import { Query } from 'react-apollo';
import FETCH_SCHEME_OF_WORK from '../../../graphql/queries/FETCH_SCHEME_OF_WORK';
import ViewSchemeOfWorkScreen from '../../../screens/ViewSchemeOfWorkScreen'

class ViewSchemeOfWorkContainer extends Component {

  render() {
    return (
      <Query
        query={FETCH_SCHEME_OF_WORK}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { schemeOfWork } = data;
          const scheme = schemeOfWork[0];
          return (
            <ViewSchemeOfWorkScreen scheme={scheme} />
          )
        }}
      </Query>
    )
  }
}

export { ViewSchemeOfWorkContainer }