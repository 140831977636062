import React from "react";
import { Line } from "@nivo/line";
import { withRouter } from "react-router-dom";

const WeekReportsChart = withRouter(({ weekReportChartData, history, bottomAxisLabel, leftAxisLabel, scoreTitle }) => {

  return (
    <div style={{ height: 400, width: 1000 }}>
      <Line
        data={weekReportChartData}
        margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
        xScale={{ type: "point" }}
        yScale={{
          type: "linear",
          stacked: true,
          min: "auto",
          max: "auto"
        }}
        width={weekReportChartData[0].data.length * 100}
        height={400}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: "bottom",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: bottomAxisLabel,
          legendOffset: 36,
          legendPosition: "middle"
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: leftAxisLabel,
          legendOffset: -40,
          legendPosition: "middle"
        }}
        colors={{ scheme: "nivo" }}
        pointSize={10}
        pointColor={{ theme: "background" }}
        pointBorderWidth={2}
        pointBorderColor={{ from: "serieColor" }}
        pointLabel="date"
        onClick={(point, event) => {
          console.log("clicked: ", point);
          history.push(`/main/daily-report/${point.data._id}`);
        }}
        enableSlices="x"
        sliceTooltip={({ slice }) => {
          const [
            {
              data: { date, y, lesson }
            }
          ] = slice.points;
          return (
            <div
              style={{
                background: "white",
                padding: "9px 12px",
                border: "1px solid #ccc"
              }}
            >
              <div>Date: {date}</div>
              <div>{scoreTitle} {y}</div>
              {lesson && <div>Lesson: {lesson}</div>}
            </div>
          );
        }}
        pointLabelYOffset={-12}
        useMesh={true}
      />
    </div>
  );
});

export { WeekReportsChart };
