import React from "react";
import { Mutation, Query } from "react-apollo";
import CREATE_USER from "../../../graphql/mutations/CREATE_USER";
import CURRENT_USER from '../../../graphql/queries/CURRENT_USER';
import ALL_SCHOOLS from '../../../graphql/queries/ALL_SCHOOLS';
import AddOrEditUserForm from "../../../screens/forms/AddOrEditUserForm";

export class AddUserContainer extends React.Component {
  _handleAddUser = async (values, mutation) => {
    console.log("submitting form");
    try {
      const { data } = await mutation({ variables: { user: values } });
      // retrieve token, persist it and route back to home

      console.log("[Created teacher]: ", data);
      this.props.history.push(`/main/admin/users`);
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  render() {
    return (
      <Query query={CURRENT_USER}>
        {({ loading, error, data }) => {
          if (loading) return 'loading...';
          if (error) console.log('Got an error:', error);
          const { currentUser } = data;
          return (
            <Mutation mutation={CREATE_USER}>
              {(mutation, { loading, error }) => {
                return (
                  <Query query={ALL_SCHOOLS}>
                    {({ loading, data, error }) => {
                      if (loading) return "Loading...";
                      if (error) return `Error! ${error.message}`;
                      const { schools } = data;
                      return (
                        <AddOrEditUserForm
                          onSubmit={values => this._handleAddUser(values, mutation)}
                          currentUser={currentUser}
                          allSchools={schools}
                        />
                      )
                    }
                    }
                  </Query>

                );
              }}
            </Mutation>
          )

        }}
      </Query>
    );
  }
}
