import React from "react";
import moment from "moment";
import { Table, Button } from "antd";
import { Link, withRouter } from "react-router-dom";
import TitleBar from "../components/common/TitleBar";
import Media from "react-media";

const ClassroomsScreen = ({ data, rowClick, history }) => {
  const columns = [
    {
      title: "Class Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/main/classroom/${record._id}`}>{text}</Link>
      )
    },
    {
      title: "Classroom Level",
      dataIndex: "classroomLevel",
      key: "classroomLevel"
    },
    {
      title: "Subject",
      dataIndex: "subject.name",
      key: "subject"
    },
    {
      title: "Students",
      dataIndex: "students",
      key: "students",
      render: students => (Array.isArray(students) ? students.length : 0)
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: text => (text ? "yes" : "no")
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: text => moment(text).format("MMM Do YY")
    }
  ];

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="Classrooms"
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
            buttons={[
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="usergroup-add"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() => history.push("/main/add-classroom")}
              >
                Add Classroom
              </Button>,
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="user-add"
                onClick={() => history.push("/main/add-student")}
              >
                Add Student
              </Button>
            ]}
          />
          <Table
            columns={columns}
            dataSource={data}
            rowKey="_id"
            style={{ minWidth: 813 }}
          />
        </div>
      )}
    </Media>
  );
};

const ClassroomsScreenWithRouter = withRouter(ClassroomsScreen);
export default ClassroomsScreenWithRouter;
