import gql from "graphql-tag";

export default gql`
  mutation createClassroom($classroom: ClassroomInputNew!) {
    createClassroom(classroom: $classroom) {
      _id
      name
      teacherId
      classroomLevel
      subjectId
    }
  }
`;
