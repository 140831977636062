import React from "react";
import { Mutation, Query } from "react-apollo";
import CREATE_TEST_QUESTION from "../../../graphql/mutations/CREATE_TEST_QUESTION";
import FETCH_LESSON from "../../../graphql/queries/FETCH_LESSON";
import AddTestQuestionForm from "../../../screens/forms/AddOrEditTestQuestionForm";

class AddTestQuestionContainer extends React.Component {
  _handleAddTestQuestion = async (values, mutation) => {
    console.log("we are submitting: ", values);
    try {
      const {
        data: { createTestQuestion: question }
      } = await mutation({ variables: { testQuestion: values } });
      console.log(question);
      this._handleNextRoute(question.lessonId);
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  _handleNextRoute = lessonId =>
    this.props.history.push(`/main/admin/lesson/${lessonId}`);

  render() {
    return (
      <Query
        query={FETCH_LESSON}
        variables={{ filter: { _id: this.props.match.params.lessonId } }}
      >
        {({ data, loading, error }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { lessons } = data;
          const lesson = lessons[0];
          return (
            <Mutation mutation={CREATE_TEST_QUESTION}>
              {(mutation, { loading, error }) => {
                const lessonId = this.props.match.params.lessonId;
                console.log("[lesson id]", this.props);
                return (
                  <AddTestQuestionForm
                    title={lesson.name}
                    subjectName={lesson.subject.name}
                    lessonId={lessonId}
                    onSubmit={values =>
                      this._handleAddTestQuestion(values, mutation)
                    }
                  />
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export { AddTestQuestionContainer };
