import gql from "graphql-tag";

export default gql`
  mutation verifyUser($verificationCode: String!, $userId: String) {
    verifyUser(verificationCode: $verificationCode, userId: $userId) {
      _id
      firstName
      lastName
      phone
      gender
      role
      email
      created_at
      updated_at
      verified
      gender
    }
  }
`;
