import React from "react";
import { Query, Mutation } from "react-apollo";
import ALL_CLASSROOMS from "../../../graphql/queries/ALL_CLASSROOMS";
import GENERATE_WEEKLY_REPORT from "../../../graphql/mutations/GENERATE_WEEKLY_REPORT";
import ReportsScreen from "../../../screens/ReportsScreen";

class ReportsContainer extends React.Component {
  _generateWeeklyReport = mutation => async (date, student, classroom) => {
    console.log(date, student, classroom);
    const weeklyReport = {
      studentId: student._id,
      subjectId: classroom.subject._id,
      classroomId: classroom._id,
      startDate: date[0],
      endDate: date[1]
    };

    try {
      const { data } = await mutation({ variables: { weeklyReport } });
      // retrieve token, persist it and route back to home
      console.log("[Created weekly report]: ", data);
      // handle created report. We need a view for the weekly report
      this.props.history.push(
        `/main/weekly-report/${data.generateWeeklyReport._id}`
      );
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  render() {
    return (
      <Mutation mutation={GENERATE_WEEKLY_REPORT}>
        {(mutation, { loading, error }) => (
          <Query query={ALL_CLASSROOMS}>
            {({ loading, error, data }) => {
              if (loading) return "Loading...";
              if (error) console.log("[Error fetching classrooms]: ", error);
              const { classrooms } = data;
              console.log("[classrooms]", classrooms);
              return (
                <ReportsScreen
                  generateWeeklyReport={this._generateWeeklyReport(mutation)}
                  classrooms={classrooms}
                />
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}

export { ReportsContainer };
