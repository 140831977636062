import React, { useState } from 'react';
import { Row, Col, Button, Alert, Select, Table } from 'antd';
import { DataOverviewCards } from '../components/common';
import { withRouter, Link } from 'react-router-dom';
import styled from 'styled-components';
import Media from 'react-media';

import { styleConstants } from '../constants';

import TitleBar from '../components/common/TitleBar';
import { WeekReportsChart } from '../components/common/WeekReportsChart';
import PieChart from '../components/common/PieChart';
import CardSection, {
  CardSectionTitle,
} from '../components/common/CardSection';

import {
  getAverageDailyReport,
  getWeeklyReportChartData,
} from '../helpers/formatChartData';

import { Query, Mutation } from 'react-apollo';
import ALL_SCHOOLS from '../graphql/queries/ALL_SCHOOLS';
import FETCH_STUDENTS from '../graphql/queries/FETCH_STUDENTS';
import EDIT_USER from '../graphql/mutations/EDIT_USER';
import AddSchoolCodeContainer from '../containers/MainContent/DashboardContainers/AddSchoolCodeContainer';

const { Option } = Select;

const DailyReportsDiv = styled.div`
  border: 1px solid #e8e8e8;
  padding: 10px;
  min-height: 250px;
`;

const HomeScreen = ({
  history,
  user,
  numberOfClassrooms,
  numberOfLessonsPassed,
  allStudents,
  numberOfStudents,
  onOpenSelectSchoolModal,
  onCloseSelectSchoolModal,
  showSchoolSelectionModal,
}) => {
  const classroomChartProps = {
    x: 'created_at',
    y: 'total_score',
  };

  const [selectedClassroom, setSelectedClassroom] = useState(
    user.classrooms.length > 0 && user.classrooms[0] && user.classrooms[0]._id,
  );
  const [defaultStudent, setDefaultStudent] = useState(null);

  const [selectedAverageClassroom, setSelectedAverageClassroom] = useState(
    user.classrooms.length > 0 && user.classrooms[0] && user.classrooms[0]._id,
  );

  const [selectedStudent, setSelectedStudent] = useState(null);
  const [unrefinedChartData, setUnrefinedChartData] = useState(null);

  const averageReportData =
    user.classrooms.length > 0
      ? getAverageDailyReport(selectedAverageClassroom, user.classrooms)
      : null;

  const weekReportChartData =
    unrefinedChartData && unrefinedChartData.length > 0
      ? getWeeklyReportChartData(unrefinedChartData)
      : null;

  const handleClassroomSelect = selectedClassroom =>
    setSelectedClassroom(selectedClassroom);

  const handleAverageClassroomSelect = classroom =>
    setSelectedAverageClassroom(classroom);

  const handleViewStudentPerformance = student => {
    setSelectedStudent(student);
  };

  const onSelectSchool = async (mutation, schoolId) => {
    const values = { schoolId };

    try {
      const { data } = await mutation({
        variables: { _id: user._id, user: values },
      });
      onCloseSelectSchoolModal();
    } catch (e) {
      console.log('Error editing student: ', e);
    }
  };
  const genderBreakDown = allStudents => {
    let boys = 0,
      girls = 0,
      unknown = 0;

    allStudents.forEach(function({ gender }) {
      if (gender === 'female') {
        girls = girls + 1;
      } else if (gender === 'male') {
        boys = boys + 1;
      } else {
        unknown = unknown + 1;
      }
    });

    return [
      { x: 'Boys', y: boys },
      { x: 'Girls', y: girls },
      { x: 'Not Specified', y: unknown },
    ];
  };

  const studentColumns = [
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (text, record) => (
        <Link to={`/main/student/${record._id}`}>{text}</Link>
      ),
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Button onClick={() => handleViewStudentPerformance(record)}>
          View Performance
        </Button>
      ),
    },
  ];

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          {!user.school && user.role !== 'admin' && (
            <Alert
              message={`You're not assigned to a school`}
              type="warning"
              showIcon
              closeText="select your school"
              afterClose={onOpenSelectSchoolModal}
              style={{ marginBottom: 10 }}
              closable={false}
            />
          )}
          <TitleBar
            title="Home"
            style={{
              display: `${matches ? 'block' : 'flex'}`,
              justifyContent: 'space-between',
            }}
            buttons={[
              <Button
                size={matches ? 'small' : 'default'}
                type="primary"
                icon="usergroup-add"
                style={{ marginRight: 8, marginBottom: '.5em' }}
                onClick={() => history.push('/main/add-classroom')}
              >
                Add Classroom
              </Button>,
              <Button
                size={matches ? 'small' : 'default'}
                type="primary"
                icon="user-add"
                onClick={() => history.push('/main/add-student')}
              >
                Add Student
              </Button>,
            ]}
          />
          <Row gutter={8}>
            <Col xs={24} md={6}>
              <DataOverviewCards
                title="All Students"
                data={numberOfStudents}
                textColor={`${styleConstants.color.blue}`}
              />
            </Col>
            <Col xs={24} md={6}>
              <DataOverviewCards
                title="All Classrooms"
                data={numberOfClassrooms}
                textColor={`${styleConstants.color.red}`}
              />
            </Col>
            <Col xs={24} md={6}>
              <DataOverviewCards
                title="All Students"
                data={numberOfStudents}
                textColor={`${styleConstants.color.green}`}
              />
            </Col>
            <Col xs={24} md={6}>
              <DataOverviewCards
                title="Lessons Unlocked"
                data={numberOfLessonsPassed}
                textColor={`${styleConstants.color.orange}`}
              />
            </Col>
          </Row>

          <Row gutter={8} style={{ marginTop: 72 }}>
            <Col xs={24} md={8}>
              <CardSection shadow>
                <CardSectionTitle>
                  Gender Breakdown (All Students)
                </CardSectionTitle>
                {allStudents.length ? (
                  <PieChart data={genderBreakDown(allStudents)} />
                ) : (
                  <div>No students available yet</div>
                )}
              </CardSection>
            </Col>

            <Col xs={24} md={16}>
              <CardSection shadow>
                <Row gutter={8}>
                  <Col xs={24} md={10}>
                    <h2>Average performance by class</h2>
                  </Col>
                  <Col xs={24} md={13}>
                    {user.classrooms.length > 0 && (
                      <Select
                        value={selectedAverageClassroom}
                        onChange={handleAverageClassroomSelect}
                      >
                        {user.classrooms.map(classroom => (
                          <Option key={classroom._id} value={classroom._id}>
                            {classroom.name}
                          </Option>
                        ))}
                      </Select>
                    )}
                  </Col>
                </Row>

                <Row>
                  <DailyReportsDiv>
                    {averageReportData &&
                    averageReportData[0].data.length > 0 &&
                    selectedAverageClassroom ? (
                      <WeekReportsChart
                        weekReportChartData={averageReportData}
                        bottomAxisLabel="Days"
                        leftAxisLabel="Total Score"
                        scoreTitle="Average Score:"
                      />
                    ) : (
                      <div>No Data</div>
                    )}
                  </DailyReportsDiv>
                </Row>
              </CardSection>
            </Col>
          </Row>

          <Row>
            <Col xs={24} md={24}>
              <CardSection shadow>
                <Row gutter={8}>
                  <Col xs={24} md={7}>
                    <h2>Student performance</h2>
                  </Col>
                  <Col xs={24} md={1}>
                    <h3>Class</h3>
                  </Col>
                  {user.classrooms.length > 0 && (
                    <Col xs={24} md={16}>
                      <Select
                        value={selectedClassroom}
                        onChange={handleClassroomSelect}
                      >
                        {user.classrooms.map(classroom => (
                          <Option key={classroom._id} value={classroom._id}>
                            {classroom.name}
                          </Option>
                        ))}
                      </Select>
                    </Col>
                  )}
                </Row>
                {user.classrooms.length > 0 ? (
                  <Row gutter={8} style={{ marginTop: 35, minHeight: 250 }}>
                    <Col xs={24} md={10}>
                      <Query
                        query={FETCH_STUDENTS}
                        variables={{
                          filter: { classroomId: selectedClassroom },
                        }}
                      >
                        {({ loading, error, data: { students } }) => {
                          if (loading) return 'Loading...';
                          if (error) return `Error! ${error.message}`;
                          setDefaultStudent(
                            selectedStudent
                              ? selectedStudent._id
                              : students[0] && students[0],
                          );
                          return (
                            <Table
                              columns={studentColumns}
                              dataSource={students}
                              rowKey="_id"
                              pagination={{ defaultPageSize: 5 }}
                              bordered
                            />
                          );
                        }}
                      </Query>
                    </Col>
                    <Col xs={24} md={14}>
                      {defaultStudent && (
                        <Query
                          query={FETCH_STUDENTS}
                          variables={{
                            filter: {
                              _id: selectedStudent
                                ? selectedStudent._id
                                : defaultStudent._id,
                            },
                          }}
                        >
                          {({ loading, error, data: { students } }) => {
                            if (loading) return 'Loading...';
                            if (error) return `Error! ${error.message}`;
                            const student = students[0];
                            setUnrefinedChartData(
                              student && student.daily_reports,
                            );
                            return (
                              <DailyReportsDiv>
                                <h3>
                                  Daily reports of the past 7 days
                                  {selectedStudent
                                    ? ` for: ${selectedStudent.firstName}`
                                    : ` for: ${defaultStudent.firstName}`}
                                  {selectedStudent
                                    ? ` ${selectedStudent.lastName}`
                                    : ` ${defaultStudent.lastName}`}
                                </h3>
                                {weekReportChartData && defaultStudent ? (
                                  <WeekReportsChart
                                    weekReportChartData={weekReportChartData}
                                    bottomAxisLabel="Days"
                                    leftAxisLabel="Total Score"
                                    scoreTitle="Total Score:"
                                  />
                                ) : (
                                  <div>No Data</div>
                                )}
                              </DailyReportsDiv>
                            );
                          }}
                        </Query>
                      )}
                    </Col>
                  </Row>
                ) : (
                  <div>No classroom(s) available yet</div>
                )}
              </CardSection>
            </Col>
          </Row>

          <AddSchoolCodeContainer
            onCloseSelectSchoolModal={onCloseSelectSchoolModal}
            isVisible={showSchoolSelectionModal}
          />
        </div>
      )}
    </Media>
  );
};

// TODO:
/*

What we need is to take the teacher's classrooms. Then for each student, collate their daily reports and then make a line chart for each of them.

The teacher can then select the classroom he wants. This will also work with the admin except that admin will have access to all classrooms on the platform.

 a flag is needed for the query of daily reports for the last 7 days
*/

const HomeScreenWithRouter = withRouter(HomeScreen);
export default HomeScreenWithRouter;
