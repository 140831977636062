import React from 'react';
import { Form, Icon, Input, Button, Select } from 'antd';
import idx from 'idx';
import { Query } from 'react-apollo';
import FETCH_LGAS from '../../graphql/queries/FETCH_LGAS';

const FormItem = Form.Item;
const { Option } = Select;

const stateEnum = ['Lagos', 'Kaduna'];

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}
class AddOrEditSchoolForm extends React.Component {
  state = {
    lgas: null,
  };

  _sortLgas = () => {
    const { localGovtAreas } = this.props;
    if (!localGovtAreas.length || localGovtAreas.length === null) {
      return this.setState({
        lgas: localGovtAreas[Object.keys(localGovtAreas)[0]],
      });
    }
    return this.setState({ lgas: localGovtAreas });
  };

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    const { school } = this.props;
    return (
      <div>
        <Form onSubmit={e => this.handleSubmit(e)}>
          <FormItem label="School Name">
            {getFieldDecorator('name', {
              initialValue: idx(school, _ => _.name),
            })(<Input />)}
          </FormItem>

          <FormItem label="State">
            {getFieldDecorator('state', {
              initialValue: idx(school, _ => _.state),
            })(
              <Select
                placeholder="Select a state"
                onSelect={this.props.handleStateChange}
                showSearch
              >
                {stateEnum &&
                  stateEnum.map((state, i) => (
                    <Option key={i} value={state}>
                      {state}
                    </Option>
                  ))}
              </Select>,
            )}
          </FormItem>
          <Query
            query={FETCH_LGAS}
            variables={{ filter: { state: this.props.selectedState } }}
            fetchPolicy="cache-and-network"
          >
            {({ loading, data, error }) => {
              // if (loading) return "Loading...";
              if (error)
                console.log('[Error fetching local governments]: ', error);
              const { localGovtQuery } = data;
              return (
                <FormItem label="Local Govt Area">
                  {getFieldDecorator('local_govt_area', {
                    initialValue: idx(school, _ => _.local_govt_area),
                  })(
                    <Select
                      placeholder="Select an LGA"
                      defaultActiveFirstOption
                      showSearch
                    >
                      {localGovtQuery &&
                        localGovtQuery.map((localGovtArea, i) => (
                          <Option key={i} value={localGovtArea}>
                            {localGovtArea}
                          </Option>
                        ))}
                    </Select>,
                  )}
                </FormItem>
              );
            }}
          </Query>
          <FormItem label="Address">
            {getFieldDecorator('address', {
              initialValue: idx(school, _ => _.address),
            })(<Input />)}
          </FormItem>

          <FormItem label="School City">
            {getFieldDecorator('city', {
              initialValue: idx(school, _ => _.city),
            })(<Input />)}
          </FormItem>

          {school && school.code && (
            <FormItem label="School Code">
              {getFieldDecorator('code', {
                initialValue: idx(school, _ => _.code),
              })(<Input disabled />)}
            </FormItem>
          )}

          <FormItem>
            <Button
              type="primary"
              htmlType="submit"
              disabled={hasErrors(getFieldsError())}
            >
              {!school ? 'Add School' : 'Save Changes'}
            </Button>
          </FormItem>
        </Form>
      </div>
    );
  }
}

const WrappedSchoolForm = Form.create()(AddOrEditSchoolForm);

export default WrappedSchoolForm;
