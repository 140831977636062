import React from "react";
import { Mutation, Query } from "react-apollo";
import CREATE_LESSON from "../../../graphql/mutations/CREATE_LESSON";
import ALL_SUBJECTS from "../../../graphql/queries/ALL_SUBJECTS";
import AddLessonSchemeForm from "../../../screens/admin/AddOrEditLessonSchemeForm";

class AddLessonSchemeContainer extends React.Component {
  render() {
    return (
      <Query query={ALL_SUBJECTS}>
        {({ loading, error, data }) => {
          console.log("[Error]", error);
          console.log("{[Data]}", data);
          return <AddLessonSchemeForm subjects={data} />;
        }}
      </Query>
    );
  }
}

export { AddLessonSchemeContainer };
