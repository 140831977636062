import React from "react";
import FETCH_STUDENT from "../../../graphql/queries/FETCH_STUDENTS";
import DELETE_STUDENT from "../../../graphql/mutations/DELETE_STUDENT";
import FETCH_CLASSROOM from "../../../graphql/queries/FETCH_CLASSROOM";
import ViewStudentScreen from "../../../screens/ViewStudentScreen";
import { Mutation, Query } from "react-apollo";

class ViewStudentContainer extends React.Component {
  onDelete = async (id, mutation) => {
    try {
      const { data } = await mutation({
        variables: { _id: id }
      });
      this.props.history.push("/main/students");
    } catch (e) {
      console.log("Error deleting student", e);
    }
  };

  render() {
    return (
      <Mutation mutation={DELETE_STUDENT}>
        {(deleteMutation, { loading, error }) => (
          <Query
            query={FETCH_CLASSROOM}
            variables={{ filter: { _id: this.props.match.params.classId } }}
          >
            {({ loading, error, data }) => {
              if (loading) return "Loading...";
              if (error) return `Error! ${error.message}`;
              const { classrooms } = data;
              const classroom = classrooms[0];
              return (
                <Query
                  query={FETCH_STUDENT}
                  variables={{ filter: { _id: this.props.match.params.id } }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return "Loading...";
                    if (error) return `Error! ${error.message}`;
                    const { students } = data;
                    const student = students[0];
                    return (
                      <ViewStudentScreen
                        classRoom={classroom}
                        onRowSelect={this._handleSelectRow}
                        data={student}
                        onDelete={id => this.onDelete(id, deleteMutation)}
                      />
                    );
                  }}
                </Query>

              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}

export { ViewStudentContainer };
