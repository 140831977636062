import React from "react";
import { message } from "antd";

export default class Toast extends React.Component {
  componentDidUpdate(prevProps) {
    console.log("The component updated: ", prevProps, this.props);
    if (this.props.msg.length) {
      message[this.props.type](this.props.msg);
    }
  }

  render() {
    return <div />;
  }
}
