import gql from 'graphql-tag';

export default gql`
  mutation addSchoolCode($code: String!, $userId: String!) {
    addSchoolCode(code: $code, userId: $userId) {
      _id
      schoolId
    }
  }
`;
