import React from "react";
import { Mutation, Query } from "react-apollo";
import EDIT_TEST_QUESTION from "../../../graphql/mutations/EDIT_TEST_QUESTION";
import FETCH_TEST_QUESTION from "../../../graphql/queries/FETCH_TEST_QUESTION";
import FETCH_LESSON from "../../../graphql/queries/FETCH_LESSON";
import EditTestQuestionForm from "../../../screens/forms/AddOrEditTestQuestionForm";

class EditTestQuestionContainer extends React.Component {
  _handleEditTestQuestion = async (values, mutation) => {
    const {
      lessonId,
      __typename,
      created_at,
      updated_at,
      _id,
      ...allowedFields
    } = values;

    try {
      const {
        data: { editTestQuestion: question }
      } = await mutation({
        variables: {
          _id: this.props.match.params.questionId,
          testQuestion: allowedFields
        }
      });
      console.log(question);
      this._handleNextRoute(this.props.match.params.lessonId);
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  _handleNextRoute = lessonId =>
    this.props.history.push(`/main/admin/lesson/${lessonId}`);

  render() {
    return (
      <Query
        query={FETCH_LESSON}
        variables={{ filter: { _id: this.props.match.params.lessonId } }}
      >
        {({ data, loading, error }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { lessons } = data;
          const lesson = lessons[0];
          return (
            <Query
              query={FETCH_TEST_QUESTION}
              variables={{
                filter: { _id: this.props.match.params.questionId }
              }}
            >
              {({ loading, error, data }) => {
                if (loading) return "Loading...";
                if (error) return `Error! ${error.message}`;
                const { testQuestions } = data;
                const testQuestion = testQuestions[0];

                return (
                  <Mutation mutation={EDIT_TEST_QUESTION}>
                    {(mutation, { loading, error }) => {
                      const lessonId = this.props.match.params.lessonId;

                      return (
                        <EditTestQuestionForm
                          lessonId={lessonId}
                          subjectName={lesson.subject.name}
                          testQuestion={testQuestion}
                          onSubmit={values =>
                            this._handleEditTestQuestion(values, mutation)
                          }
                        />
                      );
                    }}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export { EditTestQuestionContainer };
