import React from "react";
import { Query, Mutation } from "react-apollo";
import styled from 'styled-components';
import ALL_USERS from "../../../graphql/queries/ALL_USERS";
import DELETE_USER from "../../../graphql/mutations/DELETE_USER";
import VERIFY_USER from '../../../graphql/mutations/VERIFY_USER';
import UsersScreen from "../../../screens/admin/UsersScreen";
import { Table, Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";

export class UsersContainer extends React.Component {
  state = {
    searchText: '',
  };

  _onDelete = async (id, mutation) => {
    console.log("deleting...", id);
    try {
      const { data } = await mutation({
        variables: { _id: id }
      });

      console.log("[Deleted teacher]: ", data);
    } catch (e) {
      console.log("Error deleting teacher: ", e);
    }
  };

  _onVerify = async (id, mutation) => {
    console.log("updating...", id, process.env.REACT_APP_VERIFICATION_CODE);
    try {
      const { data } = await mutation({
        variables: { verificationCode: process.env.REACT_APP_VERIFICATION_CODE, userId: id  }
      });

      console.log("[Verified teacher]: ", data);
    } catch (e) {
      console.log("Error verifying teacher: ", e);
    }
  }

  _filterOutAdmins = users =>
    users.filter(user => user.role && user.role.includes("teacher"));

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
          </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
          </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  render() {
    return (
      <Mutation
        mutation={DELETE_USER}
        update={(cache, { data: { deleteUser } }) => {
          const { users } = cache.readQuery({ query: ALL_USERS });
          cache.writeQuery({
            query: ALL_USERS,
            data: { users: users.filter(user => user._id !== deleteUser._id) }
          });
        }}
      >
        {(mutation, { loading, error }) => (
          <Mutation
            mutation={VERIFY_USER}
          >
          {(verifyMutation, { loading, error }) => (
            <Query query={ALL_USERS}>
              {({ loading, error, data }) => {
                if (loading) return "Loading...";
                if (error) console.log("[Error fetching users]: ", error);
                const { users } = data;

                return (
                  <UsersScreen
                    searchText={this.state.searchText}
                    onDelete={id => this._onDelete(id, mutation)}
                    data={this._filterOutAdmins(users)}
                    getColumnSearchProps={this.getColumnSearchProps}
                    onVerify={id => this._onVerify(id, verifyMutation)}
                  />
                );
              }}
            </Query>
          )}
          </Mutation>
        )}
      </Mutation>
    );
  }
}
