import gql from "graphql-tag";

export default gql`
  mutation createCompetencyTest($competencyTest: CompetencyTestInputNew!) {
    createCompetencyTest(competencyTest: $competencyTest) {
      _id
      lessonId
      teacherId
      score
      times_attempted
    }
  }
`;

