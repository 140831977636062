import styled from "styled-components";

const AuthModalStyles = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  justify-content: center;

  .auth-modal {
    padding: 24px;
    margin-top: 48px;
    width: 400px;
    max-height: 640px;
    overflow: scroll;
    background-color: #ffffff;
    box-shadow: rgba(34, 34, 34, 0.15) 0px 2px 20px 0px;
  }
`;

export { AuthModalStyles };
