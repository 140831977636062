import React from "react";
import { Row, Col, Modal, Button, Alert } from "antd";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table } from "antd";
import { Link } from "react-router-dom";
import TitleBar from "../components/common/TitleBar";
import Avatar from "../components/common/Avatar";
import KeyedList from "../components/common/KeyedList";
import { GreyH5 } from "../components/styled";
import { Tabs } from "antd";
import CardSection, {
  CardSectionTitle
} from "../components/common/CardSection";

import { Radio, Input } from "antd";

const RadioGroup = Radio.Group;

const QuestionSectionEl = styled.section`
  margin-bottom: 32px;
`;

const QuestionText = styled.h6`
  font-size: 1.8rem;
  color: #0a0a0a;
  font-weight: bold;
`;

const GreyedText = styled.span`
  color: #ababab;
`;

const ViewCompetencyTestScreen = ({
  data: test,
  history,
  onSelectAnswer,
  answers,
  onSubmitAnswers
}) => {
  console.log("test: ", test);

  const renderQuestion = (question, i) => {
    const radioStyle = {
      display: "block",
      height: "30px",
      lineHeight: "30px"
    };
    return (
      <QuestionSectionEl key={question._id}>
        <GreyH5>question {i + 1}</GreyH5>
        <QuestionText>{question.question}</QuestionText>
        <RadioGroup onChange={e => onSelectAnswer(question, e.target.value)}>
          <Radio style={radioStyle} value={"a"}>
            <GreyedText>Option A:</GreyedText> {`${question["a"]}`}
          </Radio>
          <Radio style={radioStyle} value={"b"}>
            <GreyedText>Option B:</GreyedText> {`${question["b"]}`}
          </Radio>
          <Radio style={radioStyle} value={"c"}>
            <GreyedText>Option C:</GreyedText> {`${question["c"]}`}
          </Radio>
          <Radio style={radioStyle} value={"d"}>
            <GreyedText>Option D:</GreyedText> {`${question["d"]}`}
          </Radio>
        </RadioGroup>
      </QuestionSectionEl>
    );
  };

  const totalNumberOfQuestions = Number(process.env.REACT_APP_TOTAL_QUESTIONS);
  const startPoint = test.times_attempted * totalNumberOfQuestions;
  const endPoint = startPoint + totalNumberOfQuestions;
  console.log("[cut at]:", startPoint, endPoint);
  const selectedCompetencyTestQuestions = test.lesson.competentcy_test_questions.slice(
    startPoint,
    endPoint
  );
  const totalNumberOfRetries = process.env.REACT_APP_TOTAL_RETRIES_COUNT;

  const onSubmitCallback = test => {
    console.log("submitcallback called");
    Modal.warning({
      title: "You did not pass this test",
      content: `You scored a total of ${
        test.score
      } out of ${totalNumberOfQuestions}. You may try again immediately with a new set of questions, however we advise you to take a break and try again at a later time as you have only ${totalNumberOfRetries -
        test.times_attempted} retries left`,
      onOk() {}
    });
  };

  console.log("[Questions]:", selectedCompetencyTestQuestions);

  const userIsSuspended = Boolean(test.date_suspended);
  const testHasBeenPassed = test.is_passed;

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title={`"${test.lesson.name}" Competency Test`}
            explainerText={`Please select the correct answer for each question below. You have ${totalNumberOfQuestions} questions and must score at least 60% in order to be eligible to teach this lesson.`}
          />
          <section>
            <p>
              You have taken this test {`${test.times_attempted}`} times out of{" "}
              {totalNumberOfRetries} attempts
            </p>
            {test.score ? (
              <React.Fragment>
                <p>
                  Your last score was {`${test.score}`} out of{" "}
                  {totalNumberOfQuestions}
                </p>
                <p>
                  You last took this test on{" "}
                  {moment(test.updated_at).format("MMM Do YYYY")} at{" "}
                  {moment(test.updated_at).format("hh:mm:a")}
                </p>
              </React.Fragment>
            ) : null}
          </section>
          <Row gutter={12}>
            <Col xs={24}>
              {testHasBeenPassed ? (
                <CardSection>
                  <Alert
                    message="This test has been passed!"
                    description="You can now access the content in the lessons screen. Click below to view lesson content."
                    type="info"
                    showIcon
                  />
                  <div style={{ marginTop: 24, textAlign: "center" }}>
                    <Button
                      type="primary"
                      onClick={() =>
                        history.push(`/main/lesson/${test.lesson._id}`)
                      }
                    >
                      View Lesson
                    </Button>
                  </div>
                </CardSection>
              ) : userIsSuspended ? (
                <CardSection>
                  <Alert
                    message="Maximum attempts exceeded!"
                    description="You have exceeded the maximum number of attempts to pass this test (3 tries). Please contact an administrator for next steps"
                    type="error"
                    showIcon
                  />
                  <div style={{ marginTop: 24, textAlign: "center" }}>
                    <Button type="danger" onClick={() => {}}>
                      Contact Administrator
                    </Button>
                  </div>
                </CardSection>
              ) : (
                <CardSection>
                  <h3 style={{ textAlign: "center" }}>
                    Test Attempt {`${test.times_attempted + 1}`}
                  </h3>
                  {selectedCompetencyTestQuestions.map(renderQuestion)}
                  <Button onClick={() => onSubmitAnswers(onSubmitCallback)}>
                    Submit Answers
                  </Button>
                </CardSection>
              )}
            </Col>
          </Row>
        </div>
      )}
    </Media>
  );
};

const ViewCompetencyTestScreenWithRouter = withRouter(ViewCompetencyTestScreen);
export default ViewCompetencyTestScreenWithRouter;
