import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";

// ACTUAL KeyedList Component
const KeyedList = ({ data, keys, overflowOnMobile }) => {
  return (
    <KeyedListDiv overflowOnMobile={overflowOnMobile}>
      {keys.map(renderListItem(data))}
    </KeyedListDiv>
  );
};

// Helper functions to render data and fetch nested objects in data
const fetchNestedObjectData = (key, obj) =>
  key.split(".").reduce(
    (obj, nestedKey) => {
      return obj && obj[nestedKey];
    },
    { ...obj }
  );

const renderListItem = dataObject => ({ key, label, suffix, render }, i) => {
  let value = key.includes(".")
    ? fetchNestedObjectData(key, dataObject)
    : dataObject[key];

  // if it's a valid date format it
  if (value && moment(value).isValid() && typeof value != "number")
    value = moment(value).format("MMM Do YY");
  return (
    <div className="keyed-list-item" key={i}>
      <h5 className="keyed-list-label">{label}:</h5>
      <p>
        {render ? render(value) : value}
        {`${suffix ? " " + suffix : ""}`}
      </p>
    </div>
  );
};

// Styling
const KeyedListDiv = styled.div`
  .keyed-list-item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #eee;
    padding: 8px 0;
    align-items: center;

    &:first-of-type {
      border-top: none;
    }

    &:last-child {
      border-bottom: 1px solid #eee;
    }

    h5.keyed-list-label {
      text-transform: uppercase;
      color: #9e9e9e;
      margin: 0;
      margin-right: 12px;
    }

    p {
      margin: 0;
    }
  }

  @media only screen and (max-width: 768px) {
    min-width: ${({ overflowOnMobile }) => overflowOnMobile && "100%"};
    width: ${({ overflowOnMobile }) => overflowOnMobile && "max-content"};
  }
`;

export default KeyedList;
