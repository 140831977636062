import React from 'react'
import { Query, Mutation } from 'react-apollo';
import ALL_SUBJECTS from '../../../graphql/queries/ALL_SUBJECTS';
import AddSchemeOfWorkForm from '../../../screens/forms/AddSchemeOfWorkForm';
import CREATE_SCHEME_OF_WORK from '../../../graphql/mutations/CREATE_SCHEME_OF_WORK'

export class AddSchemeOfWorkContainer extends React.Component {

  handleSchemeSubmit = async (values, mutation) => {

    try {
      await mutation({ variables: { schemeOfWork: values } });
      this.props.history.push("/main/lessons");

    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  render() {
    return (
      <div>
        <Query query={ALL_SUBJECTS}>
          {({ loading, error, data }) => {
            if (loading) return 'loading...';
            if (error) console.log('Got an error:', error);
            return (
              <Mutation mutation={CREATE_SCHEME_OF_WORK}>
              {(mutation, { loading, error }) => {
                return (
              <AddSchemeOfWorkForm 
                subjects={data.subjects}
                onSubmit={values => this.handleSchemeSubmit(values, mutation)}
                />
                )}}
              </Mutation>
             )
          }}
        </Query>
      </div>
    )
  }
}
