import React from "react";
import { Row, Col, Button, Card, Icon } from "antd";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table } from "antd";
import { Link } from "react-router-dom";
import TitleBar from "../components/common/TitleBar";
import Avatar from "../components/common/Avatar";
import KeyedList from "../components/common/KeyedList";
import { Tabs } from "antd";
import CardSection, {
  CardSectionTitle
} from "../components/common/CardSection";
import ReportOverviewWidget from "../components/common/ReportOverviewWidget";

const TabPane = Tabs.TabPane;

const FlexedDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
`;

const Div = styled.div`
  .sub-section-header {
    margin-bottom: 20px;
    margin-top: 30px;
    &:first-of-type {
      margin-top: 0;
    }
  }
  .section-subtitle {
    text-transform: uppercase;
  }

  .section-text {
    font-size: 90%;
    margin-bottom: 0px;
  }
`;

const ViewDailyReportScreen = ({ data: dailyReport, history }) => {
  console.log("[View Daily Report screen]", dailyReport);

  const { student, classroom } = dailyReport;

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <Div>
          <TitleBar
            title="Daily Report"
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
          />
          <Row gutter={12}>
            <Col xs={24} md={7}>
              <CardSection>
                <Avatar style={{ margin: "0 auto" }} />
                <h2 className="card-section-title">{`${student.firstName} ${
                  student.lastName
                }`}</h2>
                <KeyedList
                  data={dailyReport}
                  keys={[
                    {
                      key: "total_score",
                      label: "Total Score",
                      suffix: "(out of 100)"
                    },
                    {
                      key: "percentage_score",
                      label: "Percentage Score",
                      render: value => {
                        return value.toFixed(2);
                      },
                      suffix: "%"
                    },
                    {
                      key: "score_group",
                      label: "score group",
                      render: value => value.split("_").join(" ")
                    },
                    { key: "date", label: "report date" },
                    { key: "created_at", label: "created on" },
                    { key: "updated_at", label: "last updated" }
                  ]}
                />
              </CardSection>
            </Col>
            <Col xs={24} md={17}>
              <CardSection>
                <FlexedDiv>
                  <h1>Report Details</h1>
                  <ReportOverviewWidget report={dailyReport} />
                </FlexedDiv>
                <KeyedList
                  overflowOnMobile
                  data={student}
                  keys={[
                    { key: "firstName", label: "Student First Name" },
                    { key: "lastName", label: "Student Last Name" }
                  ]}
                />
                <KeyedList
                  overflowOnMobile
                  data={classroom}
                  keys={[
                    { key: "name", label: "Classroom" },
                    { key: "subject.name", label: "Subject" }
                  ]}
                />
                <h3 style={{ margin: "25px 0" }}>Daily Report Breakdown</h3>
                <Card
                  style={{ marginBottom: 25 }}
                  title={`Assessment Score`}
                  actions={[
                    <Link to={`/main/edit-daily-report/${dailyReport._id}`}>
                      <Icon style={{ marginRight: 10 }} type="edit" />
                      Edit Report
                    </Link>
                  ]}
                >
                  <KeyedList
                    data={dailyReport.assessment_score}
                    keys={[
                      {
                        key: "task1_score",
                        label: "Task 1 Score"
                      },
                      {
                        key: "task2_score",
                        label: "Task 2 Score"
                      },
                      {
                        key: "task3_score",
                        label: "Task 3 Score"
                      },
                      {
                        key: "homework_score",
                        label: "Homework Score"
                      },
                      {
                        key: "total_score",
                        label: "Total Score",
                        suffix: "(out of 50)"
                      },
                      {
                        key: "percentage_score",
                        label: "Percentage Score",
                        render: value => {
                          return value.toFixed(2);
                        },
                        suffix: "%"
                      },
                      {
                        key: "score_group",
                        label: "score group",
                        render: value => value.split("_").join(" ")
                      }
                    ]}
                  />
                </Card>
                <Card
                  style={{ marginBottom: 25 }}
                  title={`Classroom Observation Score`}
                  actions={[
                    <Link to={`/main/edit-daily-report/${dailyReport._id}`}>
                      <Icon style={{ marginRight: 10 }} type="edit" />
                      Edit Report
                    </Link>
                  ]}
                >
                  <KeyedList
                    data={dailyReport.classroom_observation_score}
                    keys={[
                      {
                        key: "present",
                        label: "Present In Class"
                      },
                      {
                        key: "attentive",
                        label: "Listened Attentively"
                      },
                      {
                        key: "participated",
                        label: "Participated in Class"
                      },
                      {
                        key: "asked_questions",
                        label: "Asked Questions"
                      },
                      {
                        key: "answered_questions",
                        label: "Answered Questions"
                      },
                      {
                        key: "total_score",
                        label: "Total Score"
                      }
                    ]}
                  />
                </Card>
                <Card
                  style={{ marginBottom: 25 }}
                  title={`Group Evalutation Score`}
                  actions={[
                    <Link to={`/main/edit-daily-report/${dailyReport._id}`}>
                      <Icon style={{ marginRight: 10 }} type="edit" />
                      Edit Report
                    </Link>
                  ]}
                >
                  <div className="sub-section-header">
                    <h5 className="section-subtitle">Group Evaluation Score</h5>
                    <p className="section-text">
                      Group:{" "}
                      {
                        dailyReport.group_observation_score
                          .group_evaluation_score.group_name
                      }
                    </p>
                    <p className="section-text">
                      Activity:{" "}
                      {
                        dailyReport.group_observation_score
                          .group_evaluation_score.activity
                      }
                    </p>
                  </div>
                  <KeyedList
                    data={
                      dailyReport.group_observation_score.group_evaluation_score
                    }
                    keys={[
                      {
                        key: "agreed_on_plan",
                        label: "Agreed on Plan"
                      },
                      {
                        key: "participated",
                        label: "Participated in Activity"
                      },
                      {
                        key: "productive",
                        label: "Was Productive"
                      },
                      {
                        key: "respectful",
                        label: "Was Respectful of Other's Opinions"
                      },
                      {
                        key: "completed_assignment",
                        label: "Completed Assignment"
                      },
                      {
                        key: "total_score",
                        label: "Group Evaluation Score"
                      }
                    ]}
                  />
                  <div className="sub-section-header">
                    <h5 className="section-subtitle">
                      Group Member Evaluation Score
                    </h5>
                    <p className="section-text">
                      Group:{" "}
                      {
                        dailyReport.group_observation_score
                          .group_member_evaluation_score.group_name
                      }
                    </p>
                    <p className="section-text">
                      Activity:{" "}
                      {
                        dailyReport.group_observation_score
                          .group_member_evaluation_score.activity
                      }
                    </p>
                  </div>
                  <KeyedList
                    data={
                      dailyReport.group_observation_score
                        .group_member_evaluation_score
                    }
                    keys={[
                      {
                        key: "performed_assigned_roles",
                        label: "Performed Assigned Roles"
                      },
                      {
                        key: "understood_activity_purpose",
                        label: "Understood Activity Purpose"
                      },
                      {
                        key: "answered_questions",
                        label: "Answered Questions"
                      },
                      {
                        key: "listened_to_ideas",
                        label: "Listened to Ideas"
                      },
                      {
                        key: "gave_feedback",
                        label: "Gave Feedback"
                      },
                      {
                        key: "assisted_in_preparation",
                        label: "Assisted in Preperation"
                      },
                      {
                        key: "expressed_ideas",
                        label: "Expressed Ideas"
                      },
                      {
                        key: "willing_to_compormise",
                        label: "Willing To Compromise"
                      },
                      {
                        key: "accepted_feedback",
                        label: "Accepted Feedback"
                      },
                      {
                        key: "participated",
                        label: "Participated in Activity"
                      },
                      {
                        key: "total_score",
                        label: "Group Member Evaluation Score"
                      }
                    ]}
                  />
                  <KeyedList
                    data={dailyReport.group_observation_score}
                    keys={[
                      {
                        key: "total_score",
                        label: "Total Score"
                      }
                    ]}
                  />
                </Card>
              </CardSection>
            </Col>
          </Row>
        </Div>
      )}
    </Media>
  );
};

const ViewDailyReportScreenWithRouter = withRouter(ViewDailyReportScreen);
export default ViewDailyReportScreenWithRouter;
