import React from "react";
import Query from "react-apollo/Query";
import FETCH_STUDENT from "../../../graphql/queries/FETCH_STUDENTS";
import ViewStudentScreen from "../../../screens/admin/ViewStudentScreen";

class ViewStudentContainer extends React.Component {
  render() {
    return (
      <Query
        query={FETCH_STUDENT}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { students } = data;
          const student = students[0];
          return (
            <ViewStudentScreen
              onRowSelect={this._handleSelectRow}
              data={student}
            />
          );
        }}
      </Query>
    );
  }
}

export { ViewStudentContainer };
