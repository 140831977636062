import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Form, Icon, Input, Select } from 'antd';
import Button from '../../components/common/Button';
import Mutation from 'react-apollo/Mutation';
import CREATE_USER from '../../graphql/mutations/CREATE_USER';
import ALL_SCHOOLS from '../../graphql/queries/ALL_SCHOOLS';
import { persistUserAuthData } from '../../utils/persistStorage';
import idx from 'idx';
import { Link } from 'react-router-dom';

const FormItem = Form.Item;
const { Option } = Select;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class SignupScreen extends React.Component {
  state = {
    errors: [],
  };

  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = async (e, mutation) => {
    e.preventDefault();
    this.setState({ errors: [] }, () => {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
        }
        if (values.password === values.confirmPassword) {
          console.log('the values', values);
          const result = await mutation({
            variables: { user: values },
          });

          console.log('mutated: ', result);
          const { data } = result;

          // retrieve token, persist it and route back to home
          const { token, user, errors } = data.signup;

          if (errors) {
            errors.map(error => {
              this.setState({
                errors: this.state.errors.concat([
                  {
                    name: error.name,
                    message: error.message,
                  },
                ]),
              });
            });
          } else {
            persistUserAuthData(token);
            this._handleNextRoute();
          }
        } else {
          // TODO: form error for password mismatch
          this.setState({
            errors: this.state.errors.concat([
              {
                message: 'Passwords do not match',
              },
            ]),
          });
        }
      });
    });
  };

  _handleNextRoute = () =>
    this.props.history.push('/auth/request_verification');

  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;

    console.log('we have errors here: ', this.state.errors);
    const formHasErrors = !!this.state.errors.length;

    return (
      <Mutation mutation={CREATE_USER}>
        {(mutation, { loading, error, data }) => {
          return (
            <React.Fragment>
              <Form onSubmit={e => this.handleSubmit(e, mutation)}>
                {/* <Query query={ALL_SCHOOLS}>
                  {({ loading, data, error }) => {
                    if (error) console.log("[Error fetching schools]: ", error);
                    const { schools } = data;
                    return (
                      <FormItem label='School'>
                        {getFieldDecorator('schoolId', {
                          rules: [
                            {
                              required: true,
                              message: 'Please select your school',
                            }
                          ]
                        })(
                          <Select
                            placeholder='Select an school'
                            defaultActiveFirstOption
                          >
                            {schools &&
                              schools.map(school=> (
                                <Option key={school._id} value={school._id}>
                                  {school.name}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </FormItem>
                    )
                  }}
                </Query> */}
                <FormItem>
                  {getFieldDecorator('firstName', {
                    rules: [
                      {
                        required: true,
                        message: 'Enter your first name',
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="First Name"
                    />,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('lastName', {
                    rules: [
                      {
                        required: true,
                        message: 'Enter your last name',
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="user"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="Last Name"
                    />,
                  )}
                </FormItem>
                <FormItem label="Gender">
                  {getFieldDecorator('gender', {
                    rules: [
                      {
                        required: true,
                        message: 'Select your Gender',
                      },
                    ],
                  })(
                    <Select defaultActiveFirstOption>
                      <Option value={'male'}>Male</Option>
                      <Option value={'female'}>Female</Option>
                    </Select>,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [
                      {
                        required: true,
                        message: 'Enter your email',
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="mail"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="Email"
                    />,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('phone', {
                    rules: [
                      {
                        required: true,
                        message: 'Enter your phone number',
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="phone"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      placeholder="Phone"
                    />,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [
                      {
                        required: true,
                        message: 'Please input your password!',
                      },
                      {
                        validator: this.validateToNextPassword,
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      type="password"
                      placeholder="Password"
                    />,
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('confirmPassword', {
                    rules: [
                      {
                        required: true,
                        message: 'Please confirm your password!',
                      },
                      {
                        validator: this.compareToFirstPassword,
                      },
                    ],
                  })(
                    <Input
                      prefix={
                        <Icon
                          type="lock"
                          style={{ color: 'rgba(0,0,0,.25)' }}
                        />
                      }
                      type="password"
                      placeholder="Confirm Password"
                      onBlur={this.handleConfirmBlur}
                    />,
                  )}
                </FormItem>
                <FormItem>
                  {formHasErrors && (
                    <div>
                      {this.state.errors.map((error, i) => (
                        <small className="error-message" key={i}>
                          {error.message}
                        </small>
                      ))}
                    </div>
                  )}
                  <Button
                    disabled={hasErrors(getFieldsError())}
                    block
                    type="primary"
                    htmlType="submit"
                    text="Sign Up"
                    buttonColor="yellow"
                  />
                </FormItem>
              </Form>
              <div style={{ textAlign: 'center', fontSize: '89%' }}>
                <Link className="auth-small-link" to="/auth/login">
                  Log In
                </Link>
              </div>
            </React.Fragment>
          );
        }}
      </Mutation>
    );
  }
}

const WrappedSignupScreen = Form.create()(SignupScreen);

export default WrappedSignupScreen;
