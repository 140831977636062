import gql from "graphql-tag";

export default gql`
  mutation editUser($_id: String!, $user: UserInputEdit!) {
    editUser(_id: $_id, user: $user) {
      _id
      firstName
      lastName
    }
  }
`;
