import gql from 'graphql-tag';

export default gql`
  {
    schools {
      _id
      name
      local_govt_area
      state
      address
      city
      code
      created_at
      updated_at
      deleted_at
    }
  }
`;
