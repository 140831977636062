import React from "react";
import Query from "react-apollo/Query";
import FETCH_COMPETENCY_TESTS from "../../../graphql/queries/FETCH_COMPETENCY_TESTS";
import ViewCompetencyTestDetailsScreen from "../../../screens/admin/ViewCompetencyTestDetailsScreen";

class ViewCompetencyTestDetailsContainer extends React.Component {
  render() {
    return (
      <Query
        query={FETCH_COMPETENCY_TESTS}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          const { competencytests } = data;
          const competencytest = competencytests[0];
          console.log("console: ", competencytest);
          return <ViewCompetencyTestDetailsScreen data={competencytest} />;
        }}
      </Query>
    );
  }
}

// TODO: tomorrow
//  - fetch a weekly report ...
// - display view to the user
// - allow user to view/edit each daily report

export { ViewCompetencyTestDetailsContainer };
