import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const AvatarDiv = styled.div`
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  border-radius: 50%;
  background-color: #d6d6d6;
`;

const Avatar = ({ imageSrc, size, style }) => {
  if (imageSrc) {
    const backgroundImageStyles = {
      backgroundImage: `url(${imageSrc})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center"
    };
    style = { ...style, backgroundImageStyles };
  }

  return <AvatarDiv style={style} size={size} />;
};

Avatar.propTypes = {
  imageSrc: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.number.isRequired
};

Avatar.defaultProps = {
  size: 100
};

export default Avatar;
