import gql from "graphql-tag";

export default gql`
  mutation editStudent($_id: String!, $student: StudentInputEdit!) {
    editStudent(_id: $_id, student: $student) {
      _id
      firstName
      lastName
    }
  }
`;
