import React from "react";
import Query from "react-apollo/Query";
import FETCH_CLASSROOM from "../../../graphql/queries/FETCH_CLASSROOM";
import FETCH_STUDENTS from "../../../graphql/queries/FETCH_STUDENTS";
import ViewClassroomScreen from "../../../screens/admin/ViewClassroomScreen";

class ViewClassroomContainer extends React.Component {
  _handleSelectRow = (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  };

  render() {
    return (
      <Query
        query={FETCH_CLASSROOM}
        variables={{ filter: { _id: this.props.match.params.id } }}
      >
        {({ loading, error, data }) => {
          if (loading) return "Loading...";
          if (error) return `Error! ${error.message}`;
          console.log("WTF: ", data);
          const { classrooms } = data;
          const classroom = classrooms[0];
          return (
            <Query
              query={FETCH_STUDENTS}
              variables={{
                filter: { classroomId: this.props.match.params.id }
              }}
            >
              {({ loading, error, data: studentData }) => {
                if (loading) return "Loading...";
                if (error) return `Error! ${error.message}`;
                const { students } = studentData;
                return (
                  <ViewClassroomScreen
                    onRowSelect={this._handleSelectRow}
                    data={classroom}
                    students={students}
                  />
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export { ViewClassroomContainer };
