import {
  HomeContainer,
  ClassroomContainer,
  StudentsContainer,
  CoursesContainer,
  ContentContainer,
  AddClassroomContainer,
  ViewClassroomContainer,
  AddStudentContainer,
  EditStudentContainer,
  ViewStudentContainer,
  LessonsContainer,
  ViewLessonContainer,
  CompetencyTestsContainer,
  ViewCompetencyTestContainer,
  ReportsContainer,
  AddDailyReportContainer,
  ViewWeeklyReportContainer,
  ViewDailyReportContainer,
  SchemeOfWorkContainer,
  ViewSchemeOfWorkContainer,
} from "../../containers/MainContent/DashboardContainers";

// There are two types of routes. The ones that should show up in the sidebar and the ones that do not.
// For the ones that show up we add a `name` and `iconType` property

const dashboardRoutes = [
  {
    name: "Home",
    iconType: "home",
    path: "/main/home",
    component: HomeContainer
  },
  {
    name: "Classrooms",
    iconType: "book",
    path: "/main/classrooms",
    component: ClassroomContainer
  },
  {
    path: "/main/classroom/:id",
    component: ViewClassroomContainer
  },
  {
    path: "/main/add-classroom",
    component: AddClassroomContainer
  },
  {
    path: "/main/add-student",
    component: AddStudentContainer
  },
  {
    path: "/main/edit-student/:id",
    component: EditStudentContainer
  },
  {
    name: "Students",
    iconType: "team",
    path: "/main/students",
    component: StudentsContainer
  },
  {
    path: "/main/student/:id/:classId",
    component: ViewStudentContainer
  },
  {
    name: "Lessons",
    iconType: "book",
    path: "/main/lessons/",
    component: LessonsContainer
  },
  {
    path: "/main/lesson/:id",
    component: ViewLessonContainer
  },
  {
    name: "Lesson Tests",
    iconType: "book",
    path: "/main/tests",
    component: CompetencyTestsContainer
  },
  {
    path: "/main/test/:id",
    component: ViewCompetencyTestContainer
  },
  {
    name: "Create Reports",
    iconType: "book",
    path: "/main/reports",
    component: ReportsContainer
  },
  {
    path: "/main/create-daily-report/:id",
    component: AddDailyReportContainer
  },
  {
    path: "/main/daily-report/:id",
    component: ViewDailyReportContainer
  },
  {
    path: "/main/weekly-report/:id",
    component: ViewWeeklyReportContainer
  },
  {
    name: "Scheme Of Work",
    iconType: "book",
    path: "/main/all-scheme-of-work",
    component: SchemeOfWorkContainer
  },
  {
    path: '/main/scheme-of-work/:id',
    component: ViewSchemeOfWorkContainer
  },
  {
    redirect: true,
    getRedirectPath: user => {
      if (user.verified) {
        // if (user.lessons && !user.lessons.length) {
        //   return "/main/lessons";
        // }
        return "/main/home";
      } else {
        return "/auth/login?verified=false";
      }
    }
  }
];

export { dashboardRoutes };
