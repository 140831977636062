import React from "react";
import { Row, Col, Button, Alert } from "antd";
import Media from "react-media";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Table, Divider } from "antd";
import { Link } from "react-router-dom";
import TitleBar from "../../components/common/TitleBar";
import Avatar from "../../components/common/Avatar";
import KeyedList from "../../components/common/KeyedList";
import { Tabs } from "antd";
import CardSection, {
  CardSectionTitle
} from "../../components/common/CardSection";
import DisplayList from "../../components/common/DisplayList";
import { Video } from "cloudinary-react";

const TabPane = Tabs.TabPane;

const DisplayListWrapper = styled.div`
  padding: 48px 0;
`;

const ViewLessonScreen = ({
  data,
  history,
  onRowSelect,
  onDeleteTestQuestion,
  onDeleteLessonActivity
}) => {
  console.log("[Lesson]:", data);
  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title={"Lesson Details"}
            style={{
              display: `${matches ? "block" : "flex"}`,
              justifyContent: "space-between"
            }}
            buttons={[
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="file-add"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() =>
                  history.push(`/main/admin/add-question/${data._id}`)
                }
              >
                Add Competency Test Question
              </Button>,
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="file-add"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() =>
                  history.push(`/main/admin/add-lesson-activity/${data._id}`)
                }
              >
                Add Lesson Activity
              </Button>,
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="file"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() =>
                  history.push(`/main/admin/edit-lesson/${data._id}`)
                }
              >
                Edit Lesson
              </Button>
            ]}
          />
          <Row gutter={12}>
            <Col xs={24} md={7}>
              <CardSection>
                <Avatar style={{ margin: "0 auto" }} />
                <h2 className="card-section-title">{data.name}</h2>
                <KeyedList
                  data={data}
                  keys={[
                    { key: "classroomLevel", label: "school level" },
                    { key: "subject.name", label: "subject name" },
                    { key: "created_at", label: "created on" },
                    { key: "key_terms", label: "key terms" }
                  ]}
                />
              </CardSection>
            </Col>
            <Col xs={24} md={17}>
              <CardSection>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Lesson Information" key="1">
                    <p>Lesson plans are building blocks of a subject scheme</p>
                    <KeyedList
                      data={data}
                      keys={[
                        { key: "name", label: "name" },
                        { key: "classroomLevel", label: "school level" },
                        { key: "subject.name", label: "subject name" },
                        { key: "created_at", label: "created on" },
                        {
                          key: "total_activity_time",
                          label: "total activity time",
                          suffix: "minutes"
                        }
                      ]}
                    />
                    <DisplayListWrapper>
                      <DisplayList title="Content" data={data.content} />
                      <DisplayList title="Goals" data={data.goals} />
                      <DisplayList title="Objectives" data={data.objectives} />
                      <DisplayList title="Materials" data={data.materials} />
                      <DisplayList title="printables" data={data.printables} />
                      <DisplayList
                        title="suggested resources"
                        data={data.suggested_resources}
                      />
                      <DisplayList
                        title="Introduction"
                        data={data.introduction}
                      />
                      <DisplayList
                        title="development"
                        data={data.development}
                      />
                      <DisplayList title="practice" data={data.practice} />
                      <DisplayList
                        title="checks for understanding"
                        data={data.checks_for_understanding}
                      />
                      <DisplayList title="closure" data={data.closure} />
                      <DisplayList title="homework" data={data.homework} />
                      <DisplayList title="evaluation" data={data.evaluation} />
                      {data.publicId && <DisplayList
                        title="lesson video"
                        render={() => (
                          <Video
                            controls
                            cloudName={process.env.REACT_APP_CLOUD_NAME}
                            publicId={data.publicId}
                          />
                        )}
                      />}
                    </DisplayListWrapper>
                  </TabPane>
                  <TabPane tab="Lesson Activities" key="2">
                    {!data.lesson_activities.length && (
                      <Alert
                        banner
                        message="No Activites!"
                        description="No activities yet for this lesson"
                        type="info"
                        showIcon
                      />
                    )}
                    {data.lesson_activities &&
                      data.lesson_activities.map(activity => {
                        return (
                          <React.Fragment key={activity._id}>
                            <DisplayListWrapper>
                              <h3 style={{ position: "relative" }}>
                                Activity Name: {activity.name}
                                <div
                                  style={{
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                    fontSize: "small"
                                  }}
                                >
                                  <Link
                                    to={`/main/admin/edit-lesson-activity/${
                                      data._id
                                      }/${activity._id}`}
                                  >
                                    Edit
                                  </Link>
                                  <Divider type="vertical" />
                                  <a
                                    href="javascript:;"
                                    style={{ color: "red" }}
                                    onClick={() =>
                                      onDeleteLessonActivity(activity._id)
                                    }
                                  >
                                    Delete
                                  </a>
                                </div>
                              </h3>
                              <DisplayList
                                title="Description"
                                data={activity.description}
                              />
                              <DisplayList
                                title="Materials"
                                data={activity.materials}
                              />
                              <DisplayList
                                title="Instructions"
                                data={activity.instructions}
                              />
                              <hr />
                            </DisplayListWrapper>
                          </React.Fragment>
                        );
                      })}
                  </TabPane>
                  <TabPane tab="Competency Test Questions" key="3">
                    {data.competentcy_test_questions.map((testQuestion, i) => {
                      const {
                        question,
                        a,
                        b,
                        c,
                        d,
                        correct_answer,
                        _id
                      } = testQuestion;
                      return (
                        <div
                          style={{
                            marginBottom: 24,
                            paddingBottom: 12,
                            borderBottom: "1px solid #dcdcdc",
                            position: "relative"
                          }}
                          key={_id}
                        >
                          <h3>
                            {i + 1}. {question}
                          </h3>
                          <p>Option A: {a}</p>
                          <p>Option B: {b}</p>
                          <p>Option C: {c}</p>
                          <p>Option D: {d}</p>
                          <small>
                            <b>Correct Answer:</b> Option {correct_answer} (
                            {testQuestion[correct_answer]})
                          </small>
                          <div
                            style={{
                              position: "absolute",
                              right: 0,
                              bottom: 3
                            }}
                          >
                            <Link
                              to={`/main/admin/edit-question/${
                                data._id
                                }/${_id}`}
                            >
                              Edit
                            </Link>
                            <Divider type="vertical" />
                            <a
                              href="javascript:;"
                              style={{ color: "red" }}
                              onClick={() => onDeleteTestQuestion(_id)}
                            >
                              Delete
                            </a>
                          </div>
                        </div>
                      );
                    })}
                  </TabPane>
                </Tabs>
              </CardSection>
            </Col>
          </Row>
        </div>
      )}
    </Media>
  );
};

const ViewLessonScreenWithRouter = withRouter(ViewLessonScreen);
export default ViewLessonScreenWithRouter;
