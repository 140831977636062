import React from "react";
import { Query, Mutation } from "react-apollo";
import ALL_COMPETENCY_TESTS from "../../../graphql/queries/ALL_COMPETENCY_TESTS";
import ALL_LESSONS from "../../../graphql/queries/ALL_LESSONS";
import REOPEN_COMPETENCY_TEST from "../../../graphql/mutations/REOPEN_COMPETENCY_TEST";
import CompetencyTestsScreen from "../../../screens/admin/CompetencyTestsScreen";
import { Table, Input, Button, Icon } from "antd";
import Highlighter from "react-highlight-words";

export class CompetencyTestsContainer extends React.Component {
  state = {
    filteredInfo: null,
    sortedInfo: null,
    showLessonSelectionModal: false,
    searchText: ""
  };

  _clearFilters = () => {
    this.setState({ filteredInfo: null });
  };

  _handleFilterChange = (name, value) => {
    const { filteredInfo } = this.state;

    this.setState({
      filteredInfo: {
        ...filteredInfo,
        [name]: typeof value == "undefined" ? null : [value.toString()]
      }
    });
  };

  _handleTableChange = (pagination, filters, sorter) => {
    console.log("Various parameters", pagination, filters, sorter);
    this.setState({
      filteredInfo: filters,
      sortedInfo: sorter
    });
  };

  _reopenTest = async (id, mutation) => {
    try {
      await mutation({ variables: { _id: id } });
    } catch (e) {
      console.log("Error signing up: ", e);
    }
  };

  updateCache = (cache, { data: { competencyTest } }) => {
    const { competencytests } = cache.readQuery({
      query: ALL_COMPETENCY_TESTS
    });
    cache.writeQuery({
      query: ALL_COMPETENCY_TESTS,
      data: {
        competencytests: competencytests.map(test => {
          if (test._id == competencyTest._id) {
            return { ...test, ...competencyTest };
          }

          return test;
        })
      }
    });
  };

  onSelectLesson = lesson => () => {
    this.props.history.push(`/main/admin/add-question/${lesson._id}`);
  };

  showLessonSelectionModal = () => {
    this.setState({ showLessonSelectionModal: true });
  };

  closeLessonSelectionModal = () =>
    this.setState({ showLessonSelectionModal: false });

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      <Icon type="search" style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      switch (dataIndex) {
        case "teacher.firstName":
          console.log("RECORD: ", record, dataIndex);
          const fullName = `${record.teacher &&
            record.teacher.firstName} ${record.teacher &&
            record.teacher.lastName}`;
          return fullName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());

        case "lesson.name":
          const lessonName = `${record.lesson && record.lesson.name}`;
          return lessonName
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
          break;
      }
    },
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text => (
      <Highlighter
        highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ""}
      />
    )
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  render() {
    let { sortedInfo, filteredInfo, searchText } = this.state;
    console.log("filteredInfo", filteredInfo);
    sortedInfo = sortedInfo || {};
    filteredInfo = filteredInfo || {};

    return (
      <Mutation mutation={REOPEN_COMPETENCY_TEST} update={this.updateCache}>
        {(mutation, { loading, error }) => (
          <Query query={ALL_LESSONS}>
            {({ loading, error, data }) => {
              if (loading) return "Loading...";
              if (error) console.log("[Error fetching lessons]: ", error);
              const { lessons } = data;
              return (
                <Query query={ALL_COMPETENCY_TESTS}>
                  {({ loading, error, data }) => {
                    if (loading) return "Loading...";
                    if (error) console.log("[Error fetching tests]: ", error);
                    const { competencytests } = data;
                    return (
                      <CompetencyTestsScreen
                        searchText={searchText}
                        getColumnSearchProps={this.getColumnSearchProps}
                        tableOptions={{
                          sortedInfo,
                          filteredInfo,
                          handleTableChange: this._handleTableChange,
                          handleFilterChange: this._handleFilterChange,
                          clearFilters: this._clearFilters
                        }}
                        lessons={lessons}
                        onCloseModal={this.closeLessonSelectionModal}
                        onAddCompetencyTest={this.showLessonSelectionModal}
                        showLessonSelectionModal={
                          this.state.showLessonSelectionModal
                        }
                        data={competencytests}
                        onSelectLesson={this.onSelectLesson}
                        onReopenTest={id => this._reopenTest(id, mutation)}
                      />
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        )}
      </Mutation>
    );
  }
}
