import React from 'react';
import HomeScreen from '../../../screens/admin/HomeScreen';
import DASHBOARD_QUERY from '../../../graphql/queries/DASHBOARD_QUERY';
import { UserConsumer } from '../../../App';
import { Query } from 'react-apollo';

export class HomeContainer extends React.Component {
  state = {
    showSchoolSelectionModal: false,
  };

  _openSelectSchoolModal = () =>
    this.setState({ showSchoolSelectionModal: true });

  _closeSchoolSelectModal = () =>
    this.setState({ showSchoolSelectionModal: false });

  render() {
    const { showSchoolSelectionModal } = this.state;
    const getStudentsDataFromClassrooms = classrooms => {
      const allStudents = classrooms.reduce((totalStudents, classroom) => {
        return totalStudents.concat(classroom.students);
      }, []);
      const numberOfStudents = allStudents.length;
      return { allStudents, numberOfStudents };
    };

    return (
      <UserConsumer>
        {user => {
          return (
            <Query query={DASHBOARD_QUERY}>
              {({ loading, error, data }) => {
                if (loading) return 'Loading...';
                if (error)
                  console.log('[Error fetching dashboard data]: ', error);

                return (
                  <HomeScreen
                    showSchoolSelectionModal={showSchoolSelectionModal}
                    onOpenSelectSchoolModal={this._openSelectSchoolModal}
                    onCloseSelectSchoolModal={this._closeSchoolSelectModal}
                    user={user}
                    {...data}
                  />
                );
              }}
            </Query>
          );
        }}
      </UserConsumer>
    );
  }
}
