import React from 'react';
import { Mutation } from 'react-apollo';
import { Modal, Form, Input, Button } from 'antd';
import { UserConsumer } from '../../../App';
import ADD_SCHOOL_CODE from '../../../graphql/mutations/ADD_SCHOOL_CODE';

class AddSchoolCodeContainer extends React.Component {
  state = {
    errorMessage: '',
    loading: false,
  };

  componentDidMount() {
    // To disable submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = (e, user, mutation) => {
    this.setState({ errorMessage: '', loading: true });
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        const { code } = values;

        try {
          await mutation({
            variables: {
              code: code.toUpperCase(),
              userId: user._id,
            },
          });
          this.setState({ loading: false });
          this.props.onCloseSelectSchoolModal();
        } catch (e) {
          // console.log('Error signing up: ', e.graphQLErrors);
          const gqlError = e.graphQLErrors[0];
          const errorData = gqlError && gqlError.data;
          const errorMessage =
            (errorData && errorData.message) ||
            'An error occurred, please contact your head teacher or admin';

          this.setState({
            errorMessage,
            loading: false,
          });
        }
      }
    });
  };

  render() {
    const { isVisible, onCloseSelectSchoolModal } = this.props;
    const {
      getFieldDecorator,
      getFieldsError,
      getFieldError,
      isFieldTouched,
    } = this.props.form;

    const { errorMessage, loading } = this.state;

    function hasErrors(fieldsError) {
      return Object.keys(fieldsError).some(field => fieldsError[field]);
    }

    return (
      <Mutation mutation={ADD_SCHOOL_CODE}>
        {(mutation, { loading, error }) => {
          return (
            <UserConsumer>
              {user => {
                return (
                  <Modal
                    title="Enter your school code"
                    visible={isVisible}
                    footer={null}
                    onCancel={onCloseSelectSchoolModal}
                  >
                    <p>
                      Enter the school code provided by your Head Teacher or
                      Administrator
                    </p>
                    <small>Please include all the dashes and letters</small>
                    <div>
                      <Form
                        layout="inline"
                        onSubmit={e => this.handleSubmit(e, user, mutation)}
                      >
                        <Form.Item>
                          {getFieldDecorator('code', {
                            rules: [
                              {
                                required: true,
                                message: 'Please input your school code!',
                              },
                            ],
                          })(<Input placeholder="School code" />)}
                        </Form.Item>
                        <Form.Item>
                          <Button
                            type="primary"
                            htmlType="submit"
                            disabled={hasErrors(getFieldsError())}
                            loading={loading}
                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                      <div>
                        {errorMessage && (
                          <small style={{ color: 'red' }}>{errorMessage}</small>
                        )}
                      </div>
                    </div>
                  </Modal>
                );
              }}
            </UserConsumer>
          );
        }}
      </Mutation>
    );
  }
}

const WrappedAddSchoolCodeContainer = Form.create({
  name: 'AddSchoolCodeContainer',
})(AddSchoolCodeContainer);

export default WrappedAddSchoolCodeContainer;
