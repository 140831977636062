import React from "react";
import moment from "moment";
import { Table, Button, Divider, Modal } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import TitleBar from "../../components/common/TitleBar";
import Media from "react-media";
import Highlighter from "react-highlight-words";

const { confirm } = Modal;

const UsersScreen = ({
  data,
  rowSelection,
  history,
  onDelete,
  getColumnSearchProps,
  searchText,
  onVerify }) => {

  const capitalize = word => `${word[0].toUpperCase()}${word.slice(1)}`;
  const showDeleteConfirm = (recordId, name) => {
    confirm({
      title: `Are you sure you want to delete ${name}`,
      okType: 'danger',
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        onDelete(recordId);
      },
    });
  }

  const showVerifyConfirm = (recordId, name) => {
    confirm({
      title: `Are you sure you want to verify ${name}?`,
      okText: 'Yes',
      cancelText: 'No',
      onOk() {
        onVerify(recordId);
      },
    });
  }

  const columns = [
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
      ...getColumnSearchProps('firstName'),
      render: (text, record) => (
        <Link to={`/main/admin/user/${record._id}`}>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={`${record.firstName}`}
          >
            {text}
          </Highlighter>
        </Link>
      )
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
      ...getColumnSearchProps('lastName'),
      render: (text, record) => (
        <Link to={`/main/admin/user/${record._id}`}>
          <Highlighter
            highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={`${record.lastName}`}
          >
            {text}
          </Highlighter>
        </Link>
      )
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone"
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: text => {
        return text.includes("_")
          ? text
            .split("_")
            .map(eachWord => capitalize(eachWord))
            .join(" ")
          : capitalize(text);
      }
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender"
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email"
    },
    {
      title: "Verified",
      dataIndex: "verified",
      key: "verified",
      render: text => (text ? "yes" : "no")
    },
    {
      title: "Joined",
      dataIndex: "created_at",
      key: "created_at",
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/main/admin/edit-user/${record._id}`}>Edit</Link>
          <Divider type="vertical" />
          <Link to={`/main/admin/reset-password/${record._id}`}>
            Reset Password
          </Link>
          <Divider type="vertical" />
          <a
            href="javascript:;"
            disabled={record.verified}
            onClick={() => {if (!record.verified) showVerifyConfirm(record._id, `${record.firstName} ${record.lastName}`)}}
            style={{opacity: record.verified ? 0 : 1}}
          >
            Verify Teacher
          </a>
          <Divider type="vertical" />
          <a
            href="javascript:;"
            onClick={() => showDeleteConfirm(record._id, `${record.firstName} ${record.lastName}`)}
          >
            Delete
          </a>
        </span>
      )
    }
  ];

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="All Teachers"
            buttons={[
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="usergroup-add"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() => history.push("/main/admin/add-user")}
              >
                Enroll Teacher
              </Button>
            ]}
          />
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            rowKey="_id"
            style={{ minWidth: 813 }}
          />
        </div>
      )}
    </Media>
  );
};

const UsersScreenWithRouter = withRouter(UsersScreen);
export default UsersScreenWithRouter;
