import gql from "graphql-tag";

export default gql`
  {
    currentUser {
      _id
      firstName
      lastName
      email
      phone
      verified
      isSuspended
      deletedAt
      role
      phone
      lessonIds
      school{
        _id
        name
      }
      classrooms {
        _id
        name
        classroomLevel
        subjectId
        subject {
          _id
          name
        }

        teacherId

        lessonIds
        lessons {
          _id
          name
        }

        studentIds
        students {
          _id
          firstName
          lastName
          gender
          weekly_reports {
            _id
            created_at
            subjectId
            startDate
            endDate
            total_score
            percentage_score
            score_group
          }
          daily_reports {
            _id
            created_at
            subjectId
            date
            lessonId
            assessment_score {
              total_score
            }
            classroom_observation_score {
              total_score
            }
            group_observation_score {
              total_score
            }
            total_score
            percentage_score
            score_group
          }
        }

        isActive
        daily_reports {
          _id
          created_at
          total_score
        }
        weekly_reports {
          _id
          created_at
          total_score
        }
      }
      lessons {
        _id
        name
      }
    }
  }
`;
