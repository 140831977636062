import gql from "graphql-tag";

export default gql`
  mutation createStudent($student: StudentInputNew!) {
    createStudent(student: $student) {
      _id
      firstName
      lastName
    }
  }
`;
