import React from "react";
import moment from "moment";
import { Table, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import TitleBar from "../../components/common/TitleBar";
import Media from "react-media";

const LessonsScreen = ({
  data,
  history,
  students,
  onRowSelect,
  onDelete,
  tableOptions,

  getColumnSearchProps,
  searchText
}) => {
  const {
    sortedInfo,
    filteredInfo,
    handleTableChange,
    handleFilterChange,
    clearFilters
  } = tableOptions;


  const columns = [
    {
      title: "Topic",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      render: (text, record) => (
        <Link to={`/main/admin/lesson/${record._id}`}>{text}</Link>
      )
    },
    {
      title: "Subject",
      dataIndex: "subject.name",
      key: "subject.name",
      filters: [
        {
          text: "Numeracy",
          value: "Numeracy"
        },
        {
          text: "Literacy",
          value: "Literacy"
        },
        {
          text: "Reading",
          value: "Reading"
        },
        {
          text: "Ethics",
          value: "Ethics"
        },
        {
          text: "Science",
          value: "Science"
        },
        {
          text: "History",
          value: "History"
        }
      ],
      onFilter: (value, record) => record.subject.name.indexOf(value) === 0,
      sorter: (a, b) => a.subject.name.length - b.subject.name.length,
      sortDirections: ["descend", "ascend"]
    },
    {
      title: "School Level",
      dataIndex: "classroomLevel",
      key: "classroomLevel"
    },
    {
      title: "Active",
      dataIndex: "isActive",
      key: "isActive",
      render: text => (text ? "yes" : "no")
    },
    {
      title: "Added",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      sortDirections: ["descend", "ascend"],
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <span>
          <Link to={`/main/admin/edit-lesson/${record._id}`}>Edit</Link>
          <Divider type="vertical" />
          <a href="javascript:;" onClick={() => onDelete(record._id)}>
            Delete
          </a>
        </span>
      )
    }
  ];

  const rowSelection = {
    onChange: onRowSelect,
    getCheckboxProps: record => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name
    })
  };

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="All Lessons"
            buttons={[
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="usergroup-add"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={() => history.push("/main/admin/add-lessons")}
              >
                Add Lesson
              </Button>
            ]}
          />
          <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            rowKey="_id"
            style={{ minWidth: 813 }}
          />
        </div>
      )}
    </Media>
  );
};

const LessonsScreenWithRouter = withRouter(LessonsScreen);
export default LessonsScreenWithRouter;
