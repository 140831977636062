import React from 'react'
import { Tabs, Row, Col } from 'antd';
import styled from 'styled-components';
import CardSection, { CardSectionTitle } from '../components/common/CardSection';
import DisplayList from '../components/common/DisplayList'
import KeyedList from '../components/common/KeyedList';

const { TabPane } = Tabs

const DayDiv = styled.div`
border: 1px solid #ccc;
margin-top: 10px
padding: 5px
`;

const HeaderText = styled.h5`
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 800;
  color: #3f7af6;
  margin-bottom: 12px;
`;

const days = [ 'day1', 'day2', 'day3', 'day4', 'day5' ]


const ViewSchemeOfWorkScreen = ({scheme}) => {
  const { term1, term2, term3 } = scheme;

    return (
      <CardSection>
        <CardSectionTitle>Scheme of work for {scheme.session}</CardSectionTitle>
        <Tabs>
          <TabPane tab="Overview" key="1">
          
          <Row gutter={8}>
            <Col xs={8} md={4}>
              <h3>Session: {scheme && scheme.session}</h3>
            </Col>
            <Col xs={8} md={4}>
              <h3>Subject: {scheme && scheme.subject.name}</h3>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col xs={24} md={8}>
            <KeyedList
              data={scheme}
              keys={[
                { key: "classroomLevel", label: "school level" },
                { key: "minAge", label: "min age" },
                { key: "maxAge", label: "max age" }
              ]}
            />
            </Col>
          </Row>
          <Row gutter={8} style={{ marginTop: 10 }}>
            <Col xs={24} md={24}>
              <DisplayList
                title="Objectives"
                data={scheme.objectives}
              />
            </Col>
          </Row>
          </TabPane>

          <TabPane tab="1st Term" key="2">
            {term1 && term1.weeks.map(week => (
              <div key={week.order} style={{marginTop: 10}}>
                <h3>Week {week.order}</h3>
                    <DisplayList
                      title="Topic"
                      data={week.topic}
                    />
                {days.map(day => (
                <DayDiv key={day}>
                  <h4>Day{week[day].order}</h4>
                  <Row gutter={8}>
                    <Col xs={24} md={8}>
                    {!week[day].lesson && <HeaderText>Lesson</HeaderText>}
                    {!week[day].lesson && <p>N/A</p>}
                    <DisplayList
                      title="Lesson"
                      data={week[day].lesson && week[day].lesson.name}
                    />
                    </Col>
                    <Col xs={24} md={16}>
                    <HeaderText>Keywords</HeaderText>
                    {week[day].keywords.map((word, i) => {
                      if (i === (week[day].keywords.length - 1)) {
                        return (<span key={i}>{word}</span>)
                      }
                      return (<span key={i}>{word}, </span>)
                    })}
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col xs={24} md={8}>
                      <DisplayList
                        title="Evaluation"
                        data={week[day].evaluation}
                      />
                    </Col>

                    <Col xs={24} md={16}>
                      <DisplayList
                        title="Objectives"
                        data={week[day].objectives}
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>

                    <Col xs={24} md={8}>
                      <DisplayList
                        title="Resources"
                        data={week[day].resources}
                      />
                    </Col>

                    <Col xs={24} md={8}>
                      <DisplayList
                        title="Assignment"
                        data={week[day].assignment}
                      />
                    </Col>

                    <Col xs={24} md={8}>
                      <DisplayList
                        title="Teacher Initiated Activities"
                        data={week[day].teacher_initiated_activities}
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col xs={24} md={8}>
                      <DisplayList
                        title="Teacher Led Activities (Warm Up)"
                        data={week[day].teacher_led_activity.warmUp}
                      />
                    </Col>

                    <Col xs={24} md={8}>
                      <DisplayList
                        title="Teacher Led Activities (Sub Topic)"
                        data={week[day].teacher_led_activity.subTopic}
                      />
                    </Col>

                    <Col xs={24} md={8}>
                      <DisplayList
                        title="Teacher Led Activities (Activity)"
                        data={week[day].teacher_led_activity.activity}
                      />
                    </Col>
                  </Row>
                </DayDiv>
                ))}
               
              </div>
            ))}
          </TabPane>

          <TabPane tab="2nd Term" key="3">
          {term2 && term2.weeks.map(week => (
            <div key={week.order} style={{marginTop: 10}}>
              <h3>Week {week.order}</h3>
                  <DisplayList
                    title="Topic"
                    data={week.topic}
                  />
              {days.map(day => (
              <DayDiv key={day}>
                <h4>Day{week[day].order}</h4>
                <Row gutter={8}>
                  <Col xs={24} md={8}>
                  {!week[day].lesson && <HeaderText>Lesson</HeaderText>}
                  {!week[day].lesson && <p>N/A</p>}
                  <DisplayList
                    title="Lesson"
                    data={week[day].lesson && week[day].lesson.name}
                  />
                  </Col>
                  <Col xs={24} md={16}>
                  <HeaderText>Keywords</HeaderText>
                  {week[day].keywords.map((word, i) => {
                    if (i === (week[day].keywords.length - 1)) {
                      return (<span key={i}>{word}</span>)
                    }
                    return (<span key={i}>{word}, </span>)
                  })}
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Evaluation"
                      data={week[day].evaluation}
                    />
                  </Col>

                  <Col xs={24} md={16}>
                    <DisplayList
                      title="Objectives"
                      data={week[day].objectives}
                    />
                  </Col>
                </Row>

                <Row gutter={8}>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Resources"
                      data={week[day].resources}
                    />
                  </Col>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Assignment"
                      data={week[day].assignment}
                    />
                  </Col>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Teacher Initiated Activities"
                      data={week[day].teacher_initiated_activities}
                    />
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Teacher Led Activities (Warm Up)"
                      data={week[day].teacher_led_activity.warmUp}
                    />
                  </Col>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Teacher Led Activities (Sub Topic)"
                      data={week[day].teacher_led_activity.subTopic}
                    />
                  </Col>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Teacher Led Activities (Activity)"
                      data={week[day].teacher_led_activity.activity}
                    />
                  </Col>
                </Row>
              </DayDiv>
              ))}
             
            </div>
          ))}
          </TabPane>

          <TabPane tab="3rd Term" key="4">
          {term3 && term3.weeks.map(week => (
            <div key={week.order} style={{marginTop: 10}}>
              <h3>Week {week.order}</h3>

                  <DisplayList
                    title="Topic"
                    data={week.topic}
                  />

              {days.map(day => (
              <DayDiv key={day}>
                <h4>Day{week[day].order}</h4>
                <Row gutter={8}>
                  <Col xs={24} md={8}>
                  {!week[day].lesson && <HeaderText>Lesson</HeaderText>}
                  {!week[day].lesson && <p>N/A</p>}
                  <DisplayList
                    title="Lesson"
                    data={week[day].lesson && week[day].lesson.name}
                  />
                  </Col>
                  <Col xs={24} md={16}>
                  <HeaderText>Keywords</HeaderText>
                  {week[day].keywords.map((word, i) => {
                    if (i === (week[day].keywords.length - 1)) {
                      return (<span key={i}>{word}</span>)
                    }
                    return (<span key={i}>{word}, </span>)
                  })}
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Evaluation"
                      data={week[day].evaluation}
                    />
                  </Col>

                  <Col xs={24} md={16}>
                    <DisplayList
                      title="Objectives"
                      data={week[day].objectives}
                    />
                  </Col>
                </Row>

                <Row gutter={8}>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Resources"
                      data={week[day].resources}
                    />
                  </Col>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Assignment"
                      data={week[day].assignment}
                    />
                  </Col>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Teacher Initiated Activities"
                      data={week[day].teacher_initiated_activities}
                    />
                  </Col>
                </Row>

                <Row gutter={8}>
                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Teacher Led Activities (Warm Up)"
                      data={week[day].teacher_led_activity.warmUp}
                    />
                  </Col>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Teacher Led Activities (Sub Topic)"
                      data={week[day].teacher_led_activity.subTopic}
                    />
                  </Col>

                  <Col xs={24} md={8}>
                    <DisplayList
                      title="Teacher Led Activities (Activity)"
                      data={week[day].teacher_led_activity.activity}
                    />
                  </Col>
                </Row>
              </DayDiv>
              ))}
             
            </div>
          ))}
          </TabPane>
        </Tabs>
      </CardSection>
    )
}

export default ViewSchemeOfWorkScreen;

