import React from "react";
import styled from "styled-components";

const Div = styled.div`
  .report-details-widget {
    display: flex;

    &__item {
      padding: 10px 20px;
      border-right: 1px solid #eceaea;

      &:last-of-type {
        border-right: none;
      }
    }

    &__header {
      text-transform: uppercase;
      color: #c3c3c3;
    }

    &__text {
      font-size: 3.4rem;
      margin-bottom: 0;
      line-height: 3.5rem;
    }
  }
`;

const ReportOverviewWidget = ({ report }) => (
  <Div>
    <div className="report-details-widget">
      <div className="report-details-widget__item">
        <h5 className="report-details-widget__header">score</h5>
        <p className="report-details-widget__text">{report.total_score}</p>
      </div>
      <div className="report-details-widget__item">
        <h5 className="report-details-widget__header">percentage</h5>
        <p className="report-details-widget__text">
          {`${report.percentage_score.toFixed(1)}%`}
        </p>
      </div>
      <div className="report-details-widget__item">
        <h5 className="report-details-widget__header">score group</h5>
        <p className="report-details-widget__text">
          {report.score_group.split("_")[2]}
        </p>
      </div>
    </div>
  </Div>
);

export default ReportOverviewWidget;
