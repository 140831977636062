import React from "react";
import styled from "styled-components";
import { lighten } from "polished";
import PropTypes from "prop-types";
import { styleConstants } from "../../constants";

const Card = styled.div`
  background: white;
  border-radius: 3px;
  padding: 15px;
  color: #ffffff;
  box-shadow: 2px 4px 11px 0px #d5d5d5;
  min-height: 182px;

  h4 {
    text-transform: uppercase;
    color: #979797;
    border-bottom: 1px solid #efefef;
    padding-bottom: 8px;
  }

  p {
    text-align: right;
    font-size: 72px;
    font-weight: bold;
    color: ${({ textColor }) => `${textColor}`};
    margin: 0;
  }

  @media only screen and (max-width: 768px) {
    margin-bottom: 12px;
  }
`;

const DataOverviewCards = ({ data, textColor, title }) => {

  return (
    <Card textColor={textColor}>
      <h4>{title}</h4>
      <p>{data}</p>
    </Card>
  );
};

DataOverviewCards.propTypes = {
  textColor: PropTypes.string
};

DataOverviewCards.defaultProps = {
  textColor: `${styleConstants.color.orange}`
};

export { DataOverviewCards };
