import React from 'react';
import { Query } from 'react-apollo';
import ALL_COMPETENCY_TESTS from '../../../graphql/queries/ALL_COMPETENCY_TESTS';
import ALL_LESSONS from '../../../graphql/queries/ALL_LESSONS';
import CompetencyTestsScreen from '../../../screens/CompetencyTestsScreen';
import { UserConsumer } from '../../../App';

export class CompetencyTestsContainer extends React.Component {
  state = {
    showLessonSelectionModal: false,
  };

  onSelectLesson = lesson => () => {
    this.props.history.push(`/main/add-question/${lesson._id}`);
  };

  showLessonSelectionModal = () => {
    this.setState({ showLessonSelectionModal: true });
  };

  closeLessonSelectionModal = () =>
    this.setState({ showLessonSelectionModal: false });

  _filterLessons = ({ role, _id }, competencytests) => {
    console.log('filtering no', competencytests);
    if (role == 'head_teacher') {
      console.log('headie here', _id, competencytests);
      const filteredTests = competencytests.filter(
        test => test.teacher._id === _id,
      );
      return filteredTests;
    }
    return competencytests;
  };

  render() {
    return (
      <UserConsumer>
        {user => (
          <Query query={ALL_LESSONS}>
            {({ loading, error, data }) => {
              if (loading) return 'Loading...';
              if (error) console.log('[Error fetching lessons]: ', error);
              const { lessons } = data;
              return (
                <Query
                  query={ALL_COMPETENCY_TESTS}
                  variables={{
                    filter: {
                      teacherId: user._id,
                    },
                  }}
                >
                  {({ loading, error, data }) => {
                    if (loading) return 'Loading...';
                    if (error) console.log('[Error fetching tests]: ', error);
                    const { competencytests } = data;
                    return (
                      <CompetencyTestsScreen
                        onSelectLesson={this.onSelectLesson}
                        onAddCompetencyTest={this.showLessonSelectionModal}
                        onCloseModal={this.closeLessonSelectionModal}
                        showLessonSelectionModal={
                          this.state.showLessonSelectionModal
                        }
                        isSeniorTeacher={user.role == 'senior_teacher'}
                        isHeadTeacher={user.role == 'head_teacher'}
                        data={this._filterLessons(user, competencytests)}
                        lessons={lessons}
                      />
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        )}
      </UserConsumer>
    );
  }
}
