import gql from "graphql-tag";

export default gql`
  mutation createDailyReport($dailyReport: DailyReportInputNew!) {
    createDailyReport(dailyReport: $dailyReport) {
      _id
      total_score
    }
  }
`;
