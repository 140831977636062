import React from "react";
import moment from "moment";
import { Table, Button, Modal, Select } from "antd";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import TitleBar from "../../components/common/TitleBar";
import RadioFilter from "../../components/common/RadioFilter";
import TableFilters from "../../components/common/TableFilters";
import idx from "idx";
import Media from "react-media";
import Highlighter from "react-highlight-words";

const Option = Select.Option;

const CompetencyTestsScreen = ({
  data,
  history,
  tableOptions,
  onReopenTest,
  onAddCompetencyTest,
  showLessonSelectionModal,
  onCloseModal,
  lessons,
  onSelectLesson,
  getColumnSearchProps,
  searchText
}) => {
  const {
    sortedInfo,
    filteredInfo,
    handleTableChange,
    handleFilterChange,
    clearFilters
  } = tableOptions;
  console.log("filterrr", lessons);

  const columns = [
    {
      title: "Name",
      dataIndex: "teacher.firstName",
      key: "teacher.firstName",
      ...getColumnSearchProps("teacher.firstName"),
      render: (firstName, { teacher }) => (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={`${firstName} ${idx(teacher, _ => _.lastName)}`}
        />
      )
    },
    {
      title: "Lesson",
      dataIndex: "lesson.name",
      key: "lessonName",
      ...getColumnSearchProps("lesson.name")
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
      render: score => (score ? score : 0)
    },
    {
      title: "Subject",
      dataIndex: "lesson.subject.name",
      key: "subject"
    },
    {
      title: "Times Attempted",
      dataIndex: "times_attempted",
      key: "times_attempted"
    },
    {
      title: "Passed",
      dataIndex: "is_passed",
      key: "is_passed",
      filters: [
        {
          text: "Passed Tests",
          value: true
        },
        { text: "Unpassed Tests", value: false }
      ],
      filteredValue: filteredInfo["is_passed"] || null,
      render: text => (text ? "Yes" : "No"),
      onFilter: (value, record) => {
        console.log("record: ", record);
        const isPassed = !!record.is_passed;
        return isPassed.toString().includes(value);
      }
    },
    {
      title: "Test Locked",
      dataIndex: "date_suspended",
      key: "date_suspended",
      render: text => (text ? "Yes" : "No")
    },
    {
      title: "Created",
      dataIndex: "created_at",
      key: "created_at",
      render: text => moment(text).format("MMM Do YY")
    },
    {
      title: "Reopen Test",
      key: "action",
      render: record => {
        return record.date_suspended ? (
          <Button onClick={() => onReopenTest(record._id)}>Reopen Test</Button>
        ) : null;
      }
    },
    {
      title: "",
      key: "view",
      render: record => (
        <Link to={`/main/admin/view-test-details/${record._id}`}>View</Link>
      )
    }
  ];

  return (
    <Media query="(max-width: 599px)">
      {matches => (
        <div>
          <TitleBar
            title="Competency Tests"
            explainerText={`This shows all the competency tests that have been initiated on the platform.`}
            helperTooltip={`You can use the filters below to modify the results to show only those tests which have been failed`}
            buttons={[
              <Button
                size={matches ? "small" : "default"}
                type="primary"
                icon="file-add"
                style={{ marginRight: 8, marginBottom: ".5em" }}
                onClick={onAddCompetencyTest}
              >
                Add Competency Test Question
              </Button>
            ]}
          />
          <TableFilters>
            <RadioFilter
              name="is_passed"
              handleOnChange={handleFilterChange}
              label="test status"
              options={[
                { value: false, name: "Unpassed Tests" },
                { value: true, name: "Passed Tests" }
              ]}
            />
          </TableFilters>
          <Table
            columns={columns}
            dataSource={data}
            rowKey="_id"
            style={{ minWidth: 813 }}
            onChange={handleTableChange}
          />
          <Modal
            title="Select A Lesson"
            visible={showLessonSelectionModal}
            footer={null}
            onCancel={onCloseModal}
          >
            <p>Choose a lesson to add a competency test to</p>
            <div>
              <Select
                style={{ display: "block" }}
                placeholder="Choose a lesson"
              >
                {lessons.map(lesson => (
                  <Option
                    onClick={onSelectLesson(lesson)}
                    key={lesson._id}
                    value={lesson._id}
                  >
                    {lesson.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Modal>
        </div>
      )}
    </Media>
  );
};

const CompetencyTestsScreenWithRouter = withRouter(CompetencyTestsScreen);
export default CompetencyTestsScreenWithRouter;
